define("event-components/components/app-programm-module/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PX09kWbN",
    "block": "{\"symbols\":[\"model\",\"tabs\",\"programItem\",\"&default\"],\"statements\":[[6,[37,6],[[32,0,[\"model\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"app-tabs\",[],[[\"@tabs\"],[[32,1,[\"tabs\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,5,\"margin: 0 10px;\"],[12],[2,\"\\n      \"],[8,\"app-programm\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1,[\"programItems\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,3],[[32,2,[\"active\"]],[30,[36,2],[[32,3,[\"date\"]],\"dd DD.MM.YYYY\",\"YYYY-MM-DDTHH:mm:ssZ\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n            \"],[8,\"app-programm-item\",[],[[\"@model\",\"@agendaItems\",\"@user\"],[[32,3],[32,1,[\"agendaItems\"]],[32,1,[\"client\",\"user\"]]]],null],[2,\"\\n\"],[6,[37,1],[[32,3,[\"pagebreak\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"div\"],[14,0,\"break\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[3]}]]],[2,\"      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,4,null]],\"hasEval\":false,\"upvars\":[\"log\",\"if\",\"moment-format\",\"eq\",\"-track-array\",\"each\",\"async-await\"]}",
    "moduleName": "event-components/components/app-programm-module/template.hbs"
  });

  _exports.default = _default;
});