define("ember-x-tabs/mixins/component-parent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    children: null,

    init() {
      this._super(...arguments);

      this.set('children', Ember.A());
    },

    registerChild(child) {
      Ember.run.next(() => {
        this.get('children').addObject(child);
      });
    },

    removeChild(child) {
      this.get('children').removeObject(child);
    }

  });

  _exports.default = _default;
});