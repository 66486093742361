define("event-components/components/app-chat-pm/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-chat-pm/template", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  let AppChatPm = (_dec = (0, _component.classNames)("app-chat-pm"), _dec2 = Ember.inject.service("chat"), _dec3 = Ember._action, _dec(_class = (_class2 = class AppChatPm extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "chatService", _descriptor, this);
      (0, _defineProperty2.default)(this, "message", "");
    }

    async createPm() {
      await this.chatService.newChatline(this.message, this.target.id);
      this.set("target", null);
      this.set("message", "");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "chatService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "createPm", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "createPm"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppChatPm;
});