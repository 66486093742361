define("event-components/helpers/css-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cssName = cssName;
  _exports.default = void 0;

  function cssName(params
  /*, hash*/
  ) {
    return params[0].replace(/[\W_]+/g, "-");
  }

  var _default = Ember.Helper.helper(cssName);

  _exports.default = _default;
});