define("ember-in-viewport/modifiers/in-viewport", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-modifier", "fast-deep-equal"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberModifier, _fastDeepEqual) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  const WATCHED_ELEMENTS = false
  /* DEBUG */
  ? new WeakSet() : undefined;
  let InViewportModifier = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class InViewportModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "inViewport", _descriptor, this);
      (0, _defineProperty2.default)(this, "name", 'in-viewport');
      (0, _defineProperty2.default)(this, "lastOptions", void 0);
    }

    get options() {
      // eslint-disable-next-line no-unused-vars
      const {
        onEnter,
        onExit,
        ...options
      } = this.args.named;
      return options;
    }

    get hasStaleOptions() {
      return !(0, _fastDeepEqual.default)(this.options, this.lastOptions);
    }

    validateArguments() {
      (false && !(this.args.positional.length === 0) && Ember.assert(`'{{in-viewport}}' does not accept positional parameters. Specify listeners via 'onEnter' / 'onExit'.`, this.args.positional.length === 0));
      (false && !(typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function') && Ember.assert(`'{{in-viewport}}' either expects 'onEnter', 'onExit' or both to be present.`, typeof this.args.named.onEnter === 'function' || typeof this.args.named.onExit === 'function'));
    }

    onEnter() {
      if (this.args.named.onEnter) {
        this.args.named.onEnter.call(null, this.element);
      }

      if (!this.options.viewportSpy) {
        this.inViewport.stopWatching(this.element);
      }
    }

    onExit() {
      if (this.args.named.onExit) {
        this.args.named.onExit.call(null, this.element);
      }
    }

    setupWatcher() {
      (false && !(!WATCHED_ELEMENTS.has(this.element)) && Ember.assert(`'${this.element}' is already being watched. Make sure that '{{in-viewport}}' is only used once on this element and that you are not calling 'inViewport.watchElement(element)' in other places.`, !WATCHED_ELEMENTS.has(this.element)));
      if (false
      /* DEBUG */
      ) WATCHED_ELEMENTS.add(this.element);
      this.inViewport.watchElement(this.element, this.options, this.onEnter, this.onExit);
      this.lastOptions = this.options;
    }

    destroyWatcher() {
      if (false
      /* DEBUG */
      ) WATCHED_ELEMENTS.delete(this.element);
      this.inViewport.stopWatching(this.element);
    }

    didInstall() {
      this.setupWatcher();
    }

    didUpdateArguments() {
      if (this.hasStaleOptions) {
        this.destroyWatcher();
        this.setupWatcher();
      }
    }

    didReceiveArguments() {
      this.validateArguments();
    }

    willRemove() {
      this.destroyWatcher();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "inViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onEnter", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onEnter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onExit", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onExit"), _class.prototype)), _class));
  _exports.default = InViewportModifier;
});