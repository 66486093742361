define("event-components/helpers/findby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findby = findby;
  _exports.default = void 0;

  function findby(params
  /*, hash*/
  ) {
    console.log(params);
    let finding = params[2].findBy(params[0], params[1]);
    return finding !== null && finding !== undefined;
  }

  var _default = Ember.Helper.helper(findby);

  _exports.default = _default;
});