define("event-components/components/app-avatar-photo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nGEE2/Zo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"cropper-container\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,1,\"avatar\"],[15,\"src\",[34,0]],[14,\"alt\",\"avatar\"],[14,\"crossorigin\",\"\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"btn-group-lg\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-lg btn-primary\"],[4,[38,1],[[32,0],\"updateImage\"],null],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"speichern\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"img-preview-container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"img-preview\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"file\",\"action\",\"t\"]}",
    "moduleName": "event-components/components/app-avatar-photo/template.hbs"
  });

  _exports.default = _default;
});