define("event-components/components/twitter-module/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-apollo-client", "graphql-tag", "event-components/components/twitter-module/template", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberApolloClient, _graphqlTag, _template, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  const query = (0, _graphqlTag.default)`
query posts(
  $where: PostWhereInput!, 
  $orderBy: PostOrderByInput,
  $first: Int
  $after: String
  ) {
    posts(
      where: $where
      orderBy: $orderBy
      first: $first
      after: $after
    ) {
      ${_fragments.POST_FRAGMENT}
    }
}
`;
  let TwitterModule = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('amplify'), _dec3 = Ember.computed('model.posts.[]'), _dec4 = Ember.computed('model.posts.[]', 'activeFilters.[]'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class TwitterModule extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "activeFilters", []);
      (0, _defineProperty2.default)(this, "wallId", null);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "oberserverable", void 0);
      this.set('apollo', this.get('apolloService').createQueryManager()); //this.get("amplify").on("moduleitem:unset", this, "unset");
    }

    willDestroyElement() {
      this.get('amplify').off('moduleitem:unset', this, 'unset');
    }

    unset() {
      this.set('wallId', null);
    }

    didReceiveAttrs() {
      this.loadPosts();
    }

    beforeModel(model) {
      if (this.get('amplify.currentUser') == null) {
        this.transitionTo('landing');
      }
    }

    async loadPosts() {
      let client = this.amplify.currentClient;
      const variables = {
        where: {
          client: {
            id: client.id
          },
          wall: {
            id: this.get('wallId')
          },
          moderated: 'Freigeschaltet',
          show: true
        },
        first: 99,
        orderBy: 'createdAt_DESC'
      };
      const posts = await this.apollo.query({
        query,
        variables,
        fetchPolicy: 'network-only'
      }, 'posts');
      this.set('model', {
        posts,
        client
      });
    }

    get filter() {
      if (this.model == undefined) {
        return [];
      }

      const categories = [...new Set(this.model.posts.map(post => post.locations.firstObject))];
      return categories.filter(i => {
        return i !== undefined;
      });
    }

    get filteredPosts() {
      if (this.model == undefined) {
        return [];
      }

      if (this.activeFilters.length > 0) {
        return this.model.posts.filter(post => {
          return this.activeFilters.includes(post.locations.firstObject);
        });
      }

      return this.model.posts;
    }

    toggleFilter(filter) {
      if (this.activeFilters.includes(filter)) {
        this.activeFilters.removeObject(filter);
      } else {
        this.activeFilters.addObject(filter);
      }
    }

    onEnterViewport() {
      let observable = (0, _emberApolloClient.getObservable)(this.model.posts);
      const variables = {
        where: {
          client: {
            id: this.model.client.id
          }
        },
        first: 3,
        after: this.model.posts.lastObject.id,
        orderBy: 'createdAt_DESC'
      };
      observable.fetchMore({
        variables,
        updateQuery: (previousResult, {
          fetchMoreResult
        }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }

          return {
            posts: [...previousResult.posts, ...fetchMoreResult.posts]
          };
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filter", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "filter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "filteredPosts", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "filteredPosts"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleFilter", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFilter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onEnterViewport", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onEnterViewport"), _class.prototype)), _class));
  _exports.default = TwitterModule;
});