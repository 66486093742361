define("event-components/components/aws-image/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "event-components/components/aws-image/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  let AwsImage = (_dec = (0, _component.tagName)(""), _dec2 = Ember.inject.service("amplify"), _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember.computed("file"), _dec6 = Ember._action, _dec(_class = (_class2 = class AwsImage extends Ember.Component.extend() {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "recognizers", "pinch");
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _defineProperty2.default)(this, "mapHeight", null);
      (0, _defineProperty2.default)(this, "show", false);
      (0, _defineProperty2.default)(this, "removeCache", true);
      (0, _initializerDefineProperty2.default)(this, "inViewport", _descriptor2, this);
    }

    setupInViewport(element) {
      const viewportTolerance = {
        bottom: 200
      };
      const {
        onEnter,
        onExit
      } = this.inViewport.watchElement(element, {
        viewportTolerance
      });
      onEnter(this.didEnterViewport.bind(this));
    }

    didEnterViewport() {
      if (!this.isDestroyed && !this.isDestroying) {
        this.set("show", true);
      }
    }

    willDestroy() {
      // need to manage cache yourself if you don't use the mixin
      const loader = document.getElementById("loader");
      this.inViewport.stopWatching(loader);
      super.willDestroy(...arguments);
    }

    get imagepath() {
      if (Ember.isEmpty(this.file)) {
        console.log("empty map");
        return;
      }

      try {
        let cachedUrl = this.get("amplify.Cache").getItem(this.file.key);

        if (cachedUrl !== null) {
          return cachedUrl;
        }

        let url = this.get("amplify").Storage.get(this.file.key, {
          expires: 86400,
          level: "public"
        }).then(url => {
          if (url.includes("?") && this.removeCache) {
            url = url.split("?")[0];
          }

          this.get("amplify.Cache").setItem(this.file.key, url);
          return url;
        });
        return url;
      } catch (e) {
        console.error(e);
      }
    }

    didInsertImage() {
      console.log(this);

      if (this.onDidInsertElement) {
        this.onDidInsertElement();
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "inViewport", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "setupInViewport", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "setupInViewport"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "imagepath", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "imagepath"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "didInsertImage", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "didInsertImage"), _class2.prototype)), _class2)) || _class);
  _exports.default = AwsImage;
});