define("event-components/components/vote-list-module/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/vote-list-module/template", "graphql-tag", "@ember-decorators/object", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _graphqlTag, _object, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3;

  const query = (0, _graphqlTag.default)`
  query votes($where: VoteWhereInput!) {
    votes(where: $where) {
      ${_fragments.VOTE_FRAGMENT}
    }
  }
`;
  let VoteListModule = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('amplify'), _dec3 = Ember.inject.service('vote'), _dec4 = (0, _object.observes)('currentVote'), _dec5 = Ember._action, _dec6 = Ember._action, (_class = class VoteListModule extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    onCurrentVote() {
      if (!this.currentVote) {
        this.set('voteService.suggested', '');
      }
    }

    voteDetail(vote, showChart) {
      this.amplify.set("currentModuleItem", vote.question);

      if (vote.show) {
        this.set("currentVote", vote);
      } // show chart result if param given


      if (showChart) {
        this.set("showChart", true);
      }
    }

    voteDetailById(voteId) {
      this.set('currentVote', this.votes.findBy('id', voteId));
    }

    async onVotesChanged(vote) {
      let model = await this.model;
      console.log("onVotesChanged", model, vote, this.currentVote);

      if (vote.node) {
        vote = vote.node;
      }

      if (this.currentVote) {
        if (vote.id === this.currentVote.id) {
          this.set('currentVote', { ...this.currentVote,
            ...vote
          });
        }
      }

      let existingVote = model.votes.findBy('id', vote.id);
      console.log(existingVote);

      if (existingVote) {
        Ember.run.next(this, function () {
          this.set('model', this.loadModel());
        });
      } else {
        if (this.amplify.currentClient.id === vote.client.id) {
          Ember.run.next(this, function () {
            this.set('model', this.loadModel());
          });
        }
      }
    }

    willDestroy() {
      this.get('voteService').off('vote:changed', this, 'onVotesChanged');
      this.get('amplify').off('unity:vote', this, 'voteDetail');
      this.get('amplify').off('moduleitem:unset', this, 'unset');
      this.get('amplify').off('websocket:reconnected', this, 'onReconnect');
      this.amplify.off('ws:updateVote', this, 'onVotesChanged');
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "voteService", _descriptor3, this);
      (0, _defineProperty2.default)(this, "model", void 0);
      (0, _defineProperty2.default)(this, "currentVote", void 0);
      this.set('apollo', this.get('apolloService').createQueryManager());
      this.model = this.loadModel();
      this.amplify.on('unity:vote', this, 'voteDetail');
      this.amplify.on('websocket:reconnected', this, 'onReconnect');
      this.amplify.on('ws:updateVote', this, 'onVotesChanged');
      this.get('voteService').on('vote:changed', this, 'onVotesChanged');
      this.get('amplify').on('moduleitem:unset', this, 'unset');
    }

    async onReconnect() {
      console.log("Reloading Votes because websocket disconnected");
      await this.voteService.preloadVotes();
      await this.loadModel();
    }

    unset() {
      this.set('currentVote', null);
    }

    async loadModel() {
      let votes = this.get('voteService.votes');
      const client = await this.amplify.get('currentClient');
      return {
        votes: votes,
        user: client.user,
        client: client
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "voteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCurrentVote", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onCurrentVote"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "voteDetail", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "voteDetail"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "voteDetailById", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "voteDetailById"), _class.prototype)), _class));
  _exports.default = VoteListModule;
});