define("event-components/components/app-meeting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PMRmPHP/",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"],[10,\"div\"],[14,0,\"app-content\"],[14,5,\"margin:20px;\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[35,2,[\"currentUser\",\"meetinggroup\"]],[35,2,[\"currentUser\",\"meetingrole\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"x-button\",[[24,0,\"btn btn-primary btn-fullwidth btn-lg\"]],[[\"@task\",\"@args\"],[[34,1],[34,2,[\"currentUser\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,0],[\"meeting.video_meeting_beitreten\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"meeting.no_meeting_message\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"joinMeeting\",\"amplify\",\"and\",\"if\"]}",
    "moduleName": "event-components/components/app-meeting/template.hbs"
  });

  _exports.default = _default;
});