define("ember-class-based-modifier/-private/modifier-classic", ["exports", "ember-class-based-modifier/-private/modifier-manager"], function (_exports, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ClassBasedModifier = Ember.Object.extend({
    args: null,

    init() {
      this._super(...arguments);

      this.element = null;
    },

    didReceiveArguments() {},

    didUpdateArguments() {},

    didInstall() {},

    willRemove() {}

  });

  Ember._setModifierManager(() => _modifierManager.default, ClassBasedModifier);

  var _default = ClassBasedModifier;
  _exports.default = _default;
});