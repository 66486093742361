define("ember-x-tabs/components/x-tab/pane", ["exports", "ember-x-tabs/templates/components/x-tab/pane", "ember-x-tabs/mixins/component-child"], function (_exports, _pane, _componentChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_componentChild.default, {
    layout: _pane.default,
    classNameBindings: ['contentCurrent'],
    tagName: 'section',
    activeId: null,
    isActive: Ember.computed('activeId', 'paneId', function () {
      return this.get('activeId') === this.get('paneId');
    }).readOnly(),
    contentCurrent: true,
    _paneId: null,
    paneId: Ember.computed('_paneId', {
      get() {
        return this.get('_paneId') || this.get('elementId');
      },

      set(key, value) {
        this.set('_paneId', value);
        return value;
      }

    }),

    show() {
      this.set('contentCurrent', true);
    },

    hide() {
      this.set('contentCurrent', false);
    },

    _showHide: Ember.observer('isActive', function () {
      // eslint-disable-line
      if (this.get('isActive')) {
        this.show();
      } else {
        this.hide();
      }
    }),

    init() {
      this._super(...arguments);

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.set('contentCurrent', this.get('isActive'));
      });
    }

  });

  _exports.default = _default;
});