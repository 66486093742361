define("event-components/components/user-card/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/user-card/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor;

  let UserCard = (_dec = Ember.inject.service('amplify'), _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class UserCard extends Ember.Component.extend({
    classNames: ['user-card-wrapper'] // anything which *must* be merged to prototype here

  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "privateMessage", null);
      (0, _defineProperty2.default)(this, "videochat", null);
      (0, _defineProperty2.default)(this, "videocall", null);
      (0, _defineProperty2.default)(this, "appointmentRequest", null);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _defineProperty2.default)(this, "active", false);
    }

    // actions
    async addFav(user) {
      await this.amplify.addFavToProfile(user);
    }

    async removeFav(user) {
      await this.amplify.removeFavToProfile(user);
    }

    toggleFooter() {
      this.toggleProperty('active');
    }

    emitPrivateMessage() {
      this.privateMessage();
    }

    emitVideochat() {
      this.videochat();
    }

    emitVideocall() {
      this.videocall();
    }

    emitAppointmentRequest() {
      this.appointmentRequest();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addFav", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addFav"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeFav", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeFav"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleFooter", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFooter"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emitPrivateMessage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "emitPrivateMessage"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emitVideochat", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "emitVideochat"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emitVideocall", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "emitVideocall"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "emitAppointmentRequest", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "emitAppointmentRequest"), _class.prototype)), _class));
  _exports.default = UserCard;
});