define("event-components/components/app-map/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "event-components/gql/fragments", "ember-concurrency-decorators", "@ember-decorators/object", "@ember-decorators/component", "event-components/components/app-map/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _fragments, _emberConcurrencyDecorators, _object, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2;

  const query = (0, _graphqlTag.default)`
query maps($where: MapWhereInput!) {
  maps(where: $where) {
    ${_fragments.MAP_FRAGMENT}
  }
}
`;
  let AppMap = (_dec = Ember.inject.service("graphql"), _dec2 = (0, _component.attribute)("id"), _dec3 = Ember.computed("scaleStep"), _dec4 = Ember.computed("mapHeigth", "mapWidth"), _dec5 = Ember.computed("mapHeight", "currentZoomLevel"), _dec6 = Ember.computed("mapWidth", "currentZoomLevel"), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = (0, _object.observes)("mapHeight", "mapWidth"), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = (0, _emberConcurrencyDecorators.task)(), (_class = class AppMap extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    get currentZoomLevel() {
      return this.get("scaleStep.firstObject");
    }

    get scaleStep() {
      console.log(`im scaling and mapheight is ${this.mapHeight} and zooms: ${this.map.zooms}`);
      const PX = this.mapHeight,
            PY = this.mapWidth,
            SX = window.innerHeight - 95,
            SY = window.innerWidth,
            STEP = this.map.zooms;
      console.log(PX, PY, SX, SY);
      let SCALMAX = null;

      if (PX / SX > PY / SY) {
        SCALMAX = PY / SY;
      } else {
        SCALMAX = PX / SX;
      }

      console.log(`SCALMAX: ${SCALMAX}`);
      let SKALSTEP = [];

      for (let i = 1; i <= STEP; i++) {
        let newStep = Math.pow(1 + (i - 1) * (Math.sqrt(SCALMAX) - 1) / (STEP - 1), 2);
        SKALSTEP.push(newStep / SCALMAX);
      }

      return SKALSTEP.map((i, index) => {
        return {
          level: (index + 1).toString(),
          value: i,
          percentage: i * 100
        };
      });
    }

    get _mapHeight() {
      console.log("recompute height");
      return this.mapHeight * this.currentZoomLevel.value;
    }

    get _mapWidth() {
      console.log("recompute width");
      return this.mapWidth * this.currentZoomLevel.value;
    }

    oneUp() {
      console.log("oneUp");
      let index = this.scaleStep.indexOf(this.currentZoomLevel);
      console.log(`index ${index}`);

      if (index === -1) {
        index = 0;
      }

      if (index + 2 > this.scaleStep.length) {
        this.send("setZoom", this.scaleStep.lastObject);
      } else {
        this.send("setZoom", this.scaleStep.objectAt(index + 1));
      }
    }

    oneDown() {
      let index = this.scaleStep.indexOf(this.currentZoomLevel);

      if (index - 1 < 0) {
        this.send("setZoom", this.scaleStep.firstObject);
      } else {
        this.send("setZoom", this.scaleStep.objectAt(index - 1));
      }
    }

    setZoom(zoom) {
      this.scrollMagic(zoom, this.currentZoomLevel);

      if (!this.initialMapHeight) {
        this.set("initialMapHeight", this.get("mapHeight") * this.currentZoomLevel.value);
      }

      let factor = zoom.value - this.get("currentZoomLevel").value;
      let oldHeight = this.get("_mapHeight");
      this.set("currentZoomLevel", zoom);
      let newHeight = this.get("_mapHeight");
      let diff = (newHeight - oldHeight) / 2;
    }

    scrollMagic(newZoom, currentZoomLevel) {
      const BX = 0;
      const BY = 0;
      const S1X = document.querySelector("#map").scrollLeft;
      const S1Y = document.querySelector("#map").scrollTop;
      const WX = window.innerWidth;
      const WY = window.innerHeight - 95;
      const ZF2 = newZoom.value / currentZoomLevel.value;
      const S2X = BX + (S1X + WX / 2 - BX) * ZF2 - WX / 2;
      const S2Y = BY + (S1Y + WY / 2 - BY) * ZF2 - WY / 2;
      console.log(` 
      BX: ${BX}
      BY: ${BY}
      S1X: ${S1X}
      S1Y: ${S1Y}
      WX: ${WX}
      WY: ${WY}
      ZF2: ${ZF2}
      S2X: ${S2X}
      S2Y: ${S2Y}
      newZoom: ${newZoom.value}
      currentZoom: ${currentZoomLevel.value}
    `);
      document.querySelector("#map").scrollLeft = S2X;
      document.querySelector("#map").scrollTop = S2Y;
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "map", _descriptor2, this);
      (0, _defineProperty2.default)(this, "beaconDetailIsOpen", false);
      (0, _defineProperty2.default)(this, "currentBeacon", null);
      (0, _defineProperty2.default)(this, "mapHeight", 0);
      (0, _defineProperty2.default)(this, "initialMapHeight", void 0);
      (0, _defineProperty2.default)(this, "mapWidth", 0);
      (0, _defineProperty2.default)(this, "zoomLevels", [{
        level: "1",
        value: 1,
        percentage: 100
      }, {
        level: "2",
        value: 1.25,
        percentage: 125
      }, {
        level: "3",
        value: 1.5,
        percentage: 150
      }, {
        level: "4",
        value: 1.75,
        percentage: 175
      }]);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    didReceiveAttrs() {
      this.reloadMap.perform();
    }

    initMapScroll() {
      if (this.get("mapHeight") === 0 || this.get("mapWidth") === 0) {
        return;
      }

      document.getElementById("map").scrollLeft = this.mapWidth * (this.map.scrollpositionX / 100);
      document.getElementById("map").scrollTop = this.mapHeight * (this.map.scrollpositionY / 100);
    }

    onDidInsertElement() {
      let img = document.getElementById("img-map");
      let map = new Image();
      map.addEventListener("load", () => {
        this.set("mapHeight", document.getElementById("img-map").naturalHeight);
        this.set("mapWidth", document.getElementById("img-map").naturalWidth);
      });
      map.src = img.src;
    }

    toggleBeaconDetail(beacon) {
      if (this.currentBeacon === null) {
        this.toggleProperty("beaconDetailIsOpen");
        this.set("currentBeacon", beacon);
        return;
      }

      if (this.currentBeacon.id === beacon.id) {
        this.toggleProperty("beaconDetailIsOpen");
      }

      this.set("currentBeacon", beacon);
    }

    *reloadMap() {
      const variables = {
        where: {
          client: {
            id: this.get("client.id")
          }
        }
      };
      let maps = yield this.get("apolloService").query({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "maps");

      if (maps.length > 0) {
        this.set("map", maps.firstObject);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "map", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "map";
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentZoomLevel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "currentZoomLevel"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "scaleStep", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "scaleStep"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_mapHeight", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_mapHeight"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_mapWidth", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "_mapWidth"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "oneUp", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "oneUp"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "oneDown", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "oneDown"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "setZoom", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setZoom"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initMapScroll", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "initMapScroll"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDidInsertElement", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsertElement"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleBeaconDetail", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBeaconDetail"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reloadMap", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "reloadMap"), _class.prototype)), _class));
  _exports.default = AppMap;
});