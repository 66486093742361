define("event-components/components/vote-module/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/vote-module/template", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  const query = (0, _graphqlTag.default)`
  query votes($where: VoteWhereInput!) {
    votes(where: $where) {
      ${_fragments.VOTE_FRAGMENT}
    }
  }
`;
  let VoteModule = (_dec = Ember.inject.service("graphql"), (_class = class VoteModule extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _defineProperty2.default)(this, "model", void 0);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.model = this.loadModel();
    }

    async loadModel(params) {
      let vote, votes;
      const client = await this.modelFor("application");
      const variables = {
        where: {
          client: {
            id: client.id
          },
          id: params.vote_id,
          active: true
        }
      };
      votes = await this.apollo.query({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "votes");
      vote = votes.firstObject;
      votes = this.get("voteService.votes");
      return {
        vote,
        votes,
        user: client.user,
        client: client
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = VoteModule;
});