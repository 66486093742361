define("event-components/components/app-avatar-module/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-avatar-module/template", "event-components/gql/fragments", "ember-concurrency-decorators", "graphql-tag", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _fragments, _emberConcurrencyDecorators, _graphqlTag, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  const mutation = (0, _graphqlTag.default)`
mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}
`;
  let AppAvatarModule = (_dec = (0, _component.classNames)("app-avatar-module"), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service("graphql"), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = (0, _emberConcurrencyDecorators.task)(), _dec(_class = (_class2 = class AppAvatarModule extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor2, this);
      (0, _defineProperty2.default)(this, "model", void 0);
      (0, _defineProperty2.default)(this, "openPhoto", false);
      (0, _defineProperty2.default)(this, "user", void 0);
      (0, _defineProperty2.default)(this, "isInvalid", false);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.set("model", this.loadModel());
    }

    async loadModel() {
      const client = await this.amplify.get("currentClient");
      const {
        questions,
        answers
      } = await this.amplify.findAllQuestions(client);
      const model = {
        answers,
        questions: questions.filterBy("profil", true),
        user: client.user,
        client: client
      };
      return model;
    }

    *createAvatar(event) {
      try {
        const file = event.target.files[0];
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          this.set("image", reader.result);
        }, false);
        reader.readAsDataURL(file);
        console.log(file);
        const filekey = `${this.amplify.currentClient.id}/${this.amplify.currentUser.id}/avatar/${file.name}`;
        let result = yield this.get("amplify").Storage.put(filekey, file, {
          contentType: 'image/jpg',
          level: "public"
        });
        let fileUrl = yield this.get("amplify").Storage.get(result.key, {
          expires: 60,
          level: "public"
        });
        let fileModel = {
          key: result.key
        };
        let user = yield this.submitForm2.perform({
          file: fileModel
        });
        console.log(user);
        this.set("amplify.currentUser.avatar", user.avatar);
        this.set("amplify.currentUser.avatarthumb", user.avatar);
        this.editImage();
        return user;
      } catch (e) {
        this.get("amplify").Logger.error("[File Upload] ", e);
      }
    }

    uploadImage() {
      var _document$getElementB;

      (_document$getElementB = document.getElementById("avatar-upload")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.click();
    }

    editImage() {
      this.set("openPhoto", true);
    }

    *submitForm2(data) {
      let {
        file
      } = data;
      const variables = {
        data: {
          avatar: {
            create: { ...file,
              client: {
                connect: {
                  id: this.amplify.currentClient.id
                }
              }
            }
          }
        },
        where: {
          id: this.get("amplify.currentUser.id")
        }
      };
      let user = yield this.get("apollo").mutate({
        mutation,
        variables
      }, "updateUser");
      return user;
    }

    *submitForm(data) {
      console.log(data);
      let {
        publicprofile,
        notifications,
        company,
        description,
        industry
      } = data;
      const variables = {
        data: {
          publicprofile,
          notifications,
          company,
          description,
          industry
        },
        where: {
          id: this.get("model.user.id")
        }
      };
      yield this.get("apollo").mutate({
        mutation,
        variables
      }, "updateUser");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apolloService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "createAvatar", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createAvatar"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "uploadImage", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "uploadImage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "editImage", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "editImage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "submitForm2", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "submitForm2"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "submitForm", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "submitForm"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppAvatarModule;
});