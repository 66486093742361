define("event-components/components/pwa-message/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "event-components/components/pwa-message/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  let PwaMessage = (_dec = (0, _component.classNames)("pwa-message"), _dec2 = (0, _component.className)("isClosed"), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec(_class = (_class2 = class PwaMessage extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "isClosed", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isMobile", _descriptor3, this);

      if (window.matchMedia("(display-mode: standalone)").matches || !this.get("isMobile.apple.device")) {
        this.set("isClosed", true);
      }

      if (window.localStorage.getItem("pwa-message") !== null) {
        this.set("isClosed", true);
      }
    }

    close() {
      this.set("isClosed", true);
      window.localStorage.setItem("pwa-message", "showed");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isClosed", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isMobile", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype)), _class2)) || _class);
  _exports.default = PwaMessage;
});