define("event-components/helpers/map-by-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.mapByQuestion = mapByQuestion;
  _exports.default = void 0;

  function mapByQuestion(params) {
    return params[0].map((q, i) => {
      return {
        id: i + 1,
        title: q
      };
    });
  }

  var _default = Ember.Helper.helper(mapByQuestion);

  _exports.default = _default;
});