define("event-components/helpers/firstletter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firstletter = firstletter;
  _exports.default = void 0;

  function firstletter(params) {
    return params[0].charAt(0);
  }

  var _default = Ember.Helper.helper(firstletter);

  _exports.default = _default;
});