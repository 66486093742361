define("event-components/chat/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "event-components/gql/fragments", "@ember-decorators/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _fragments, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SystemMessageTypes = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2;

  let SystemMessageTypes;
  _exports.SystemMessageTypes = SystemMessageTypes;

  (function (SystemMessageTypes) {
    SystemMessageTypes["VideoChat"] = "VideoChat";
  })(SystemMessageTypes || (_exports.SystemMessageTypes = SystemMessageTypes = {}));

  const createChatroomMutation = (0, _graphqlTag.default)`
mutation createChatroom($data: ChatroomCreateInput!) {
  createChatroom(data: $data) {
    ${_fragments.CHATROOM_FRAGMENT}
  }
}`;
  const subscription = (0, _graphqlTag.default)`
subscription($where: ChatlineSubscriptionWhereInput) {
  chatline(where: $where) {
    node {
     ${_fragments.CHATLINE_FRAGMENT}
    }
  }
}
`;
  const CREATE_CHATLINE = (0, _graphqlTag.default)`
mutation($data: ChatlineCreateInput!) {
  createChatline(
    data: $data
  ) {
    ${_fragments.CHATLINE_FRAGMENT}
  }
}
`;
  const query2 = (0, _graphqlTag.default)`
  query($where: ChatroomWhereInput!) {
    chatrooms(
      where: $where
    ) {
      ${_fragments.CHATROOM_FRAGMENT}
    }
  }
`;
  let Chat = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('amplify'), _dec3 = Ember.computed('unreadMessages.[]'), _dec4 = Ember.computed('unreadPrivateMessages.[]'), _dec5 = (0, _object.observes)('unreadMessages.[]'), _dec6 = (0, _object.observes)('unreadPmMessages.[]'), (_class = class Chat extends Ember.Service.extend(Ember.Evented, {}) {
    willDestroy() {
      this.amplify.off('ws:createChatline', this, 'handleChatLine');
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "_subscriber", void 0);
      (0, _defineProperty2.default)(this, "client", 'cjuzzvn9101lh0716eweuzcr3');
      (0, _defineProperty2.default)(this, "chatrooms", []);
      (0, _defineProperty2.default)(this, "unreadMessages", []);
      (0, _defineProperty2.default)(this, "unreadPmMessages", []);
      this.set('apollo', this.get('apolloService').createQueryManager());
      this.amplify.on('ws:createChatline', this, 'handleChatLine');
      this.initUnreadMessages();
      this.initChatrooms();
    }

    async initChatrooms() {
      const variables = {
        where: {
          client: {
            id: this.get("amplify.currentClient.id")
          }
        }
      };
      let chatrooms = await this.apollo.query({
        query: query2,
        variables,
        fetchPolicy: "network-only"
      }, "chatrooms");
      this.set("chatrooms", chatrooms);
    }

    get unreadPrivateMessages() {
      return this.unreadMessages.filter(m => {
        return m.chatroom.privateusersmap !== null && m.chatroom.privateusersmap !== undefined;
      });
    }

    get unreadPublicMessages() {
      return this.unreadMessages.filter(m => {
        return m.chatroom.privateusersmap == null;
      });
    }

    async initUnreadMessages() {
      let unreadMessages = this.get('amplify').Cache.getItem('unreadMessages');

      if (unreadMessages) {
        this.set('unreadMessages', unreadMessages);
      }

      let unreadPmMessages = this.get('amplify').Cache.getItem('unreadPmMessages');

      if (unreadPmMessages) {
        this.set('unreadPmMessages', unreadPmMessages);
      }
    }

    async persistUnreadMessages() {
      this.get('amplify').Cache.setItem('unreadMessages', this.unreadMessages);
    }

    async persistUnreadPmMessages() {
      this.get('amplify').Cache.setItem('unreadPmMessages', this.unreadPmMessages);
    }

    handleChatLine(event) {
      var _event$node$chatroom$, _event$node$chatroom$2;

      if (event.node.chatroom.client === undefined || event.node.chatroom.client.id !== this.amplify.currentClient.id) {
        return;
      }

      console.log("NEW CHATLINE SERVICE", event);
      const iframe = document.getElementById("unity-iframe");

      if (iframe != undefined && !event.node.system) {
        const iframeContentWindow = iframe.contentWindow;

        if (iframeContentWindow != null) {
          iframeContentWindow.postMessage({
            type: "privateMessage",
            data: {
              user: event.node.user.id,
              data: {
                message: event.node.message,
                whisper: true,
                area: ""
              }
            }
          }, "*");
        }
      }

      this.trigger('chatline:created', event.node);
      let chatroomIsAvailable = false;

      if (this.get('amplify.currentUser.groups').includes('Stoerer') && event.node.chatroom.groupname === 'Stoerer') {
        chatroomIsAvailable = true;
      } else if (this.get('amplify.currentUser.groups').includes(event.node.chatroom.groupname) && !this.get('amplify.currentUser.groups').includes('Stoerer')) {
        chatroomIsAvailable = true;
      } else if (event.node.chatroom.autoEnter && !this.get('amplify.currentUser.groups').includes('Stoerer')) {
        chatroomIsAvailable = true;
      } else if ((_event$node$chatroom$ = event.node.chatroom.privateusersmap) !== null && _event$node$chatroom$ !== void 0 && _event$node$chatroom$.includes(this.get('amplify.currentUser.id'))) {
        chatroomIsAvailable = false;
      } else {
        chatroomIsAvailable = false;
      }

      if ((_event$node$chatroom$2 = event.node.chatroom.privateusersmap) !== null && _event$node$chatroom$2 !== void 0 && _event$node$chatroom$2.includes(this.amplify.currentUser.id) && event.node.user.id !== this.get('amplify.currentUser.id') || chatroomIsAvailable) {
        this.unreadMessages.pushObject(event.node);
      }
    } // Maybe unused? oldschool private message


    async newChatline(message, target) {
      const variables = {
        data: {
          client: {
            connect: {
              id: this.get('amplify.currentClient.id')
            }
          },
          message: message,
          target: target,
          user: {
            connect: {
              id: this.get('amplify.currentUser.id')
            }
          }
        }
      };
      let chatline = await this.apollo.mutate({
        mutation: CREATE_CHATLINE,
        variables
      }, 'createChatline');
    }

    async newPrivateMessage(message, target) {
      console.log(target, this.get('amplify.currentUser.id'));
      const room = await this.getChatroom(this.get('amplify.currentUser.id'), target);
      const variables = {
        data: {
          chatroom: {
            connect: {
              id: room.id
            }
          },
          client: {
            connect: {
              id: this.get("amplify.currentClient.id")
            }
          },
          message: message,
          user: {
            connect: {
              id: this.get("amplify.currentUser.id")
            }
          }
        }
      };
      let chatline = await this.apollo.mutate({
        mutation: CREATE_CHATLINE,
        variables
      }, 'createChatline');
    }

    async newSystemMessage(type, data) {
      switch (type) {
        case SystemMessageTypes.VideoChat:
          this.sendVideochatSystemMessage(data);
          break;

        default:
          break;
      }
    }

    async sendVideochatSystemMessage(data) {
      // Filter other Members to send the System message to
      const otherMembers = data.members.filter(u => u.id != data.user);
      console.log(otherMembers, data.members);

      for (const member of otherMembers) {
        let room = await this.getChatroom(this.get('amplify.currentUser.id'), member.id); // Chatroom doesnt exist yet. so we have to create one

        if (room == undefined) {
          room = await this.createPrivateChatroom(member);
        } // still undefined???? rip


        if (room == undefined) {
          console.error("ROOM Undefined in Systemmessage");
          return undefined;
        }

        let duration = undefined;

        if (data.duration != undefined && data.duration < 3600) {
          duration = new Date(+data.duration * 1000).toISOString().substr(14, 5);
        } else if (data.duration != undefined) {
          duration = new Date(+data.duration * 1000).toISOString().substr(11, 5);
        }

        const variables = {
          data: {
            chatroom: {
              connect: {
                id: room.id
              }
            },
            client: {
              connect: {
                id: this.get("amplify.currentClient.id")
              }
            },
            message: data.messageType,
            user: {
              connect: {
                id: this.get("amplify.currentUser.id")
              }
            },
            system: true,
            videochatMessage: {
              duration,
              members: data.members,
              sender: data.user,
              target: member.id
            }
          }
        };
        await this.apollo.mutate({
          mutation: CREATE_CHATLINE,
          variables
        }, 'createChatline');
      }

      return true;
    }

    async createPrivateChatroom(target) {
      const variables = {
        data: {
          name: `Privater Chat: ${target.firstname} ${target.lastname}`,
          privateuser: [this.amplify.currentUser, target],
          privateusersmap: `${this.amplify.currentUser.id}-${target.id}`,
          text: "",
          client: {
            connect: {
              id: this.amplify.currentClient.id
            }
          }
        }
      };
      let chatroom = await this.apollo.mutate({
        mutation: createChatroomMutation,
        variables
      }, "createChatroom");
      return chatroom;
    }

    async getChatroom(sender, target) {
      let room = this.chatrooms.find(room => {
        var _room$privateusersmap, _room$privateusersmap2;

        return ((_room$privateusersmap = room.privateusersmap) === null || _room$privateusersmap === void 0 ? void 0 : _room$privateusersmap.includes(target)) && ((_room$privateusersmap2 = room.privateusersmap) === null || _room$privateusersmap2 === void 0 ? void 0 : _room$privateusersmap2.includes(sender));
      });

      if (room == undefined) {
        console.error("Room not found in private message");
        const variables = {
          where: {
            client: {
              id: this.get("amplify.currentClient.id")
            }
          }
        };
        let chatrooms = await this.apollo.query({
          query: query2,
          variables,
          fetchPolicy: "network-only"
        }, "chatrooms");
        this.set("chatrooms", chatrooms);
        room = this.chatrooms.find(room => {
          var _room$privateusersmap3, _room$privateusersmap4;

          return ((_room$privateusersmap3 = room.privateusersmap) === null || _room$privateusersmap3 === void 0 ? void 0 : _room$privateusersmap3.includes(target)) && ((_room$privateusersmap4 = room.privateusersmap) === null || _room$privateusersmap4 === void 0 ? void 0 : _room$privateusersmap4.includes(sender));
        });

        if (room == undefined) {
          console.error("Room not found in private message #2");
          return undefined;
        }
      }

      return room;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unreadPrivateMessages", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "unreadPrivateMessages"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "unreadPublicMessages", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unreadPublicMessages"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "persistUnreadMessages", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "persistUnreadMessages"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "persistUnreadPmMessages", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "persistUnreadPmMessages"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = Chat;
});