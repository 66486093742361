define("event-components/components/app-register/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-register/template", "ember-concurrency-decorators", "event-components/gql/fragments", "graphql-tag"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _emberConcurrencyDecorators, _fragments, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const mutation = (0, _graphqlTag.default)`
mutation($data: UserCreateInput!) {
    createUser(
      data: $data
    ) {
      ${_fragments.USER_FRAGMENT}
    }
  }
`;
  const query = (0, _graphqlTag.default)`
query($where: UserWhereInput) {
    users(
      where: $where
    ) {
      ${_fragments.USER_FRAGMENT}
    }
  }
`;
  let AppRegister = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('chat'), _dec3 = Ember.inject.service('amplify'), _dec4 = Ember.inject.service('router'), _dec5 = Ember.inject.service(), (_class = class AppRegister extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    willDestroy() {
      this._super(...arguments);

      this.set('error', '');
      this.set('messageSend', false);
      this.set('accept', false);
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "error", '');
      (0, _defineProperty2.default)(this, "messageSend", false);
      (0, _defineProperty2.default)(this, "accept", false);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "chatService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor5, this);
      this.set('apollo', this.get('apolloService').createQueryManager());
    }

    scrollToTop() {
      Ember.run.next(this, function () {
        document.getElementsByClassName('alert')[0].scrollIntoView({
          behavior: "smooth"
        });
      });
    }

    *register() {
      var _this$password;

      if (!this.accept) {
        this.set('error', this.intl.t('login.register_accept_privacy'));
        this.scrollToTop();
        return;
      }

      if (this.password !== this.passwordwdh) {
        this.set('error', this.intl.t('ts.pass_not_identical'));
        this.scrollToTop();
        return;
      }

      if (((_this$password = this.password) === null || _this$password === void 0 ? void 0 : _this$password.length) < 6 || !this.password) {
        this.set('error', this.intl.t('ts.pass_too_short'));
        this.scrollToTop();
        return;
      }

      if (this.email == '') {
        this.set('error', this.intl.t('ts.enter_mail'));
        this.scrollToTop();
        return;
      }

      try {
        const Auth = this.amplify.Auth;
        let username = `${this.amplify.currentClient.kuerzel}${this.email.toLowerCase().trim()}`;
        const variables = {
          data: {
            username: username,
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email.toLowerCase().trim(),
            invitecode: '',
            groups: {
              set: 'default'
            },
            client: {
              connect: {
                id: this.amplify.currentClient.id
              }
            }
          }
        };
        let isUserNotUnique = yield this.userisNotUnique(username);

        if (isUserNotUnique) {
          this.set('error', this.intl.t('ts.user_exits'));
          return;
        }

        let prismaUser = yield this.apollo.mutate({
          mutation,
          variables
        }, 'createUser');
        const {
          user
        } = yield Auth.signUp({
          username: username,
          password: this.password,
          attributes: {
            email: this.email.toLowerCase().trim(),
            nickname: username,
            'custom:client': this.amplify.currentClient.id,
            'custom:userid': prismaUser.id // optional

          }
        });
      } catch (error) {
        console.log('error signing up:', error);

        if (error.code == 'UsernameExistsException') {
          this.set('error', this.intl.t('ts.user_exits_login'));
        }

        this.set('messageSend', false);
        return;
      }

      this.set('messageSend', true);
    }

    async userisNotUnique(username) {
      const variables = {
        where: {
          username: username
        }
      };
      let prismaUsers = await this.apollo.query({
        query,
        variables,
        fetchPolicy: 'network-only'
      }, 'users');
      return prismaUsers.length > 0;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chatService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "register", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype)), _class));
  _exports.default = AppRegister;
});