define("event-components/components/vote-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lGxzc8dq",
    "block": "{\"symbols\":[\"model\",\"&default\"],\"statements\":[[6,[37,8],[[32,0,[\"model\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"p\"],[14,0,\"chart-header\"],[12],[10,\"b\"],[12],[2,\"\\n    \"],[1,[32,1,[\"vote\",\"question\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"type\",\"plugins\",\"options\",\"data\"],[[32,1,[\"vote\",\"charttype\"]],[30,[36,2],[\"labels\"],null],[35,1],[35,0]]]]],[2,\"\\n  \"],[1,[30,[36,4],[[35,0]],null]],[2,\"\\n\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary btn-fullwidth btn-lg\"],[15,\"onclick\",[30,[36,6],[[35,5],[32,1,[\"vote\",\"id\"]]],null]],[12],[2,\"\\n    \"],[1,[30,[36,7],[\"vote.zurueck_zur_umfrage\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"data\",\"chartoptions\",\"array\",\"ember-chart\",\"log\",\"goBack\",\"perform\",\"t\",\"async-await\"]}",
    "moduleName": "event-components/components/vote-chart/template.hbs"
  });

  _exports.default = _default;
});