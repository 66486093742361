define("event-components/components/app-chat-private/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "event-components/components/app-chat/component", "graphql-tag", "@ember-decorators/component", "event-components/gql/fragments", "ember-concurrency-decorators"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _graphqlTag, _component2, _fragments, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  const CREATE_CHATLINE = (0, _graphqlTag.default)`
  mutation($data: ChatlineCreateInput!) {
    createChatline(
      data: $data
    ) {
      ${_fragments.CHATLINE_FRAGMENT}
    }
  }
`;
  const query = (0, _graphqlTag.default)`
  query ($where: ChatlineWhereInput!, $last: Int, $after: String) {
    chatlines(where: $where, last: $last, after: $after) {
      id
      message
      target

      user {
        id
        username
        firstname
        lastname
        avatarthumb {
          id
          key
        }
      }
      createdAt
      updatedAt
    }
  }
`;
  const query2 = (0, _graphqlTag.default)`
  query($where: ChatroomWhereInput!) {
    chatrooms(
      where: $where
    ) {
      ${_fragments.CHATROOM_FRAGMENT}
    }
  }
`;
  const mutation = (0, _graphqlTag.default)`
mutation createChatroom($data: ChatroomCreateInput!) {
  createChatroom(data: $data) {
    ${_fragments.CHATROOM_FRAGMENT}
  }
}
`;
  let AppChat = (_dec = (0, _component2.classNames)("app-chat"), _dec2 = Ember._action, _dec3 = (0, _emberConcurrencyDecorators.task)(), _dec(_class = (_class2 = class AppChat extends _component.default.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "chatIsPrivate", true);
    }

    videocall(id) {
      const iframe = document.getElementById('videochat');

      if (iframe != null) {
        this.amplify.set('videoChatOpen', true);
        iframe.contentWindow.postMessage({
          type: 'invite',
          data: id
        }, '*');
      }
    }

    *loadChatrooms() {
      try {
        const variables = {
          where: {
            client: {
              id: this.get("amplify.currentClient.id")
            }
          }
        };
        let chatrooms = yield this.apollo.query({
          query: query2,
          variables,
          fetchPolicy: "network-only"
        }, "chatrooms");
        chatrooms = chatrooms.filter(c => {
          var _c$privateusersmap;

          if ((_c$privateusersmap = c.privateusersmap) !== null && _c$privateusersmap !== void 0 && _c$privateusersmap.includes(this.get("amplify.currentUser.id"))) {
            return true;
          } else {
            return false;
          }
        });
        this.set("chatrooms", chatrooms);
        if (this.target !== null) this.openPrivateChat(this.target);

        if (!Ember.isEmpty(this.chat)) {
          let chat = this.chatrooms.findBy("id", this.chat);
          this.set("currentChatroom", chat);
        }
      } catch (e) {
        console.error(e);
      }
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "videocall", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "videocall"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadChatrooms", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "loadChatrooms"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppChat;
});