define("ember-x-tabs/templates/components/x-tab/nav-wrap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+8cDbN3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],null,[[\"paneId\",\"active\",\"selectAction\"],[[35,1],[30,[36,2],[[35,0,[\"activeId\"]],[35,1]],null],[35,0,[\"selectAction\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tab\",\"paneId\",\"eq\",\"x-tab/nav-item\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tab/nav-wrap.hbs"
  });

  _exports.default = _default;
});