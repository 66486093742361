define("event-components/repository/user/service", ["exports", "ember-apollo-client", "graphql-tag", "ember-inflector"], function (_exports, _emberApolloClient, _graphqlTag, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const modelName = "User";
  const modelNameP = (0, _emberInflector.pluralize)(modelName).toLowerCase();
  const baseFragment = `
  name
`;
  const CREATE_MUTATION = (0, _graphqlTag.default)`
  mutation ($data: ${modelName}CreateInput!) {
    create${modelName}(data: $data) {
      id
      ${baseFragment}
    }
  }
`;
  const UPDATE_MUTATION = (0, _graphqlTag.default)`
  mutation ($data: ${modelName}UpdateInput!, $where: ${modelName}WhereUniqueInput!) {
    update${modelName}(data: $data, where: $where) {
      id
      ${baseFragment}
    }
  }
`;
  const DELETE_MUTATION = (0, _graphqlTag.default)`
  mutation ($where: ${modelName}WhereUniqueInput!) {
    delete${modelName}(where: $where) {
      id
    }
  }
`;
  const FINDALL_QUERY = (0, _graphqlTag.default)`
  query findAll {
    ${modelNameP} {
      id
      ${baseFragment}
    }
  }
`;

  const removeTypename = value => {
    if (value === null || value === undefined) {
      return value;
    } else if (Array.isArray(value)) {
      return value.map(v => removeTypename(v));
    } else if (typeof value === "object") {
      const newObj = {};
      Object.entries(value).forEach(([key, v]) => {
        if (key !== "__typename") {
          newObj[key] = removeTypename(v);
        }
      });
      return newObj;
    }

    return value;
  };

  class RepositoryUser extends Ember.Service.extend(_emberApolloClient.ObjectQueryManager) {
    findAll() {
      let model = this.get("apollo").query({
        query: FINDALL_QUERY,
        variables: {},
        fetchPolicy: "network-only"
      }, "users");
      return model;
    }

    delete(userId) {
      let response = this.get("apollo").mutate({
        mutation: DELETE_MUTATION,
        variables: {
          where: {
            id: userId
          }
        },
        fetchPolicy: "no-cache",
        refetchQueries: ["findAll"]
      });
      return response;
    }

    update(data) {
      const id = data.id;
      delete data.id;
      const variables = {
        data: removeTypename(data),
        where: {
          id: id
        }
      };
      return this.get("apollo").mutate({
        mutation: UPDATE_MUTATION,
        variables,
        refetchQueries: ["findAll"]
      }, "user");
    }

    create(data) {
      const variables = {
        data: data
      };
      return this.get("apollo").mutate({
        mutation: CREATE_MUTATION,
        variables,
        refetchQueries: ["findAll"]
      }, "user");
    }

  } // DO NOT DELETE: this is how TypeScript knows how to look up your services.


  _exports.default = RepositoryUser;
});