define("event-components/components/app-umfrage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZgRTQ0PW",
    "block": "{\"symbols\":[\"imagepath\",\"&default\"],\"statements\":[[6,[37,2],[[35,0,[\"image\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"header\"],[15,1,[31,[\"h-\",[34,0,[\"id\"]]]]],[12],[2,\"\\n      \"],[8,\"aws-image\",[],[[\"@file\",\"@tagless\"],[[34,0,[\"image\"]],\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"style\"],[12],[2,\"\\n              #h-\"],[1,[35,0,[\"id\"]]],[2,\" {\\n                  background-image: url(\"],[1,[32,1]],[2,\");\\n                  background-repeat: no-repeat;\\n                  width: 100%;\\n                  background-size: cover;\\n                  min-height: \"],[1,[30,[36,2],[[35,1,[\"currentTheme\",\"data\",\"voteImageHeight\"]],[35,1,[\"currentTheme\",\"data\",\"voteImageHeight\"]],100],null]],[2,\"px;\\n              }\\n          \"],[13],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"vote\",\"amplify\",\"if\"]}",
    "moduleName": "event-components/components/app-umfrage/template.hbs"
  });

  _exports.default = _default;
});