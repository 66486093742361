define("event-components/components/unity-nav/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "event-components/components/unity-nav/template", "@ember-decorators/component", "eventapp-stream/components/module-nav/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _template, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  let UnityNav = (_dec = (0, _component.tagName)("nav"), _dec2 = Ember._action, _dec(_class = (_class2 = class UnityNav extends _component2.default {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "stateName", "unity-nav");
      (0, _defineProperty2.default)(this, "navHandler", void 0);
      (0, _defineProperty2.default)(this, "fullscreenItem", {
        id: "99",
        key: "",
        header: "Fullscreen",
        headeritem: "",
        iconname: "icons_minimieren.svg",
        icon: "",
        tooltip: "Vollbild beenden"
      });
    }

    goTo(navItem) {
      this.set("activeModule", navItem);
      this.navHandler(navItem);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "goTo", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "goTo"), _class2.prototype)), _class2)) || _class);
  _exports.default = UnityNav;
});