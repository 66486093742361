define("event-components/components/app-dashboard/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "event-components/gql/fragments", "graphql-tag", "event-components/components/app-dashboard/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _fragments, _graphqlTag, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  const query = (0, _graphqlTag.default)`
  query appointments($where: AppointmentWhereInput) {
    appointments(where: $where) {
      ${_fragments.APPOINTMENT_FRAGMENT}
    }
  }
`;
  let AppDashboard = (_dec = (0, _component.tagName)('div'), _dec2 = (0, _component.classNames)('app-dashboard'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('graphql'), _dec5 = Ember.inject.service('amplify'), _dec6 = Ember.computed('amplify.currentTheme.data.moduleConfig.[]'), _dec7 = Ember.computed('modules.[]'), _dec8 = (0, _component.className)('only2'), _dec9 = Ember.computed('itemsCount'), _dec(_class = _dec2(_class = (_class2 = class AppDashboard extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    get modules() {
      if (!this.get('amplify.currentTheme.data.moduleConfig')) {
        return [];
      }

      let mods = this.get('amplify.currentTheme.data.moduleConfig');
      mods = mods.filterBy('showInEvent', true);

      if (this.isMobile.any) {
        mods = mods.filterBy('showInMobile', true);
      }

      if (!this.isMobile.any) {
        mods = mods.filterBy('showInDesktop', true);
      }

      mods.sort((a, b) => parseInt(a.sorting) > parseInt(b.sorting) ? 1 : parseInt(b.sorting) > parseInt(a.sorting) ? -1 : 0);
      return mods;
    }

    get itemsCount() {
      return this.get('modules.length');
    }

    get isOnlyTwo() {
      return this.get('modules.length') == 2;
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "isMobile", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apollo", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor3, this);
      (0, _defineProperty2.default)(this, "appointmentNotifications", 0);
      this.getAppointmentNotifications();
    }

    async getAppointmentNotifications() {
      this.set('appointmentNotifications', 0);

      if (!this.amplify.isAuthenticated) {
        return false;
      }

      const variablesOA = {
        where: {
          user: {
            id: this.amplify.currentUser.id
          }
        }
      };
      const variablesPA = {
        where: {
          requestUser: {
            id: this.amplify.currentUser.id
          }
        }
      };
      let pendingAppointments = await this.apollo.query({
        query,
        variables: variablesPA,
        fetchPolicy: 'network-only'
      }, 'appointments');
      let owningAppointments = await this.apollo.query({
        query,
        variables: variablesOA,
        fetchPolicy: 'network-only'
      }, 'appointments');
      owningAppointments.forEach(a => {
        if (a.requestUser != null) {
          this.set('appointmentNotifications', this.appointmentNotifications + 1);
        }
      });
      pendingAppointments.forEach(a => {
        if (a.approved === true) {
          this.set('appointmentNotifications', this.appointmentNotifications + 1);
        }
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isMobile", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apollo", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "modules", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "modules"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "itemsCount", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "itemsCount"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isOnlyTwo", [_dec8, _dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "isOnlyTwo"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = AppDashboard;
});