define("event-components/components/app-tab/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "event-components/components/app-tab/template"], function (_exports, _defineProperty2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @ts-ignore: Ignore import of compiled template
  class AppTab extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
    }

  }

  _exports.default = AppTab;
});