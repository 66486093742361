define("event-components/module/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Module extends Ember.Service.extend({// anything which *must* be merged to prototype here
  }) {// normal class body definition here
  } // DO NOT DELETE: this is how TypeScript knows how to look up your services.


  _exports.default = Module;
});