define("event-components/components/twitter-wall/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "@ember-decorators/object", "event-components/components/twitter-wall/template", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _object, _template, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  const query = (0, _graphqlTag.default)`
query walls(
  $where: WallWhereInput!, 
  ) {
    walls(
      where: $where
    ) {
      ${_fragments.WALL_FRAGMENT}
    }
}
`;
  let TwitterWall = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("amplify"), _dec3 = (0, _object.observes)("currentWall"), _dec4 = (0, _object.observes)("currentWallId"), (_class = class TwitterWall extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    onCurrentWall() {
      var _this$currentWall;

      this.amplify.set("currentModuleItem", (_this$currentWall = this.currentWall) === null || _this$currentWall === void 0 ? void 0 : _this$currentWall.name); //this.set("currentWall", this.model.walls.findBy("id", this.currentWallId));
    }

    onCurrentWallId() {
      Ember.run.next(this, function () {
        this.loadWalls().then(model => {
          let wall = model.walls.findBy("id", this.currentWallId.id);
          this.amplify.set("currentModuleItem", wall.name);
          this.set("currentWallId", wall.id);
          this.set("currentWall", wall);
        });
      });
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "id", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor3, this);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "oberserverable", void 0);
      (0, _defineProperty2.default)(this, "currentWall", false);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.get("amplify").on("moduleitem:unset", this, "unset");
      this.loadWalls();
      let unreadMessages = 4;
    }

    willDestroyElement() {
      this.get("amplify").off("moduleitem:unset", this, "unset");
    }

    unset() {
      this.set("currentWall", null);
    }

    async loadWalls() {
      let client = this.amplify.currentClient;
      const variables = {
        where: {
          client: {
            id: client.id
          }
        }
      };
      const walls = await this.apollo.query({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "walls");
      this.set("model", {
        walls,
        client
      });

      if (this.currentWallId) {
        this.set("currentWall", walls.findBy("id", this.currentWallId));
      }

      return {
        walls,
        client
      };
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "id", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "wall";
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCurrentWall", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onCurrentWall"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCurrentWallId", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onCurrentWallId"), _class.prototype)), _class));
  _exports.default = TwitterWall;
});