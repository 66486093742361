define("event-components/components/app-programm/component", ["exports", "@ember-decorators/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let AppProgramm = (_dec = (0, _component.classNames)("app-programm"), _dec(_class = class AppProgramm extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {// normal class body definition here
  }) || _class);
  _exports.default = AppProgramm;
});