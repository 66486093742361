define("event-components/components/app-avatar-photo/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-cli-image-cropper/components/image-cropper", "@ember-decorators/component", "event-components/components/app-avatar-photo/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _imageCropper, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2, _descriptor;

  let AppAvatarPhoto = (_dec = (0, _component.classNames)("app-avatar-photo"), _dec2 = Ember.inject.service(), _dec3 = Ember._action, _dec(_class = (_class2 = class AppAvatarPhoto extends _imageCropper.default.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _defineProperty2.default)(this, "aspectRatio", 1);
      (0, _defineProperty2.default)(this, "minCropBoxWidth", 100);
      (0, _defineProperty2.default)(this, "minCropBoxHeight", 100);
      (0, _defineProperty2.default)(this, "cropperContainer", ".cropper-container > img");
      (0, _defineProperty2.default)(this, "previewClass", ".img-preview");
      (0, _defineProperty2.default)(this, "croppedAvatar", null);
      (0, _defineProperty2.default)(this, "checkCrossOrigin", false);
    }

    didRecieveAttr() {}

    didInsertElement() {
      this._super(...arguments);

      let properties;
      properties = this.getProperties("cropperContainer", "aspectRatio", "crop", "previewClass", "viewMode", "dragMode", "responsive", "center", "checkCrossOrigin", "toggleDragModeOnDblclick", "background", "modal", "guides", "highlight", "autoCrop", "autoCropArea", "dragDrop", "movable", "resizable", "zoomable", "zoomOnWheel", "zoomOnTouch", "cropBoxMovable", "cropBoxResizable", "rotateable", "minContainerWidth", "minContainerHeight", "minCropBoxWidth", "minCropBoxHeight", "build", "built", "dragStart", "dragMove", "dragEnd", "zoomin", "zoomout", "ready", "data");
      properties["preview"] = properties["previewClass"];
      delete properties["previewClass"];
      let image = document.querySelector(properties["cropperContainer"]);
      const cropper = new Cropper(image, properties);
      this.set("cropper", cropper);
      let croppedImage = cropper.getCroppedCanvas();
      let cropping = window.localStorage.getItem("cropping");

      if (cropping !== null) {
        console.log("set crop data", JSON.parse(cropping));
        this.get("cropper").setCropBoxData(JSON.parse(cropping));
      }

      image.addEventListener("crop", event => {
        this.get("cropper").getCroppedCanvas({
          width: 300,
          height: 300,
          fillColor: '#fff',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high'
        }).toBlob(value => {
          this.blob = value;
        }, 'image/jpeg', 1);
      });
    }

    willDestroy() {
      let cropConfig = this.get("cropper").getCropBoxData();
      window.localStorage.setItem("cropping", JSON.stringify(cropConfig));
    }

    async updateImage() {
      await this.onDataChange.perform(this.blob);
      this.router.transitionTo("profil");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "updateImage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "updateImage"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppAvatarPhoto;
});