define("ember-browser-update/initializers/browser-update", ["exports", "browser-update"], function (_exports, _browserUpdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    if (typeof FastBoot === 'undefined') {
      if (application.hasRegistration('config:environment')) {
        const config = application.resolveRegistration('config:environment');
        (0, _browserUpdate.default)(config['browserUpdate']);
      }
    }
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});