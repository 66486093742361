define("ember-class-based-modifier/-private/modifier-native", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-class-based-modifier/-private/modifier-manager"], function (_exports, _defineProperty2, _modifierManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNative = isNative;
  _exports.destroy = destroy;
  _exports.default = void 0;
  const IS_NATIVE = Symbol('native');
  const DESTROYING = Symbol('destroying');
  const DESTROYED = Symbol('destroyed');

  class ClassBasedModifier {
    static create(options) {
      let owner = Ember.getOwner(options);
      let {
        args
      } = options;
      return new this(owner, args);
    }

    constructor(owner, args) {
      (0, _defineProperty2.default)(this, IS_NATIVE, true);
      (0, _defineProperty2.default)(this, DESTROYING, false);
      (0, _defineProperty2.default)(this, DESTROYED, false);
      Ember.setOwner(this, owner);
      this.element = null;
      this.args = args;
    }

    didReceiveArguments() {}

    didUpdateArguments() {}

    didInstall() {}

    willRemove() {}

    willDestroy() {}

    get isDestroying() {
      return this[DESTROYING];
    }

    get isDestroyed() {
      return this[DESTROYED];
    }

  }

  _exports.default = ClassBasedModifier;

  Ember._setModifierManager(() => _modifierManager.default, ClassBasedModifier);

  function isNative(modifier) {
    return modifier[IS_NATIVE] === true;
  }

  function destroy(modifier) {
    if (modifier[DESTROYING]) {
      return;
    }

    let meta = Ember.meta(modifier);
    meta.setSourceDestroying();
    modifier[DESTROYING] = true;
    Ember.run.schedule('actions', modifier, modifier.willDestroy);
    Ember.run.schedule('destroy', undefined, scheduleDestroy, modifier, meta);
  }

  function scheduleDestroy(modifier, meta) {
    if (modifier[DESTROYED]) {
      return;
    }

    Ember.destroy(modifier);
    meta.setSourceDestroyed();
    modifier[DESTROYED] = true;
  }
});