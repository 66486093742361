define("event-components/components/app-map-beacondetail/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "event-components/components/app-map-beacondetail/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  let AppMapBeacondetail = (_dec = (0, _component.className)("isOpen", "isClosed"), _dec2 = Ember.computed("beacon"), _dec3 = Ember._action, (_class = class AppMapBeacondetail extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "isOpen", _descriptor, this);
    }

    get beaconClass() {
      if (this.beacon) {
        return `beacon-border-${this.beacon.id}`;
      } else {
        return "";
      }
    }

    click() {
      this.toggleProperty("isOpen");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isOpen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "beaconClass", [_component.className, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "beaconClass"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "click", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = AppMapBeacondetail;
});