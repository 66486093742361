define("event-components/components/app-tabs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D7msYCJZ",
    "block": "{\"symbols\":[\"tab\",\"index\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"tabs tabs-count-\",[34,4,[\"length\"]]]]],[14,1,\"tabs\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[16,0,[31,[\"tab \",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"changeTab\",[32,1]],null],[12],[2,\"\\n            \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"underline\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,3,[[30,[36,7],null,[[\"active\"],[[35,0]]]]]]],\"hasEval\":false,\"upvars\":[\"activeTab\",\"eq\",\"if\",\"action\",\"tabs\",\"-track-array\",\"each\",\"hash\"]}",
    "moduleName": "event-components/components/app-tabs/template.hbs"
  });

  _exports.default = _default;
});