define("event-components/components/vote-list-module/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AFdFV9k0",
    "block": "{\"symbols\":[\"model\",\"vote\",\"&default\"],\"statements\":[[6,[37,6],[[32,0,[\"model\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"app-umfrage-vote\",[],[[\"@model\",\"@vote\",\"@votes\",\"@showChart\"],[[34,4],[34,4],[32,1,[\"votes\"]],[34,5]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"app-content\"],[14,5,\"padding: 0px;\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1,[\"votes\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"app-umfrage\",[],[[\"@vote\",\"@onClickHandler\"],[[32,2],[30,[36,0],[[32,0],\"voteDetail\",[32,2],false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,5,\"width:100%;\"],[14,0,\"app-umfrage-text\"],[12],[2,\"\\n              \"],[10,\"div\"],[12],[2,\"\\n                \"],[1,[32,2,[\"question\"]]],[2,\"\\n              \"],[13],[2,\"\\n\"],[6,[37,1],[[32,2,[\"showresult\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"button\"],[4,[38,0],[[32,0],\"voteDetail\",[32,2],true],null],[12],[2,\"\\n                  \"],[10,\"i\"],[14,0,\"fa fa-bar-chart\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[18,3,null]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"-track-array\",\"each\",\"currentVote\",\"showChart\",\"async-await\"]}",
    "moduleName": "event-components/components/vote-list-module/template.hbs"
  });

  _exports.default = _default;
});