define("ember-cli-meta-tags/templates/components/head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ldXuwD3s",
    "block": "{\"symbols\":[\"headTag\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"headTag\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"head-tag\",\"headTags\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-cli-meta-tags/templates/components/head-tags.hbs"
  });

  _exports.default = _default;
});