define("event-components/components/vote-chart/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/vote-chart/template", "ember-concurrency-decorators", "@ember-decorators/object", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _emberConcurrencyDecorators, _object, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const query = (0, _graphqlTag.default)`
  query votes($where: VoteWhereInput!) {
    votes(where: $where) {
      ${_fragments.VOTE_FRAGMENT}
    }
  }
`;
  let VoteChart = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service('amplify'), _dec3 = Ember.inject.service('vote'), _dec4 = Ember.inject.service('graphql'), _dec5 = Ember.inject.service('cookies'), _dec6 = (0, _object.observes)('currentVote.id'), _dec7 = Ember.computed('model.vote.charttype'), _dec8 = (0, _emberConcurrencyDecorators.task)(), _dec9 = (0, _emberConcurrencyDecorators.task)(), _dec10 = Ember.computed('currentAnswer', 'model.vote.votes.[]', 'voteService.votes.[]'), _dec11 = Ember.computed('model.vote.votes.[]', 'currentVote.vote.votes.[]'), (_class = class VoteChart extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    onCurrentVoteChanged() {
      this.set('currentVote', this.currentVote.id);
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "voteService", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "cookies", _descriptor5, this);
      (0, _defineProperty2.default)(this, "variableChartOptions", {
        layoutPaddingTop: 25,
        legendDisplay: false
      });
      this.set('apollo', this.get('apolloService').createQueryManager());
    }

    async loadModel() {
      let vote, votes;
      const client = this.amplify.get('currentClient');
      votes = this.get('voteService.votes');
      vote = votes.findBy('id', this.voteId);
      this.set('model', {
        vote,
        votes,
        user: client.user,
        client: client
      }); // variable chart options

      console.log(vote.charttype);

      switch (vote.charttype) {
        case 'bar':
          this.variableChartOptions.layoutPaddingTop = 50;
          break;

        case 'pie':
          this.variableChartOptions.legendDisplay = true;
          break;

        case 'doughnut':
          this.variableChartOptions.legendDisplay = true;
          break;

        default:
          break;
      }
    }

    didInsertElement() {
      Ember.run.later(this, function () {
        this.set('height', this.element.offsetHeight);
      }, 0);
    }

    get chartoptions() {
      return {
        animation: {
          duration: 0
        },
        aspectRatio: 1,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: this.variableChartOptions.layoutPaddingTop,
            bottom: 0
          }
        },
        plugins: {
          labels: [{
            render: "label",
            // position: "outside",
            fontSize: 14,
            fontStyle: 'bold'
          } // {
          //   render: function (args) {
          //     return ``;
          //   },
          //   fontSize: 14,
          //   fontStyle: "bold",
          // },
          ]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              //get the concerned dataset
              var dataset = data.datasets[tooltipItem.datasetIndex]; //calculate the total of this data set

              var total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                return previousValue + currentValue;
              }); //get the current items value

              var currentValue = dataset.data[tooltipItem.index]; //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number

              var percentage = Math.floor(currentValue / total * 100 + 0.5);
              return percentage + '%';
            }
          }
        },
        legend: {
          display: this.variableChartOptions.legendDisplay,
          // pie
          position: 'bottom'
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              display: true
            },
            ticks: {
              beginAtZero: true,
              userCallback: function (label, index, labels) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            }
          }],
          yAxes: [{
            ticks: {
              display: false,
              beginAtZero: true
            },
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              display: false
            }
          }]
        }
      };
    }

    *goBack(voteId) {
      this.set('amplify.currentModuleItem3rd', null);
    }

    *reload(voteId) {
      let route = Ember.getOwner(this).lookup(`route:votechart`);
      return route.refresh();
    }

    get data() {
      try {
        if (!this.get('model.vote.votes')) {
          return null;
        }

        function getSum(total, num) {
          return total + Math.round(num);
        }

        const data = this.get('model.vote.votes').reduce((data, vote) => {
          let index = this.get('model.vote.values').indexOf(vote.vote);

          if (data[index] === undefined) {
            data[index] = 0;
          }

          data[index] = data[index] + 1;
          return data;
        }, []);
        return {
          labels: this.get('model.vote.values').map((v, index) => {
            let all = data.reduce(getSum, 0);
            if (data[index] === undefined) data[index] = 0;
            return `${v} (${Math.round(data[index] / all * 100 * 100) / 100}%)`;
          }),
          datasets: [{
            data: data,
            borderSkipped: false,
            borderColor: 'rgba(0, 0, 0, 0)',
            backgroundColor: this.get('model.vote.colors').map(c => {
              if (c.includes('#')) {
                return `${c}`;
              } else {
                return `#${c}`;
              }
            })
          }]
        };
      } catch (e) {
        console.error(e);
      }
    }

    didReceiveAttrs() {
      this.set('voteid', this.get('cookies').read(`vote-${this.votescope}`));
      this.loadModel();
    }

    get currentAnswers() {
      if (this.get('model.vote.votes') == null) {
        return false;
      }

      return this.get('model.vote.votes').filterBy('user', this.amplify.currentUser.id);
    }

    willDestroy() {
      this.get('voteService').off('vote:changed');
    }

    onVotesChanged(vote) {
      console.log(...arguments);

      if (vote.id === this.currentVote.id) {
        this.set('currentVote', vote);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "voteService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cookies", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCurrentVoteChanged", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onCurrentVoteChanged"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "chartoptions", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "chartoptions"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goBack", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "reload", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "reload"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "data", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "data"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentAnswers", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "currentAnswers"), _class.prototype)), _class));
  _exports.default = VoteChart;
});