define("event-components/components/vote-module/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gk3tb3fp",
    "block": "{\"symbols\":[\"model\",\"&default\"],\"statements\":[[6,[37,0],[[32,0,[\"model\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"app-umfrage-vote\",[],[[\"@model\",\"@vote\",\"@votes\"],[[32,1,[\"vote\"]],[32,1,[\"vote\"]],[32,1,[\"votes\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"async-await\"]}",
    "moduleName": "event-components/components/vote-module/template.hbs"
  });

  _exports.default = _default;
});