define("event-components/components/twitter-post-create/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-concurrency-decorators", "graphql-tag", "event-components/gql/fragments", "event-components/components/twitter-post-create/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _graphqlTag, _fragments, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  let TwitterPostCreate = (_dec = (0, _component.classNames)("twitter-wall-create"), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service("graphql"), _dec4 = Ember.inject.service("notifications"), _dec5 = (0, _component.className)("open", "closed"), _dec6 = (0, _component.attribute)("data-state"), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = (0, _emberConcurrencyDecorators.enqueueTask)(), _dec12 = Ember._action, _dec(_class = (_class2 = class TwitterPostCreate extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "textareaIsFocused", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "isAddingPhotos", _descriptor5, this);
      (0, _defineProperty2.default)(this, "files", []);
      (0, _defineProperty2.default)(this, "size", 42.5);
      (0, _defineProperty2.default)(this, "text", "");
      (0, _defineProperty2.default)(this, "postlocations", []);
      (0, _defineProperty2.default)(this, "cameraAccessDenied", false);
      (0, _initializerDefineProperty2.default)(this, "uploadFile", _descriptor6, this);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    didReceiveAttrs() {
      this.loadLocations.perform();
      this.checkCameraAccess();
    }

    checkCameraAccess() {
      let successCallback = error => {};

      let errorCallback = error => {
        if (error.name == "NotAllowedError") {
          this.set("cameraAccessDenied", true);
        }
      };

      const constraints = {
        video: true
      };
    }

    *uploadFiles(file) {
      var _file$target;

      const files = (_file$target = file.target) === null || _file$target === void 0 ? void 0 : _file$target.files;

      for (let i = 0; i < files.length; i++) {
        // console.log(this.get("client"), this.get("user"), files[i]);
        let file = yield this.get("uploadFile").perform(this.get("client"), this.get("user"), files[i]);
        this.set("files", [file, ...this.files]);
      }
    }

    deleteImage(index) {
      let files = this.get("files");

      if (index > -1 && files) {
        console.log(files);
        console.log(index);

        for (let i = 0; i < files.length; i++) {
          console.log(i, files[i]);
        }

        files.splice(index, 1);
        this.set("files", [...files]);
        console.log(this.get("files"));
      }
    }

    onFocusTextarea() {
      console.log("onFocusTextarea");
      this.set("textareaIsFocused", true);
      Ember.run.later(this, () => {
        window.scroll(0, 0);
      }, 100);
      this.set("size", 42.5);
    }

    onFocusOutTextarea() {
      console.log("onFocusOutTextarea");
      this.set("textareaIsFocused", false);
      this.set("size", 80);
    }

    addLocation(location) {
      this.set("postlocations", [location]);
    }

    *loadLocations() {
      console.log("here");

      try {
        const variables = {
          where: {
            client: {
              id: this.client.id
            }
          }
        };
        const query = (0, _graphqlTag.default)`
      query($where: BeaconWhereInput!) {
        beacons(where: $where) {
          ${_fragments.BEACON_FRAGMENT}
        }
      }
      `;
        console.log("here2");
        let beacons = yield this.apollo.query({
          query,
          variables,
          fetchPolicy: "network-only"
        }, "beacons");
        console.log("here3");
        this.set("locations", beacons);
        console.log("locations loaded");
      } catch (e) {
        console.error(e);
      }
    }

    *createPost() {
      let locations;

      if (this.postlocations.firstObject) {
        locations = {
          connect: {
            id: this.postlocations.firstObject.id
          }
        };
      } else {
        locations = {};
      }

      const variables = {
        data: {
          client: {
            connect: {
              id: this.client.id
            }
          },
          wall: {
            connect: {
              id: this.wallId
            }
          },
          author: {
            connect: {
              id: this.user.id
            }
          },
          text: this.text,
          locations,
          images: {
            create: [...this.files.map(item => {
              return {
                key: item.key,
                level: "PRIVATE",
                client: {
                  connect: {
                    id: this.client.id
                  }
                }
              };
            })]
          }
        }
      };
      const mutation = (0, _graphqlTag.default)`
    mutation($data: PostCreateInput!) {
      createPost(data: $data) {
        ${_fragments.POST_FRAGMENT}
      }
    }
    `;
      this.send("onFocusOutTextarea");
      let result;

      try {
        result = yield this.apollo.mutate({
          mutation,
          variables
        }, "post");
        console.log(result);
        Ember.run.later(() => {
          this.get("notifications").success(this.intl.t('wall.zeit_validierung'), {
            autoClear: true
          });
        }, 500);
        this.set("files", []);
        this.set("text", "");
      } catch (e) {
        console.error(e);
      }
    }

    addPhoto() {
      this.set("isAddingPhotos", "yes");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apolloService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "notifications", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "textareaIsFocused", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isAddingPhotos", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "no";
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "uploadFiles", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "uploadFiles"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "deleteImage", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteImage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onFocusTextarea", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "onFocusTextarea"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onFocusOutTextarea", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "onFocusOutTextarea"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addLocation", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "addLocation"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadLocations", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "loadLocations"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "createPost", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "createPost"), _class2.prototype), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "uploadFile", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        progress: 0,
        image: "",
        amplify: Ember.inject.service(),

        *perform(client, user, file) {
          try {
            console.log(client, user);
            let reader = new FileReader();
            reader.addEventListener("load", () => {
              this.set("image", reader.result);
            }, false);
            reader.readAsDataURL(file);
            const filekey = `${client.id}/${user.id}/wall/${file.name}`;
            let result = yield this.get("amplify").Storage.put(filekey, file, {
              level: "public",
              progressCallback: event => {
                let progress = 100 / event.total * event.loaded;
                this.set("progress", progress);
              }
            });
            let fileUrl = yield this.get("amplify").Storage.get(result.key, {
              expires: 60,
              level: "public"
            });
            return {
              key: result.key,
              url: fileUrl
            };
          } catch (e) {
            this.get("amplify").Logger.error("[File Upload] ", e);
          }
        }

      };
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addPhoto", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "addPhoto"), _class2.prototype)), _class2)) || _class);
  _exports.default = TwitterPostCreate;
});