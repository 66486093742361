define("event-components/components/iframe-wrapper/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/iframe-wrapper/template", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor;

  let IframeWrapper = (_dec = (0, _component.tagName)("iframe"), _dec2 = Ember.computed("src"), _dec(_class = (_class2 = class IframeWrapper extends Ember.Component.extend({
    // anything which *must* be merged to prototype here
    classNameBindings: ["multIframeOpen"],
    multIframeOpen: false
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "src", _descriptor, this);
    }

    get allow() {
      return `camera ${this.src}; microphone ${this.src}`;
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "src", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "allow", [_component.attribute, _dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "allow"), _class2.prototype)), _class2)) || _class);
  _exports.default = IframeWrapper;
});