define("event-components/components/svg-mask/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "event-components/components/svg-mask/template", "@ember-decorators/component"], function (_exports, _defineProperty2, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let SvgMask = (_dec = (0, _component.tagName)(''), _dec(_class = class SvgMask extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "width", 32);
      (0, _defineProperty2.default)(this, "height", 32);
      this.uniqueId = Date.now();
    }

  }) || _class);
  _exports.default = SvgMask;
  ;
});