define("ember-x-tabs/components/x-tab/nav-item", ["exports", "ember-x-tabs/templates/components/x-tab/nav-item"], function (_exports, _navItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _navItem.default,
    tagName: 'li',
    classNameBindings: ['active:tab-current']
  });

  _exports.default = _default;
});