define("event-components/components/app-css/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "event-components/gql/fragments", "event-components/components/app-css/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _fragments, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  const query = (0, _graphqlTag.default)`
  query themes($where: ThemeWhereInput!) {
    themes(where: $where) {
      ${_fragments.THEME_FRAGMENT}
    }
  }
`;
  let AppCss = (_dec = Ember.inject.service("graphql"), (_class = class AppCss extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    async didInsertElement() {
      const variables = {
        where: {
          client: {
            id: this.client.id
          }
        }
      };
      let theme = await this.apollo.query({
        query,
        variables
      }, "themes");

      if (theme.length > 0) {
        this.set("theme", theme[0].data);
      } //fallback


      Chart.defaults.global.defaultFontColor = theme[0].data.textColorQuestion;
      window.addEventListener("message", e => {
        const data = e.data;

        try {
          console.log(data);

          if (data.apptitle) {
            this.set("theme", JSON.parse(data));
          }
        } catch (e) {}
      }, false);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppCss;
});