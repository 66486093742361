define("event-components/components/app-umfrage/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "event-components/components/app-umfrage/template"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let AppUmfrage = (_dec = Ember._action, (_class = class AppUmfrage extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
    }

    click() {
      this.onClickHandler(this.vote);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "click", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  _exports.default = AppUmfrage;
});