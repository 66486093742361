define("event-components/components/app-tabs/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "ember-concurrency-decorators", "event-components/components/app-tabs/template"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _emberConcurrencyDecorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  let AppTabs = (_dec = (0, _component.tagName)("nav"), _dec2 = (0, _component.classNames)("app-tabs"), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class AppTabs extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "activeTab", 0);
    }

    didReceiveAttrs() {
      this.set("activeTab", this.tabs.firstObject);
    }

    changeTab(tab) {
      this.set("activeTab", tab);
    }

    didInsertElement() {
      window.addEventListener("scroll", this.onScroll.bind(this), false);
    }

    *onScrollTask() {
      let pageYOffset = window.pageYOffset;
      let headerHeight = document.getElementById("header-image").getBoundingClientRect().height;
      let element = document.getElementById("tabs");

      if (pageYOffset >= headerHeight) {
        this.set("sticky", true);
      } else {
        this.set("sticky", false);
      }

      yield true;
    }

    onScroll(event) {
      this.onScrollTask.perform();
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "changeTab", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "changeTab"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onScrollTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "onScrollTask"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = AppTabs;
});