define("event-components/components/app-avatar-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JBtx5zfp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"label\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,1,\"avatar-upload\"],[15,\"onchange\",[30,[36,1],[[35,0]],null]],[14,5,\"display:none:\"],[14,4,\"file\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"onFileChange\",\"perform\"]}",
    "moduleName": "event-components/components/app-avatar-upload/template.hbs"
  });

  _exports.default = _default;
});