define("event-components/components/app-dashboard-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "event-components/components/app-dashboard-item/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  let AppDashboardItem = (_dec = (0, _component.tagName)("div"), _dec2 = (0, _component.classNames)("dashboard-item", "fade-in"), _dec3 = Ember.inject.service("chat"), _dec4 = Ember.inject.service("vote"), _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class AppDashboardItem extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "chatService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "voteService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
    }

    didInsertElement() {
      let width = this.element.getBoundingClientRect().width; //this.element.style.height = `${width}px`;
    }

    click() {
      if (this.route === "videocall") {
        let user = this.amplify.currentUser;
        window.open(`${this.amplify.currentTheme.data.meetingUrl}?userid=${user.id}`, "_blank");
        return;
      }

      if (this.external) {
        let link = this.link.replace("{{code}}", this.amplify.currentUser.invitecode).replace("{{username}}", this.amplify.currentUser.username);
        window.open(`${link}`, "_blank");
        return;
      }

      this.get("router").transitionTo(this.route);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "chatService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "voteService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "click", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "click"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = AppDashboardItem;
});