define("event-components/components/aws-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UAcvPzJx",
    "block": "{\"symbols\":[\"imagepath\",\"imagepath\",\"&default\"],\"statements\":[[2,\"\\n\"],[6,[37,6],[[32,0,[\"imagepath\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[27,[32,3]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,[[32,2]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[32,0,[\"imagepath\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[27,[32,3]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"span\"],[4,[38,0],[[32,0,[\"setupInViewport\"]]],null],[12],[13],[2,\"\\n      \"],[11,\"img\"],[16,1,[31,[[34,1]]]],[16,5,[31,[\"height: \",[34,2],\"px;\"]]],[16,0,[31,[[34,3]]]],[16,\"src\",[31,[[32,1]]]],[4,[38,4],[[32,0],\"pinchAction\"],[[\"on\"],[\"pinch\"]]],[4,[38,0],[[30,[36,4],[[32,0],\"didInsertImage\"],null]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"id\",\"mapHeight\",\"class\",\"action\",\"if\",\"async-await\",\"show\"]}",
    "moduleName": "event-components/components/aws-image/template.hbs"
  });

  _exports.default = _default;
});