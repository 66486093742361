define("event-components/components/app-chat-pm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lhPZ31BI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[24,5,\"float:right;\"],[4,[38,2],[[32,0],[30,[36,1],[[35,0]],null],null],null],[12],[2,\"\\n  \"],[10,\"i\"],[14,0,\"fa fa-remove\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"label\"],[12],[2,\"\\n  \"],[1,[30,[36,3],[\"chat.private_nachricht\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,5],null,[[\"value\",\"class\"],[[35,4],\"form-control\"]]]],[2,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-lg btn-primary\"],[4,[38,2],[[32,0],\"createPm\"],null],[12],[2,\"\\n  \"],[1,[30,[36,3],[\"senden\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"target\",\"mut\",\"action\",\"t\",\"message\",\"textarea\"]}",
    "moduleName": "event-components/components/app-chat-pm/template.hbs"
  });

  _exports.default = _default;
});