define("event-components/helpers/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.split = split;
  _exports.default = void 0;

  function split(params) {
    if (params[0] !== undefined && params[0] !== null && typeof params[0] == 'string') {
      return params[0].split(params[1]);
    } else {
      return "";
    }
  }

  var _default = Ember.Helper.helper(split);

  _exports.default = _default;
});