define("ember-gestures/components/async-element", ["exports", "ember-gestures/components/gesture-element"], function (_exports, _gestureElement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**!
   *
   * Provides the ability to easily build a
   * gesture-ful async-button implementation
   *
   */
  var _default = _gestureElement.default.extend({
    classNameBindings: ['actionState'],
    actionState: 'default',
    isPending: Ember.computed('actionState', function () {
      return this.get('actionState') === 'pending';
    }),
    _getParams: function (actionName) {
      let actionArguments = this._super(actionName);

      let callbackHandler = promise => {
        this.set('promise', promise);
        this.set('actionState', 'pending');
      };

      actionArguments.splice(1, 0, callbackHandler);
      return actionArguments;
    },
    __observePromiseState: Ember.observer('promise', function promiseTheComponentState() {
      Ember.get(this, 'promise').then(() => {
        if (!this.isDestroyed) {
          this.set('actionState', 'fulfilled');
        }
      }).catch(() => {
        if (!this.isDestroyed) {
          this.set('actionState', 'rejected');
        }
      });
    })
  });

  _exports.default = _default;
});