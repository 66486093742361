define("event-components/components/splashscreen/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  let Splashscreen = (_dec = (0, _component.classNames)("splashscreen"), _dec2 = Ember.inject.service, _dec3 = (0, _component.className)("isActive", "isNotActive"), _dec4 = (0, _component.className)("isDisabled"), _dec5 = (0, _component.className)("dontshow", ""), _dec(_class = (_class2 = class Splashscreen extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "isActive", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "isDisabled", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "dontshow", _descriptor4, this);
      let splashScreenWasShown = window.localStorage.getItem("splashscreen");

      if (splashScreenWasShown !== null) {
        this.set("isActive", false);
        this.set("dontshow", true);
      }

      if (this.isActive) {
        Ember.run.later(() => {
          this.set("isActive", false);
          window.localStorage.setItem("splashscreen", true);
        }, parseInt(this.get("amplify.currentTheme.data.splashscreenTime")));
      }

      if (this.get("amplify.currentTheme.data.splashscreen")) {} else {
        this.set("isDisabled", true);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isActive", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "dontshow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class);
  _exports.default = Splashscreen;
});