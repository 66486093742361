define("event-components/components/app-avatar-photo-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYjCcEn1",
    "block": "{\"symbols\":[\"imagepath\",\"&default\"],\"statements\":[[6,[37,5],[[35,0,[\"currentUser\",\"avatar\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"aws-image\",[],[[\"@file\",\"@tagless\"],[[34,0,[\"currentUser\",\"avatar\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"app-avatar-photo\",[],[[\"@file\",\"@onDataChange\"],[[32,1],[34,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-primary btn-lg\"],[15,\"onclick\",[30,[36,3],[[35,2]],null]],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"loeschen\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"]],\"parameters\":[]}]]],[18,2,null]],\"hasEval\":false,\"upvars\":[\"amplify\",\"createAvatarFromBlob\",\"removeImage\",\"perform\",\"t\",\"if\"]}",
    "moduleName": "event-components/components/app-avatar-photo-detail/template.hbs"
  });

  _exports.default = _default;
});