define("event-components/components/twitter-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TySS0yop",
    "block": "{\"symbols\":[\"singleFilter\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"text1\"],[12],[2,\"\\n  \"],[1,[30,[36,3],[\"filtern\"],null]],[2,\":\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"text2\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[\"wall.alle\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"filter\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"img\"],[16,\"src\",[31,[\"/icons/\",[32,1,[\"icon\"]],\".svg\"]]],[16,0,[30,[36,1],[[30,[36,0],[[32,1],[32,0,[\"activeFilters\"]]],null],\"active\",\"inactive\"],null]],[4,[38,2],[[32,0],\"onFilter\",[32,1]],null],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"contains\",\"if\",\"action\",\"t\",\"-track-array\",\"each\"]}",
    "moduleName": "event-components/components/twitter-filter/template.hbs"
  });

  _exports.default = _default;
});