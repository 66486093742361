define("event-components/components/twitter-post/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "graphql-tag", "event-components/gql/fragments", "@ember-decorators/component", "event-components/components/twitter-post/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, _graphqlTag, _fragments, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;

  const mutation = (0, _graphqlTag.default)`
  mutation updatePost(
    $where: PostWhereUniqueInput!
    $data: PostUpdateInput!
    ) {
      updatePost(
        where: $where
        data: $data
      ){
        ${_fragments.POST_FRAGMENT}
      }
  }
`;
  const mutation2 = (0, _graphqlTag.default)`
  mutation deletePostItem($where: PostWhereUniqueInput!) {
    deletePost(where: $where) {
      id
    }
  }
`;
  let TwitterPost = (_dec = (0, _component.classNames)("twitter-post"), _dec2 = Ember.inject.service("graphql"), _dec3 = Ember.inject.service("amplify"), _dec4 = Ember._action, _dec5 = (0, _emberConcurrencyDecorators.task)(), _dec(_class = (_class2 = class TwitterPost extends Ember.Component.extend({}) {
    didInsertElement() {
      this.element.addEventListener("touchstart", Ember.run.bind(this, this.startTouch), false);
      this.element.addEventListener("touchmove", Ember.run.bind(this, this.moveTouch), false);
    }

    startTouch(e) {
      this.initialX = e.touches[0].clientX;
      this.initialY = e.touches[0].clientY;
    }

    moveTouch(e) {
      if (this.initialX === null) {
        return;
      }

      if (this.initialY === null) {
        return;
      }

      let currentX = e.touches[0].clientX;
      let currentY = e.touches[0].clientY;
      let diffX = this.initialX - currentX;
      let diffY = this.initialY - currentY;

      if (Math.abs(diffX) > Math.abs(diffY)) {
        // sliding horizontally
        if (diffX > 0) {
          // swiped left
          this.send("swipedLeft", e);
        } else {
          this.send("swipedRight", e);
        }
      } else {
        // sliding vertically
        if (diffY > 0) {// swiped up
        } else {// swiped down
        }
      }

      this.initialX = null;
      this.initialY = null;
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "initialX", null);
      (0, _defineProperty2.default)(this, "initialY", null);
      (0, _defineProperty2.default)(this, "modalImage", null);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    async like(post) {
      let email = this.get("amplify.currentUser.email");

      if (post.likes.includes(email)) {
        post.likes.removeObject(email);
      } else {
        post.likes.addObject(email);
      }

      const variables = {
        where: {
          id: post.id
        },
        data: {
          likes: {
            set: Ember.get(post, "likes")
          }
        }
      };
      const posts = await this.apollo.mutate({
        mutation,
        variables
      }, "post");
      return true;
    }

    *removePost(post) {
      const variables = {
        where: {
          id: post.id
        }
      };
      let resp = yield this.apollo.mutate({
        mutation: mutation2,
        variables,
        refetchQueries: ["posts"]
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apolloService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "like", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "like"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "removePost", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "removePost"), _class2.prototype)), _class2)) || _class);
  _exports.default = TwitterPost;
});