define("event-components/components/x-button/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "event-components/components/x-button/template"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2;

  let XButton = (_dec = (0, _component.classNames)("btn", "btn-info"), _dec2 = (0, _component.tagName)("button"), _dec3 = Ember._action, _dec4 = Ember.computed("taskInstance.isRunning", "isDisabled"), _dec5 = (0, _component.attribute)("disabled"), _dec(_class = _dec2(_class = (_class2 = class XButton extends Ember.Component.extend({}) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "task", void 0);
      (0, _defineProperty2.default)(this, "taskInstance", void 0);
      (0, _defineProperty2.default)(this, "isDisabled", false);
      (0, _defineProperty2.default)(this, "showCheck", true);
    }

    click() {
      Ember.set(this, "taskInstance", this.task.perform(this.args));
    }

    get disabled() {
      if (this.isDisabled) {
        return true;
      }

      if (this.taskInstance == undefined) {
        return false;
      }

      return this.taskInstance.isRunning;
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "click", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "click"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "disabled", [_dec4, _dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "disabled"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = XButton;
});