define("event-components/components/hls-stream/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oSsYPr3S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\\n\"],[10,\"video-js\"],[14,1,\"vid1\"],[14,\"width\",\"1200\"],[14,\"height\",\"600\"],[14,0,\"vjs-default-skin\"],[14,\"controls\",\"\"],[12],[2,\"\\n  \"],[10,\"source\"],[15,\"src\",[34,0]],[15,4,[34,1]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"src\",\"mediatype\"]}",
    "moduleName": "event-components/components/hls-stream/template.hbs"
  });

  _exports.default = _default;
});