define("ember-x-tabs/templates/components/x-tabs-panes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ncfW3YZz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"api\",\"pane\"],[[35,0],[30,[36,1],[\"x-tabs-pane\"],[[\"api\"],[[35,0]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"api\",\"component\",\"hash\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tabs-panes.hbs"
  });

  _exports.default = _default;
});