define("ember-cli-meta-tags/services/head-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //TODO: consider polyfilled Set
  const VALID_HEAD_TAGS = Ember.A(['base', 'link', 'meta', 'script', 'noscript', 'title']);

  var _default = Ember.Service.extend({
    headData: Ember.inject.service(),

    // crawl up the active route stack and collect head tags
    collectHeadTags() {
      let tags = {};
      let currentHandlerInfos;

      if (true) {
        currentHandlerInfos = this.get('router.targetState.routerJsState.routeInfos');
      } else {
        currentHandlerInfos = this.get('router._routerMicrolib.currentHandlerInfos');

        if (!currentHandlerInfos) {
          currentHandlerInfos = this.get('router.router.currentHandlerInfos');
        }
      }

      let handlerInfos = Ember.A(currentHandlerInfos);
      handlerInfos.forEach(handlerInfo => {
        if (true) {
          Ember.assign(tags, this._extractHeadTagsFromRoute(handlerInfo.route));
        } else {
          Ember.assign(tags, this._extractHeadTagsFromRoute(handlerInfo.handler));
        }
      });
      let tagArray = Ember.A(Object.keys(tags)).map(id => tags[id]);
      this.set('headData.headTags', Ember.A(tagArray));
    },

    _extractHeadTagsFromRoute(route) {
      let headTags = Ember.get(route, 'headTags');

      if (!headTags) {
        return {};
      }

      if (typeof headTags === 'function') {
        headTags = headTags.apply(route);
      } else if (typeof headTags !== 'object') {
        // not recognized construct
        return {};
      } // convert headTags to object


      return this._buildTags(headTags);
    },

    // ensure all tags have a tagId and build object keyed by id
    _buildTags(headTagsArray) {
      let tagMap = {};
      Ember.A(headTagsArray).forEach(function (tagDefinition) {
        if (!VALID_HEAD_TAGS.includes(tagDefinition.type)) {
          return;
        }

        let tagId = tagDefinition.tagId;

        if (!tagId) {
          tagId = Ember.guidFor(tagDefinition);
        }

        tagMap[tagId] = tagDefinition;
      });
      return tagMap;
    }

  });

  _exports.default = _default;
});