define("event-components/components/module-nav/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/module-nav/template", "@ember-decorators/component"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor, _descriptor2;

  let ModuleNav = (_dec = (0, _component.tagName)("nav"), _dec2 = Ember.inject.service("chat"), _dec3 = Ember.inject.service, _dec4 = Ember.computed("amplify.currentTheme.data.moduleConfig"), _dec5 = Ember._action, _dec6 = Ember._action, _dec(_class = (_class2 = class ModuleNav extends Ember.Component.extend() {
    get inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "chatService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "stateName", "module-nav");
      (0, _defineProperty2.default)(this, "stateProps", ["activeModule", ""]);
      (0, _defineProperty2.default)(this, "preserveState", true);
      (0, _defineProperty2.default)(this, "activeModule", void 0);
      (0, _defineProperty2.default)(this, "privateChat", {
        key: "app-chat-private",
        header: "Chat"
      });

      try {
        this.amplify.on("nav:goto", this, "goTo");
      } catch (e) {
        console.log(e);
      }
    }

    willDestroy() {
      this.amplify.off("nav:goto", this, "goTo");
    }

    get activeModules() {
      if (!this.get("amplify.currentTheme.data.moduleConfig")) {
        return [];
      }

      let mods = this.get("amplify.currentTheme.data.moduleConfig");
      let filter = this.inIframe ? "showInMeeting" : "showInStream";
      mods = mods.filterBy(filter, true);
      console.log('mods', mods);
      mods.sort((a, b) => parseInt(a.sorting) - parseInt(b.sorting));
      return mods.map(mod => {
        return {
          key: mod.componentName,
          header: mod.name,
          icon: mod.icon,
          iconname: mod.iconname,
          tooltip: mod.tooltip
        };
      });
    }

    goTo(navItem) {
      console.log(navItem);
      this.set("activeModule", navItem);
      this.amplify.set("currentModule", navItem.header);
      this.navHandler(navItem);
    }

    goBack() {
      if (this.amplify.currentModuleItem3rd) {
        this.set("amplify.currentModuleItem3rd", false);
        return true;
      }

      this.amplify.trigger("nav:goback", this.amplify.get("currentModuleItem"));
      this.amplify.set("currentModuleItem", "");
    }

    didReceiveAttrs() {
      Ember.run.next(this, () => {
        this.goTo(this.activeModules.firstObject);
      });
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "chatService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "activeModules", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "activeModules"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "goTo", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "goTo"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "goBack", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "goBack"), _class2.prototype)), _class2)) || _class);
  _exports.default = ModuleNav;
});