define("event-components/repository/apollo/service", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "apollo-utilities", "apollo-link", "apollo-link-ws", "subscriptions-transport-ws"], function (_exports, _applyDecoratedDescriptor2, _apolloUtilities, _apolloLink, _apolloLinkWs, _subscriptionsTransportWs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  const GRAPHQL_ENDPOINT = "ws://api.hypetrain.cloud/umotions-events/dev";
  const client = new _subscriptionsTransportWs.SubscriptionClient(GRAPHQL_ENDPOINT, {
    reconnect: true
  });
  const wsLink = new _apolloLinkWs.WebSocketLink(client);
  let RepositoryApollo = (_dec = Ember.computed(), (_class = class RepositoryApollo extends Service.extend({// anything which *must* be merged to prototype here
  }) {
    clientOptions() {
      console.log("CACHE");
      console.log(this.get("cache"));
      return {
        link: this.get("newlink"),
        cache: this.get("cache")
      };
    }

    get newlink() {
      return (0, _apolloLink.split)(({
        query
      }) => {
        const {
          kind,
          operation
        } = (0, _apolloUtilities.getMainDefinition)(query);
        return kind === "OperationDefinition" && operation === "subscription";
      }, wsLink, this.get("link"));
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "newlink", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "newlink"), _class.prototype)), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = RepositoryApollo;
});