define("event-components/helpers/link-blank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linkBlank = linkBlank;
  _exports.default = void 0;

  function linkBlank(params
  /*, hash*/
  ) {
    return params[0].replace("href", 'target="_blank" href');
  }

  var _default = Ember.Helper.helper(linkBlank);

  _exports.default = _default;
});