define("event-components/components/app-agenda/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-agenda/template", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  const query = (0, _graphqlTag.default)`
  query agenda($where: AgendaItemWhereInput!) {
    agendaItems(where: $where) {
      ${_fragments.AGENDA_FRAGMENT}
    }
  }
`;
  const queryA = (0, _graphqlTag.default)`
  query appointments($where: AppointmentWhereInput) {
    appointments(where: $where) {
      ${_fragments.APPOINTMENT_FRAGMENT}
    }
  }
`;
  const queryP = (0, _graphqlTag.default)`
  query program($where: ProgramItemWhereInput!) {
    programItems(orderBy: sorting_ASC, where: $where) {
      ${_fragments.PROGRAM_FRAGMENT}
    }
  }
`;
  let AppAgenda = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("amplify"), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class AppAgenda extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "isLoading", false);
      (0, _defineProperty2.default)(this, "errorMsg", null);
      (0, _defineProperty2.default)(this, "model", void 0);
      (0, _defineProperty2.default)(this, "date", "");
      (0, _defineProperty2.default)(this, "startAt", "");
      (0, _defineProperty2.default)(this, "endAt", "");
      (0, _defineProperty2.default)(this, "location", "");
      (0, _defineProperty2.default)(this, "description", "");
      (0, _defineProperty2.default)(this, "badgeCounter", 0);
      (0, _defineProperty2.default)(this, "acceptedAppointments", []);
      (0, _defineProperty2.default)(this, "tabsActive", false);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.loadModel();
      this.amplify.on('ws:updateAppointment', this, 'onUpdateAppointment');
      this.amplify.on('ws:createAppointment', this, 'onCreateAppointment');
      this.amplify.on('ws:deleteAppointment', this, 'onDeleteAppointment');
    }

    willDestroy() {
      this.amplify.off('ws:updateAppointment', this, 'onUpdateAppointment');
      this.amplify.off('ws:createAppointment', this, 'onCreateAppointment');
      this.amplify.off('ws:deleteAppointment', this, 'onDeleteAppointment');
    }

    async onUpdateAppointment(appointmentNode) {
      let model = await this.get('model');
      model.owningAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
      model.pendingAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
    }

    async onCreateAppointment(appointmentNode) {
      this.set("user", this.amplify.currentUser);
      this.set("client", this.amplify.currentClient);

      if (appointmentNode.node.client.id == this.client.id) {
        if (appointmentNode.node.user.id == this.user.id || appointmentNode.node.requestUser.id == this.user.id) {
          this.loadModel();
        }
      }
    }

    async onDeleteAppointment(appointmentNode) {
      let model = await this.get('model');
      model.owningAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
      model.pendingAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
    }

    sortAppointment(a) {
      a.sort((a, b) => {
        let asort = parseInt(a.date.replace(/-/g, '') + a.startAt.replace(/:/g, ''));
        let bsort = parseInt(b.date.replace(/-/g, '') + b.startAt.replace(/:/g, ''));
        console.log(asort, bsort);
        return asort - bsort;
      });
      return a;
    }

    sortAgendaItem(a) {
      a.sort((a, b) => {
        let asort = null;
        let bsort = null;

        if (a.isAppointment) {
          asort = parseInt(a.sortingDate.replace(/-|T|:/g, ''));
        } else {
          asort = parseInt(a.programItem.date.replace(/-|T|:/g, ''));
        }

        if (b.isAppointment) {
          bsort = parseInt(b.sortingDate.replace(/-|T|:/g, ''));
        } else {
          bsort = parseInt(b.programItem.date.replace(/-|T|:/g, ''));
        }

        return asort - bsort;
      });
      return a;
    }

    async loadModel() {
      this.set('badgeCounter', 0);
      let client = await this.amplify.get('currentClient');
      const variables = {
        where: {
          user: {
            id: this.amplify.currentUser.id
          }
        }
      };
      const variablesOA = {
        where: {
          user: {
            id: this.amplify.currentUser.id
          }
        }
      };
      const variablesPA = {
        where: {
          requestUser: {
            id: this.amplify.currentUser.id
          }
        }
      };
      const variablesP = {
        where: {
          client: {
            id: client.id
          }
        },
        orderBy: 'sorting_ASC'
      };
      let agendaItems = await this.apollo.query({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "agendaItems");
      let owningAppointments = await this.apollo.query({
        query: queryA,
        variables: variablesOA,
        fetchPolicy: "network-only"
      }, "appointments");
      let programItems = await this.apollo.query({
        query: queryP,
        variables: variablesP,
        fetchPolicy: "network-only"
      }, 'programItems');
      owningAppointments.forEach(a => {
        if (a.requestUser != null) {
          this.set("badgeCounter", this.badgeCounter + 1);

          if (a.approved === true) {
            this.acceptedAppointments.push(a);
          }
        }
      });
      let pendingAppointments = await this.apollo.query({
        query: queryA,
        variables: variablesPA,
        fetchPolicy: "network-only"
      }, "appointments");
      pendingAppointments.forEach(a => {
        if (a.approved === true) {
          this.set("badgeCounter", this.badgeCounter + 1);
          this.acceptedAppointments.push(a);
        }
      });
      owningAppointments = this.sortAppointment(owningAppointments);
      pendingAppointments = this.sortAppointment(pendingAppointments); // create tabs with dates of program items

      let tabs = [];
      programItems = programItems.map(p => {
        p.sorting = parseInt(p.sorting);
        return p;
      });
      programItems.sort((a, b) => {
        return a.sorting - b.sorting;
      });
      programItems.forEach(element => {
        tabs.pushObject(moment(element.date, moment.ISO_8601).format('dd DD.MM.YYYY'));
      }); // create another tab if no agenda items

      tabs = tabs.uniq();

      if (tabs[0] == null) {
        tabs.push('Bestätigte Termine');
      }

      this.acceptedAppointments.forEach(aa => {
        // check if item already in agenda items
        let exitLoop = false;
        agendaItems.forEach(function (agendaItem) {
          if (agendaItem.id === aa.id) {
            exitLoop = true;
          }
        });

        if (exitLoop) {
          return;
        }

        aa = { ...aa,
          isAppointment: true,
          sortingDate: aa.date + 'T' + aa.startAt + ':00.000Z'
        };
        agendaItems.push(aa);
      });
      agendaItems = this.sortAgendaItem(agendaItems);
      const model = Ember.RSVP.hash({
        agendaItems: agendaItems,
        owningAppointments: owningAppointments,
        pendingAppointments: pendingAppointments,
        acceptedAppointments: this.acceptedAppointments,
        tabs: tabs
      });
      this.set("model", model);
    }

    openAgenda() {
      this.set("tabsActive", !this.tabsActive);
      this.amplify.setAppointmentNotification(null, false);
    }

    async createAppointment() {
      this.set("user", this.amplify.currentUser);
      this.set("client", this.amplify.currentClient);
      const variables = {
        data: {
          client: {
            connect: {
              id: this.client.id
            }
          },
          user: {
            connect: {
              id: this.user.id
            }
          },
          date: this.date,
          startAt: this.startAt,
          endAt: this.endAt,
          location: this.location,
          description: this.description,
          type: this.intl.t('appointment.feier_termin'),
          approved: false
        }
      };

      if (parseInt(this.startAt.replace(':', '')) >= parseInt(this.endAt.replace(':', ''))) {
        this.set('errorMsg', this.intl.t('appointment.zeit_validierung'));
      } else {
        this.set('isLoading', true);
        const mutation = (0, _graphqlTag.default)`
        mutation($data: AppointmentCreateInput!) {
          createAppointment(data: $data) {
            ${_fragments.APPOINTMENT_FRAGMENT}
          }
        }
      `;

        try {
          await this.apollo.mutate({
            mutation,
            variables
          }, "appointment");
        } catch (e) {
          console.error(e);
        } finally {
          this.set('errorMsg', null);
          this.set('isLoading', false);
          this.set('appointmentModal', false);
          this.loadModel();
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "openAgenda", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "openAgenda"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createAppointment", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "createAppointment"), _class.prototype)), _class));
  _exports.default = AppAgenda;
});