define("event-components/components/app-programm-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-concurrency-decorators", "graphql-tag", "event-components/components/app-programm-item/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _graphqlTag, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const mutation = (0, _graphqlTag.default)`
  mutation createAgendaItem($data: AgendaItemCreateInput!) {
    createAgendaItem(data: $data) {
      id
      programItem {
        id
      }
      user {
        id
      }
    }
  }
`;
  const mutation2 = (0, _graphqlTag.default)`
  mutation deleteAgendaItem($where: AgendaItemWhereUniqueInput!) {
    deleteAgendaItem(where: $where) {
      id
    }
  }
`;
  let AppProgrammItem = (_dec = (0, _component.classNames)('app-programm-item'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _component.className)('active', 'inactive'), _dec5 = Ember.computed('model.fullpage'), _dec6 = Ember.computed('model.floatright'), _dec7 = Ember.inject.service('graphql'), _dec8 = Ember.computed('agendaItems.[]'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec(_class = (_class2 = class AppProgrammItem extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    get fullpage() {
      return this.get('model.fullpage') ? 'fullpage' : 'no-fullpage';
    }

    get floatright() {
      return this.get('model.floatright') ? 'floatright' : 'no-floatright';
    }

    get agendaItem() {
      return (async () => {
        let agendaItems = await this.agendaItems;
        let items = agendaItems.filterBy('programItem.id', this.model.id);
        items = items.filterBy('user.id', this.amplify.currentUser.id);

        if (items.length > 0) {
          return items.firstObject;
        } else {
          return null;
        }
      })();
    }

    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "isMobile", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _defineProperty2.default)(this, "isDragging", false);
      (0, _defineProperty2.default)(this, "isAgendaItem", false);
      (0, _defineProperty2.default)(this, "touchStartPoint", null);
      (0, _defineProperty2.default)(this, "offset", 80);
      (0, _initializerDefineProperty2.default)(this, "isActive", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor4, this);
      this.set('apollo', this.get('apolloService').createQueryManager());
    }

    mouseDown(event) {
      this.handleStart(event);
    }

    touchStart(event) {
      this.handleStart(event);
    }

    handleStart(event) {
      if (!this.get('amplify.currentTheme.data.modules.agenda')) {
        return;
      }

      this.set('isDragging', true);

      if (!Ember.isEmpty(event.touches)) {
        this.set('touchStartPoint', event.touches[0].clientX);
      }
    }

    didInsertElement() {
      this.element.addEventListener('mousemove', this.handleMouseMove.bind(this));
      this.element.addEventListener('touchmove', this.handleTouchMove.bind(this));
    }

    handleMouseMove(event) {
      this.handleMove.perform(event);
    }

    handleTouchMove(event) {
      event.stopPropagation();
      this.handleMove.perform(event);
    }

    *handleMove(event) {
      if (!this.get('amplify.currentTheme.data.modules.agenda')) {
        return;
      }

      if (Ember.isEmpty(event.touches)) {
        return;
      }

      let computedValue = (this.touchStartPoint - event.touches[0].clientX) * -1;

      if (Ember.isEmpty(this.element.getElementsByClassName('inner')[0])) {
        return;
      }

      if (computedValue < -this.offset) {
        this.set('isActive', true);
      }

      if (computedValue > this.offset) {
        this.set('isActive', false);
      }

      yield true; //this.element.getElementsByClassName('inner')[0].style.transform = `translateX(${computedValue}px)`;
    }

    mouseUp(event) {
      this.handleStop(event);
    }

    touchEnd(event) {
      this.handleStop(event);
    }

    async addItemToAgenda(item) {
      const variables = {
        data: {
          programItem: {
            connect: {
              id: item.id
            }
          },
          user: {
            connect: {
              id: this.user.id
            }
          }
        }
      };
      let resp = this.apollo.mutate({
        mutation,
        variables,
        refetchQueries: ['agenda']
      });
      this.agendaItems.pushObject((await resp).createAgendaItem);
      this.set('isActive', false);
      this.set('isAgendaItem', true);
    }

    async removeItemToAgenda(item, agendaItem) {
      const variables = {
        where: {
          id: agendaItem.id
        }
      };
      let resp = this.apollo.mutate({
        mutation: mutation2,
        variables,
        refetchQueries: ['agenda']
      });

      if (await resp) {//location.reload();
      }

      this.agendaItems.removeObject(agendaItem);
      this.set('isActive', false);
      this.set('isAgendaItem', false);
    }

    handleStop(event) {
      this.set('isDragging', false);
      this.set('touchStartPoint', null);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isMobile", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "isActive", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fullpage", [_component.className, _dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "fullpage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "floatright", [_component.className, _dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "floatright"), _class2.prototype), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apolloService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "agendaItem", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "agendaItem"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "mouseDown", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "mouseDown"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "touchStart", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "touchStart"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "handleMove", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMove"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "mouseUp", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "mouseUp"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "touchEnd", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "touchEnd"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "addItemToAgenda", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "addItemToAgenda"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "removeItemToAgenda", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "removeItemToAgenda"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppProgrammItem;
});