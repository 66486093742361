define("event-components/components/svg-mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r3AWirCx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"],[10,\"style\"],[12],[2,\"\\n  #svg-\"],[1,[34,0]],[2,\"\\n  {\\n        -webkit-mask-image: url(\\n  \"],[1,[34,1]],[2,\"\\n  );\\n        mask-image: url(\\n  \"],[1,[34,1]],[2,\"\\n  );\\n        mask-size: contain;\\n        -webkit-mask-size: contain;\\n        -webkit-mask-position: center;\\n        mask-position: center;\\n        -webkit-mask-repeat: no-repeat;\\n        mask-repeat: no-repeat;\\n        width:\"],[1,[34,2]],[2,\"px;\\n        height:\"],[1,[34,3]],[2,\"px;\\n        background: white;\\n    }\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,1,[31,[\"svg-\",[34,0]]]],[14,0,\"svg-mask-icon\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"uniqueId\",\"svg\",\"width\",\"height\"]}",
    "moduleName": "event-components/components/svg-mask/template.hbs"
  });

  _exports.default = _default;
});