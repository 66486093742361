define("ember-x-tabs/templates/components/x-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5lBYzyyR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"  \"],[18,1,[[30,[36,3],null,[[\"pane\",\"activeId\",\"selectAction\"],[[30,[36,2],[\"x-tab/pane\"],[[\"parent\",\"activeId\"],[[32,0],[35,1]]]],[35,1],[30,[36,0],[[32,0],\"select\"],null]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"isActiveId\",\"component\",\"hash\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tab.hbs"
  });

  _exports.default = _default;
});