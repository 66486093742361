define("event-components/components/x-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rJLLSrAr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0,[\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-circle-o-notch fa-spin\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null],[2,\"\\n\"],[6,[37,1],[[30,[36,4],[[35,3,[\"last\",\"isSuccessful\"]],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-check\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[1,[30,[36,5],[\"test\",[35,3]],null]],[2,\"\\n\"],[6,[37,1],[[35,3,[\"last\",\"isError\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-remove\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"taskInstance\",\"if\",\"showCheck\",\"task\",\"and\",\"log\"]}",
    "moduleName": "event-components/components/x-button/template.hbs"
  });

  _exports.default = _default;
});