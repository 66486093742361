define("event-components/gql/fragments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.APPOINTMENT_FRAGMENT = _exports.WALL_FRAGMENT = _exports.POST_FRAGMENT = _exports.USER_FRAGMENT = _exports.THEME_FRAGMENT = _exports.MAP_FRAGMENT = _exports.AGENDA_FRAGMENT = _exports.FILE_FRAGMENT = _exports.PROGRAM_FRAGMENT = _exports.CLIENT_FRAGMENT = _exports.QUESTION_FRAGMENT = _exports.ANSWER_FRAGMENT = _exports.BEACON_FRAGMENT = _exports.VOTE_FRAGMENT = _exports.CHATLINE_FRAGMENT = _exports.CHATROOM_FRAGMENT = _exports.INFO_FRAGMENT = void 0;
  const CLIENT_FRAGMENT = `
    id
    name
    domain
    kuerzel
    locale
    appIcon
    loginmode
    createdAt
    updatedAt
`;
  _exports.CLIENT_FRAGMENT = CLIENT_FRAGMENT;
  const FILE_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    key
    level
    createdAt
    updatedAt
`;
  _exports.FILE_FRAGMENT = FILE_FRAGMENT;
  const USER_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    industry
    description 
    avatar {
      ${FILE_FRAGMENT}
    }
    avatarthumb {
      ${FILE_FRAGMENT}
    }
    meetingrole
    meetinggroup
    publicprofile
    notifications
    calendar
    doubleoptin
    token
    favs
    lastname
    lastseen
    invitecode
    company
    title
    username
    filterfield
    finished
    phone
    salutation
    salutationpraefix
    hasUnreadAppointments
    firstname
    email
    address
    city
    zip
    country
    group
    groups
    var1
    var2
    var3
    var4
    var5
`;
  _exports.USER_FRAGMENT = USER_FRAGMENT;
  const THEME_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    data
`;
  _exports.THEME_FRAGMENT = THEME_FRAGMENT;
  const BEACON_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    xPos
    yPos
    title
    color
    description
    icon
`;
  _exports.BEACON_FRAGMENT = BEACON_FRAGMENT;
  const MAP_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    image {
      ${FILE_FRAGMENT}
    }
    beacons {
      ${BEACON_FRAGMENT}
    }
    scrollpositionX
    scrollpositionY
    zooms
    createdAt
    updatedAt
`;
  _exports.MAP_FRAGMENT = MAP_FRAGMENT;
  const PROGRAM_FRAGMENT = `
    id
    title
    text
    pagebreak
    floatright
    sorting
    whitelist
    fullpage
    beacon {
      ${BEACON_FRAGMENT}
    }
    date
    image {
      ${FILE_FRAGMENT}
    }
    client {
      ${CLIENT_FRAGMENT}
    }
`;
  _exports.PROGRAM_FRAGMENT = PROGRAM_FRAGMENT;
  const AGENDA_FRAGMENT = `
    id
    user {
      ${USER_FRAGMENT}
    }
    programItem {
      ${PROGRAM_FRAGMENT}
    }
    date
    title
    
`;
  _exports.AGENDA_FRAGMENT = AGENDA_FRAGMENT;
  const POST_FRAGMENT = `
    id
    text
    moderated
    show
    wall {
      id
    }
    author {
      ${USER_FRAGMENT}
    }
    locations {
      ${BEACON_FRAGMENT}
    }
    createdAt
    updatedAt
    images {
      ${FILE_FRAGMENT}
    }
    likes
`;
  _exports.POST_FRAGMENT = POST_FRAGMENT;
  const APPOINTMENT_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    user {
      ${USER_FRAGMENT}
    }
    date
    startAt
    endAt
    location
    description
    locationSuggestion
    requestion
    requestUser {
      ${USER_FRAGMENT}
    }
    type
    approved
    createdAt
    updatedAt
`;
  _exports.APPOINTMENT_FRAGMENT = APPOINTMENT_FRAGMENT;
  const QUESTION_FRAGMENT = `
    id
    type
    values
    group
    groups
    isRequired
    sorting
    allowcustominput
    isMultiselect
    default
    profil
    hide
    filter
    userfield
    question
    questionWhitelist
    participation
    companion
    pagebreak
    agendadate
    agendalimit
    multiselectlimit
    until
    client {
      id
    }
`;
  _exports.QUESTION_FRAGMENT = QUESTION_FRAGMENT;
  const WALL_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    name
    image {
      ${FILE_FRAGMENT}
    }
    sorting
`;
  _exports.WALL_FRAGMENT = WALL_FRAGMENT;
  const ANSWER_FRAGMENT = `
    id
    client {
      ${CLIENT_FRAGMENT}
    }
    user {
      ${USER_FRAGMENT}
    }
    notification {
      id
      executionArn
      date
      state
    }
    agendaitem {
      ${AGENDA_FRAGMENT}
    }
    value
    question {
      ${QUESTION_FRAGMENT}
    }
`;
  _exports.ANSWER_FRAGMENT = ANSWER_FRAGMENT;
  const VOTE_FRAGMENT = `
    id
    values
    moderatedvalues
    image {
      ${FILE_FRAGMENT}
    }
    background {
      ${FILE_FRAGMENT}
    }
    chartpositiontop
    chartpositionleft
    chartheight
    chartwidth
    question
    show
    moderated
    umfrage
    defaulttext
    changetrack
    showresult
    colors
    limit
    upvotemode
    votes
    fontsize
    votable
    answers
    charttype
    active
    client {
      ${CLIENT_FRAGMENT}
    }
`;
  _exports.VOTE_FRAGMENT = VOTE_FRAGMENT;
  const CHATROOM_FRAGMENT = `
  id
  name
  text
  autoEnter
  privateuser
  privateusersmap
  groupname
  image {
    ${FILE_FRAGMENT}
  }
  client {
    ${CLIENT_FRAGMENT}
  }
`;
  _exports.CHATROOM_FRAGMENT = CHATROOM_FRAGMENT;
  const CHATLINE_FRAGMENT = `
  id
  message
  target
  system
  videochatMessage
  chatroom {
    ${CHATROOM_FRAGMENT}
  }
  user {
    ${USER_FRAGMENT}
  }
  createdAt
  updatedAt
`;
  _exports.CHATLINE_FRAGMENT = CHATLINE_FRAGMENT;
  const INFO_FRAGMENT = `
  id
  title
  description
  link
  type
  createdAt
  updatedAt
  file {
    ${FILE_FRAGMENT}
  }
  client {
    ${CLIENT_FRAGMENT}
  }
`;
  _exports.INFO_FRAGMENT = INFO_FRAGMENT;
});