define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de-de", {
    "abmelden": "Abmelden",
    "absenden": "Absenden",
    "agenda": {
      "beschreibung": "Beschreibung",
      "bis": "Bis",
      "laufende_terminanfragen": "Laufende Terminanfragen",
      "meine_agenda": "Meine Agenda",
      "meine_termine": "Meine Termine",
      "no_appointments_agenda_message": "Sie haben noch keine Programmpunkte auf der persönlichen Agenda.",
      "no_appointments_message": "Sie haben noch keine Termine angeboten.",
      "ort": "Ort",
      "termin": "Termin",
      "termin_anbieten": "Termin anbieten",
      "termine_anbieten": "Termine anbieten",
      "termine_verwalten": "Termine verwalten",
      "uebernehmen": "Übernehmen",
      "von": "Von",
      "zueruck_zur_agenda": "Zurück zur Agenda"
    },
    "anmeldung": "Anmeldung",
    "appointment": {
      "ablehnen": "Ablehnen",
      "anfrage": "Anfrage",
      "angeboten_von_ihnen": "Angeboten von Ihnen",
      "angefragt_von": "Angefragt von",
      "bestaetigen": "Bestätigen",
      "bietet_aktuell_keine_Termine_an": "bietet aktuell keine Termine an",
      "feier_termin": "Freier Termin",
      "frei": "Frei",
      "ihnen": "Ihnen",
      "individuelle_terminanfrage_stellen": "Individuelle Terminanfrage stellen",
      "termin_aktualisieren": "Termin aktualisieren",
      "termin_anfragen": "Termin anfragen",
      "termin_wird_angeboten_von": "Termin wird angeboten von",
      "termin_wird_angefragt_von": "Termin wird angefragt von",
      "termin_wurde_bestaetigt": "Termin wurde bestätigt",
      "termin_wurde_stoniert": "Termin wurde stoniert",
      "termin_wurde_storniert": "Termin wurde storniert",
      "terminanfrage": "Terminanfrage",
      "terminanfrage_läuft": "Terminanfrage läuft",
      "uhr": "Uhr",
      "uhr_bis": "Uhr bis",
      "zeit_validierung": "'Die Startzeit liegt später als die Endzeit.'"
    },
    "ausloggen": "Ausloggen",
    "chat": {
      "ete_message": "Dieser Chat ist nicht Ende-zu-Ende verschlüsselt!",
      "go": "Go",
      "nachricht": "Nachricht",
      "no_chatroom_message": "Sie sind in keinem Chatraum eingetragen",
      "no_private_message": "Sie haben noch keine privaten Nachrichten",
      "private_nachricht": "Private Nachricht",
      "private_nachrichten": "Private Nachrichten",
      "privater_chat": "Privater Chat",
      "um": "um",
      "von": "Von",
      "zurueck_nach_unten": "Zurück nach unten!"
    },
    "datenschutz": "Datenschutz und AGB",
    "datum": "Datum",
    "download": "Download",
    "email": {
      "password_forgot_subject": "Neues Passwort für {url}"
    },
    "filtern": "Filtern",
    "fragebogen": {
      "frist_abgelaufen_am": "Frist abgelaufen am",
      "required_fields": "Bitte füllen Sie die rot umrandeten Felder aus."
    },
    "gesamtprogramm": "Gesamtprogramm",
    "impressum": "Impressum",
    "kontakt": "Kontakt",
    "link": "Link",
    "link_oeffnen": "Link öffnen",
    "loeschen": "Löschen",
    "login": {
      "already_registered_message": "Schon registriert? Jetzt einloggen!",
      "bitte_loggen_sie_sich_ein": "Bitte loggen Sie sich ein",
      "einloggen": "Einloggen",
      "einloggen_lc": "einloggen",
      "email": "E-Mail",
      "hat_account_message": "Falls Sie schon einen Account haben, loggen Sie sich hier ein",
      "jetzt": "Jetzt",
      "jetzt_einloggen": "Jetzt einloggen",
      "kein_account_message": "Wenn Sie noch nicht angemeldet sind, registrieren Sie sich bitte hier",
      "login": "Login",
      "login_mail_message": "Sie müssen sich einloggen um an diesem Event teilzunehmen. Klicken Sie dazu auf den Link aus der Einladungs E-Mail.",
      "login_und_registrierung": "Login und Registrierung",
      "nachname": "Nachname",
      "neues_passwort_vergeben": "Neues Passwort vergeben",
      "new_password_message": "Bitte vergeben Sie Ihr neues Passwort",
      "not_registered_message": "Wenn Sie sich noch nicht registriert haben, können Sie dies unter folgendem",
      "passwort": "Passwort",
      "passwort_speichern": "Passwort speichern",
      "passwort_vergessen": "Passwort vergessen",
      "passwort_vergessen_email": "Wir haben Ihnen einen Link an Ihre hinterlegte E-Mail-Adresse gesendet.",
      "passwort_wdh": "Passwort Wiederholung",
      "register": "Die AGB habe ich gelesen und bestätige sie mit meiner Anmeldung. Die Datenschutzhinweise habe ich zur Kenntnis genommen. Den Link zu Datenschutzhinweis und Veranstaltungs-AGB finden Sie mittig am Ende der Seite (Desktop) bzw. im Menü oben rechts (Mobile).",
      "register_accept_privacy": "Bitte akzeptieren Sie die Datenschutzbestimmungen.",
      "register_conformation_message": "Sie haben sich erfolgreich registriert. Sie erhalten in Kürze eine Bestätigungs-E-Mail mit Ihrem Login-Link.",
      "registrierung": "Registrierung",
      "resend_code_message": "Einladungs-Code erneut zusenden",
      "reset_password_message": "Bitte geben Sie hier Ihre E-Mail-Adresse ein, um Ihr Passwort zurück zu setzen. Sie erhalten daraufhin eine E-Mail.",
      "tun": "tun.",
      "vorname": "Vorname",
      "zum_login": "Zum Login",
      "zur_registrierung": " Zur Registrierung"
    },
    "meeting": {
      "no_meeting_message": "Sie sind für keine Video-Meeting eingetragen",
      "video_meeting_beitreten": "Video Meeting beitreten"
    },
    "mehr": "Mehr",
    "nav": {
      "eingeloggt_als": "Eingeloggt als"
    },
    "oeffnen": "Öffnen",
    "profil": "Profil",
    "pwa": {
      "gehe_zu": "Gehe zu",
      "pwa_message_1": "Füge diese Web-App zu deinem",
      "pwa_message_2": "Homescreen",
      "pwa_message_3": "hinzu und nutze sie wie eine normale App auch ohne Internetverbindung.",
      "und": "und",
      "zum_home_bildschirm": "Zum Home-Bildschirm"
    },
    "schliessen": "Schließen",
    "senden": "Senden",
    "speichern": "Speichern",
    "streaming": {
      "3d_tooltip": "Hier gelangen Sie zu der 3D-Umgebung.",
      "fullscreen_tooltip": "Vollbildmodus",
      "stream_tooltip": "Wählen Sie aus einem Livestream aus."
    },
    "ts": {
      "end_before_start": "Die Startzeit liegt später als die Endzeit.",
      "enter_mail": "Bitte geben Sie Ihre E-Mail an",
      "fill_fields": "Bitte füllen Sie die rot umrandeten Felder aus.",
      "link_expired": "Ihr Aktivierungslink ist abgelaufen. Wir haben Ihnen eine E-Mail mit einem neuen Link zugeschickt, bitte nutzen Sie diesen, um Ihren Account zu aktivieren.",
      "login_error": "Login Fehler!",
      "pass_mail_incorrect": "Dieser Benutzer ist uns nicht bekannt, oder das Passwort ist falsch",
      "pass_not_identical": "Passwörter müssen identisch sein",
      "pass_not_identical_2": "Ihre Passwörter sind nicht identisch.",
      "pass_saved": "Ihr Passwort wurde gespeichert, bitte loggen Sie sich erneut ein.",
      "pass_too_short": "Ihr Passwort muss mindestens aus 6 Zeichen bestehen",
      "post_check_message": "Der Post wird nun geprüft und freigeschaltet!",
      "resend_invite": "Wir haben Ihnen einen Link an Ihre hinterlegte E-Mail-Adresse gesendet.",
      "reset_your_pw": "Bitte setzen Sie ihr Passwort zurück!",
      "too_many_retries": "Sie haben Ihr Passwort zu oft falsch eingegeben. Bitte warten Sie einige Minuten!",
      "user_activated_login": "Benutzer wurde aktiviert - Sie können sich nun einloggen",
      "user_exits": "Benutzer wurde bereits registriert!",
      "user_exits_login": "Der Benutzer wurde bereits registriert, bitte loggen Sie sich ein",
      "user_not_found": "Wir haben diesen Benutzer nicht gefunden!",
      "user_not_found2": "Der Benutzer wurde nicht gefunden. Für den Fall, dass Sie Ihren Account noch nicht aktiviert haben, haben wir Ihnen den Aktivierungslink noch einmal an Ihre angegebene E-Mail-Adresse geschickt.",
      "vollbild_beenden": "Vollbild beenden",
      "vote_message": "Hier können Sie abstimmen und somit die Reihenfolge der Fragen beeinflussen.",
      "wrong_password": "Falsches Passwort?"
    },
    "verfuegbar": "verfügbar",
    "video_oeffnen": "Video öffnen",
    "vote": {
      "neu_laden": "neu laden",
      "neuer_vorschlag": "Vielen Dank, Ihre Eingabe wurde erfolgreich übermittelt.",
      "sie_haben": "Sie haben",
      "stimme": "Stimme",
      "stimmen": "Stimmen",
      "vorschlagen": "vorschlagen",
      "vote_inactive_message": "Umfrage aktuell nicht aktiv.",
      "zu_der_auswertung": "Zu der Auswertung",
      "zurueck_zur_umfrage": "zurück zur Umfrage"
    },
    "wall": {
      "alle": "Alle",
      "filtern": "Filtern",
      "pinnwand": "Pinnwand",
      "post_loeschen": "Post löschen",
      "post_senden": "Post senden"
    },
    "weniger": "Weniger",
    "zum_dashboard": "Zum Dashboard",
    "zurueck": "Zurück"
  }], ["en-us", {
    "abmelden": "Logout",
    "absenden": "submit",
    "agenda": {
      "beschreibung": "description",
      "bis": "until",
      "laufende_terminanfragen": "ongoing appointment requests",
      "meine_agenda": "my agenda",
      "meine_termine": "my appointments",
      "no_appointments_agenda_message": "You don’t have any program items on your personal agenda.",
      "no_appointments_message": "You haven't offered any appointments yet.",
      "ort": "location",
      "termin": "appointment",
      "termin_anbieten": "offer an appointment",
      "termine_anbieten": "offer dates",
      "termine_verwalten": " Manage appointments.",
      "uebernehmen": "take over",
      "von": "from"
    },
    "anmeldung": "Registration",
    "appointment": {
      "ablehnen": "decline",
      "anfrage": "request",
      "angeboten_von_ihnen": "offered from you",
      "angefragt_von": "requested by",
      "bestaetigen": "confirm",
      "bietet_aktuell_keine_Termine_an": "currently offers no dates",
      "frei": "free",
      "ihnen": "you",
      "individuelle_terminanfrage_stellen": "Make an individual appointment request.",
      "termin_aktualisieren": "update appointment",
      "termin_anfragen": "request an appointment ",
      "termin_wird_angeboten_von": "appointment is offered by",
      "termin_wird_angefragt_von": "appointment is requested by",
      "termin_wurde_bestaetigt": "appointment was confirmed",
      "termin_wurde_stoniert": "appointment was canceled",
      "termin_wurde_storniert": "appointment was canceled",
      "terminanfrage": "appointment request",
      "terminanfrage_läuft": "appointment request running",
      "uhr": "h",
      "uhr_bis": "o'clock until"
    },
    "ausloggen": "log out",
    "chat": {
      "ete_message": "This chat is not end-to-end encrypted!",
      "go": "go",
      "nachricht": "message",
      "no_chatroom_message": "you are not registered in any chat room",
      "no_private_message": "you do not have any private messages",
      "private_nachricht": "private newsletter",
      "private_nachrichten": "private messages",
      "privater_chat": "private chat",
      "um": "at",
      "von": "from",
      "zurueck_nach_unten": "Back down!"
    },
    "datenschutz": "data protection",
    "datum": "date",
    "download": "download",
    "email": {
      "password_forgot_subject": "New password for {url}"
    },
    "filtern": "Filter",
    "fragebogen": {
      "frist_abgelaufen_am": "deadline expired on",
      "required_fields": "Please fill in the fields outlined in red."
    },
    "gesamtprogramm": "Agenda",
    "impressum": "imprint",
    "kontakt": "contact",
    "link": "link",
    "link_oeffnen": "open link",
    "loeschen": "delete",
    "login": {
      "already_registered_message": "Are you registerd? Login now!",
      "bitte_loggen_sie_sich_ein": "please login",
      "einloggen": "login",
      "einloggen_lc": "login",
      "email": "email",
      "hat_account_message": "If you already have an account, log in here",
      "jetzt": "now",
      "jetzt_einloggen": "login now",
      "kein_account_message": "if you are not registered, please register here",
      "login": "login",
      "login_mail_message": "You need to login to participate in this event. Please click on the link from the invitation e-mail.",
      "login_und_registrierung": "login and sign-up",
      "nachname": "lastname",
      "neues_passwort_vergeben": "assign new password",
      "new_password_message": "please enter your new password",
      "not_registered_message": "If you have not registered, you can do so under the following",
      "passwort": "password",
      "passwort_speichern": "save password",
      "passwort_vergessen": "forgot password",
      "passwort_wdh": "password repetition",
      "register": "I accept the data protection regulations",
      "register_accept_privacy": "Please accept the data protection regulations",
      "register_conformation_message": " You have successfully registered. You will shortly receive a confirmation e-mail with your login link.",
      "registrierung": "sign-up",
      "resend_code_message": "Send invitation code again",
      "reset_password_message": "Please enter your email address to reset your password. Then you will receive an email.",
      "tun": "do.",
      "vorname": "firstname",
      "zum_login": "login",
      "zur_registrierung": "sign-up"
    },
    "meeting": {
      "no_meeting_message": " You are not registered for any video meeting.",
      "video_meeting_beitreten": "join video meeting"
    },
    "mehr": "More",
    "nav": {
      "eingeloggt_als": "logged in as"
    },
    "oeffnen": "open",
    "profil": "account",
    "pwa": {
      "gehe_zu": "go to",
      "pwa_message_1": "Add this web app to your",
      "pwa_message_2": "home screen",
      "pwa_message_3": "and use it like a normal app even without an internet connection.",
      "und": "and",
      "zum_home_bildschirm": "to the home screen"
    },
    "schliessen": "close",
    "senden": "send",
    "speichern": "save",
    "streaming": {
      "3d_tooltip": "Here you will get to the 3D environment.",
      "fullscreen_tooltip": "fullscreen mode",
      "stream_tooltip": "Choose one of the live streams."
    },
    "ts": {
      "end_before_start": "The start time is later than the end time.",
      "enter_mail": "please enter your email",
      "fill_fields": "Please complete the red fields.",
      "link_expired": " Your activation link has expired. We have sent you an email with a new link. Please use it to activate your account.",
      "login_error": "login error!",
      "pass_mail_incorrect": "This user is not known to us, or the password is incorrect.",
      "pass_not_identical": "passwords must be identical",
      "pass_not_identical_2": "Your password is not identical.",
      "pass_saved": "Your password has been stored, please log in again.",
      "pass_too_short": "our password shall have a minimum length of 6 character",
      "post_check_message": " The post is now checked and unlocked!",
      "reset_your_pw": "Please reset your password!",
      "user_activated_login": "Your account has been successfully activated – please login ",
      "user_exits": "User has already registered!",
      "user_exits_login": "The user has already been registered, please log in",
      "user_not_found": "User not found!",
      "vollbild_beenden": "exit full screen mode",
      "vote_message": "Here you can vote and thus influence the order of the questions.",
      "wrong_password": "wrong password?"
    },
    "verfuegbar": "available",
    "video_oeffnen": "open video",
    "vote": {
      "sie_haben": "you have",
      "stimme": "vote",
      "stimmen": "vote",
      "vorschlagen": "suggest",
      "vote_inactive_message": "Survey currently not active.",
      "zu_der_auswertung": "to the evaluation",
      "zurueck_zur_umfrage": "Back to the vote"
    },
    "wall": {
      "alle": "all",
      "filtern": "screen",
      "pinnwand": "pinboard",
      "post_loeschen": "delete the message",
      "post_senden": "mail"
    },
    "weniger": "Less",
    "zum_dashboard": "to the dashboard",
    "zurueck": "back"
  }]];
  _exports.default = _default;
});