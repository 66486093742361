define("event-components/votechart/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "event-components/gql/fragments", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _fragments, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  const subscription = (0, _graphqlTag.default)`
subscription($where: VoteSubscriptionWhereInput) {
  vote(where: $where) {
    node {
     ${_fragments.VOTE_FRAGMENT}
    }
    previousValues {
      id
    }
    mutation
  }
}
`;
  const query = (0, _graphqlTag.default)`
  query votes($where: VoteWhereInput!) {
    votes(where: $where) {
      ${_fragments.VOTE_FRAGMENT}
    }
  }
`;
  let Chat = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("amplify"), (_class = class Chat extends Ember.Service.extend(Ember.Evented) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "votes", []);
      (0, _defineProperty2.default)(this, "_subscriber", void 0);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.initService();
    }

    async initService() {
      await (0, _emberConcurrency.waitForProperty)(this, "amplify.currentClient.id");
      this.initSubscriber();
      this.initVotesSubscription();
      this.preloadVotes();
    }

    async initVotesSubscription() {
      console.log(this.get("amplify"));
    }

    async preloadVotes() {
      let votes;
      let variables = {
        where: {
          client: {
            id: this.get("amplify.currentClient.id")
          }
        }
      };
      votes = await this.apollo.watchQuery({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "votes");
      this.get("votes").pushObjects(votes);
    }

    willDestroy() {
      this._subscriber.unsubscribe();
    }

    handleVoteChange(event) {
      console.log("new vote", ...arguments);

      if (event.mutation === "DELETED") {
        let oldVote = this.votes.findBy("id", event.previousValues.id);
        console.log("note is null", oldVote, this.votes.length);
        this.votes.removeObject(oldVote);
        console.log(this.votes.length);
      } else if (event.mutation === "UPDATED") {
        let oldVote = this.votes.findBy("id", event.previousValues.id);
        let idx = this.votes.indexOf(oldVote);
        console.log("note is updated", idx, this.votes.length);
        this.votes.replace(idx, 1, [event.node]);
        console.log(this.votes.length);
      } else {
        this.votes.pushObject(event.node);
      }

      this.trigger("vote:changed", event.node);
    }

    async initSubscriber() {
      try {
        const variables = {
          where: {
            mutation_in: ["CREATED", "UPDATED", "DELETED"],
            node: {
              client: {
                id: this.get("amplify.currentClient.id")
              }
            }
          }
        };
        this._subscriber = await this.apollo.subscribe({
          query: subscription,
          variables
        }, "vote");
        Ember.addListener(this._subscriber, "event", this, this.handleVoteChange);

        this._subscriber.on("event", event => {});
      } catch (e) {
        console.error(e);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = Chat;
});