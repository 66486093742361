define("event-components/components/app-dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qzZeLCSc",
    "block": "{\"symbols\":[\"module\",\"src\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"acl-groups\",[],[[\"@groups\",\"@usergroups\"],[[32,1,[\"groups\"]],[34,2,[\"currentUser\",\"groups\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,1,[\"icon\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"aws-image\",[],[[\"@file\"],[[32,1,[\"icon\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"app-dashboard-item\",[],[[\"@title\",\"@route\",\"@external\",\"@link\",\"@icon\",\"@appointmentNotifications\"],[[32,1,[\"name\"]],[32,1,[\"routeName\"]],[32,1,[\"external\"]],[32,1,[\"link\"]],[32,2],[34,1]]],null],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"app-dashboard-item\",[],[[\"@title\",\"@route\",\"@external\",\"@link\",\"@icon\",\"@appointmentNotifications\"],[[32,1,[\"name\"]],[32,1,[\"routeName\"]],[32,1,[\"external\"]],[32,1,[\"link\"]],[30,[36,0],[\"/icons/\",[32,1,[\"iconname\"]]],null],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"appointmentNotifications\",\"amplify\",\"if\",\"modules\",\"-track-array\",\"each\"]}",
    "moduleName": "event-components/components/app-dashboard/template.hbs"
  });

  _exports.default = _default;
});