define("event-components/components/hls-stream/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "event-components/components/hls-stream/template", "@ember-decorators/component"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2;

  let HlsStream = (_dec = (0, _component.tagName)(''), _dec2 = Ember.computed(), _dec(_class = (_class2 = class HlsStream extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
    }

    get token() {
      const Auth = this.amplify.Auth;
      return Auth.currentSession();
    }

    async didRender() {
      //let token = await this.get('token');
      if (DD_RUM != undefined) {
        DD_RUM.onReady(function () {
          DD_RUM.addRumGlobalContext('streamviewer', 'yes');
        });
      }

      if (false) {
        videojs.Hls.xhr.beforeRequest = function (options) {
          options.headers = options.headers || {};
          options.headers.Authorization = `Bearer ${token.accessToken.jwtToken}`;
          return options;
        };
      }

      try {
        Ember.run.next(this, async function () {
          this.player = videojs('vid1');
          console.log(this.player);
          this.player.hlsQualitySelector({
            displayCurrentQuality: true
          });
          await this.player.play();
        });
      } catch (e) {
        console.log(e);
      }
    }

    willDestroy() {
      this.player.dispose();
      this.player = null;
    } // normal class body definition here


  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "token", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "token"), _class2.prototype)), _class2)) || _class);
  _exports.default = HlsStream;
});