define("ember-x-tabs/templates/components/x-tabs-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "diTEl7tb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"ul\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,3],null,[[\"api\",\"tab\"],[[35,1],[30,[36,2],[\"x-tabs-tab\"],[[\"api\",\"ownerName\"],[[35,1],[35,0]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"ownerName\",\"api\",\"component\",\"hash\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tabs-tabs.hbs"
  });

  _exports.default = _default;
});