define("event-components/components/app-chat-pm-messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sfuvHDBW",
    "block": "{\"symbols\":[\"message\",\"imagepath\",\"&default\"],\"statements\":[[18,3,null],[2,\"\\n\"],[10,\"style\"],[12],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3,[\"unreadPmMessages\"]]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"user\",\"avatarthumb\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"chat-box-avatar right private-message\"],[12],[2,\"\\n        \"],[8,\"aws-image\",[],[[\"@file\",\"@tagless\"],[[32,1,[\"user\",\"avatarthumb\"]],\"true\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"img\"],[14,0,\"info-image\"],[15,\"src\",[31,[[32,2]]]],[12],[13],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"chat.von\"],null]],[2,\"\\n      \"],[1,[32,1,[\"user\",\"firstname\"]]],[2,\"\\n      \"],[1,[32,1,[\"user\",\"lastname\"]]],[2,\"\\n      \"],[1,[30,[36,0],[\"chat.um\"],null]],[2,\"\\n      \"],[1,[30,[36,2],[[32,1,[\"createdAt\"]],\"H:mm\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"chat.nachricht\"],null]],[2,\":\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n      \"],[1,[32,1,[\"message\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"chat.no_private_message\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"moment-format\",\"chatService\",\"-track-array\",\"each\"]}",
    "moduleName": "event-components/components/app-chat-pm-messages/template.hbs"
  });

  _exports.default = _default;
});