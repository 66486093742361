define("ember-x-tabs/components/x-tabs-panes", ["exports", "ember-x-tabs/templates/components/x-tabs-panes"], function (_exports, _xTabsPanes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xTabsPanes.default,
    classNames: ['content-wrap']
  });

  _exports.default = _default;
});