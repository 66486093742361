define("event-components/amplify/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "aws-amplify", "@ember-decorators/object", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _awsAmplify, _object, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const mutation = (0, _graphqlTag.default)`
mutation($data: UserCreateInput!) {
    createUser(
      data: $data
    ) {
      ${_fragments.USER_FRAGMENT}
    }
  }
`;
  const query = (0, _graphqlTag.default)`
  query clients($where: ClientWhereInput!) {
    clients(where: $where) {
      ${_fragments.CLIENT_FRAGMENT}
    }
  }
`;
  const query2 = (0, _graphqlTag.default)`
query user($where: UserWhereUniqueInput!) {
  user(where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}
`;
  const query4 = (0, _graphqlTag.default)`
query themes($where: ThemeWhereInput!) {
  themes(where: $where) {
    ${_fragments.THEME_FRAGMENT}
  }
}
`;
  const query3 = (0, _graphqlTag.default)`
  query program($where: ProgramItemWhereInput!) {
    programItems(orderBy: date_ASC, where: $where) {
      ${_fragments.PROGRAM_FRAGMENT}
    }
  }
`;
  const questionsQuery = (0, _graphqlTag.default)`
  query questions($where: QuestionWhereInput!) {
    questions(orderBy: sorting_ASC, where: $where) {
      ${_fragments.QUESTION_FRAGMENT}
    }
  }
`;
  const answersQuery = (0, _graphqlTag.default)`
  query answers($where: AnswerWhereInput!) {
    answers(where: $where) {
      ${_fragments.ANSWER_FRAGMENT}
    }
  }
`;
  const updateUser = (0, _graphqlTag.default)`
mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}
`;
  let AmplifyService = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = (0, _object.observes)('currentModule'), _dec4 = (0, _object.observes)('currentModuleItem'), _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('graphql'), _dec7 = Ember.computed('currentUser', 'currentClient'), _dec8 = Ember.inject.service('cookies'), (_class = class AmplifyService extends Ember.Service.extend(Ember.Evented) {
    // Variable to open and close the Unity Iframe
    // Variable to open and close the Videochat
    onCurrentModule() {
      this.set('currentModuleItem', '');
    }

    onCurrentModuleItem() {
      if (this.currentModuleItem === '') {
        this.trigger('moduleitem:unset');
      }
    }

    get isAuthenticated() {
      if (Ember.isNone(this.currentUser)) {
        return false;
      }

      if (this.currentUser.client.id !== this.currentClient.id) {
        return false;
      }

      return true;
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "Amplify", _awsAmplify.default);
      (0, _defineProperty2.default)(this, "I18n", _awsAmplify.I18n);
      (0, _defineProperty2.default)(this, "Analytics", _awsAmplify.Analytics);
      (0, _defineProperty2.default)(this, "Auth", _awsAmplify.Auth);
      (0, _defineProperty2.default)(this, "Storage", _awsAmplify.Storage);
      (0, _defineProperty2.default)(this, "Cache", _awsAmplify.Cache);
      (0, _defineProperty2.default)(this, "Logger", new _awsAmplify.Logger('Application'));
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "moment", _descriptor2, this);
      (0, _defineProperty2.default)(this, "currentUser", null);
      (0, _defineProperty2.default)(this, "currentClient", null);
      (0, _defineProperty2.default)(this, "currentModule", 'Chat');
      (0, _defineProperty2.default)(this, "componentName", '');
      (0, _defineProperty2.default)(this, "currentModuleItem", '');
      (0, _defineProperty2.default)(this, "currentModuleItem3rd", false);
      (0, _defineProperty2.default)(this, "hasAppointmentNotifications", false);
      (0, _defineProperty2.default)(this, "unityIframeOpen", false);
      (0, _defineProperty2.default)(this, "videoChatOpen", false);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor4, this);
      (0, _defineProperty2.default)(this, "cookiedomain", '');
      (0, _defineProperty2.default)(this, "websocket", void 0);
      (0, _initializerDefineProperty2.default)(this, "cookies", _descriptor5, this);
      window.LOG_LEVEL = 'ERROR';
      this.initAmplify();
      this.Storage.configure({
        level: 'private'
      });
      this.set('apollo', this.get('apolloService').createQueryManager());
      this.initMessageHandler();
      this.initSubscriber();
    }

    async initAmplify() {
      let {
        awsmobile
      } = Ember.getOwner(this).resolveRegistration('config:environment');
      let root_domain;

      if (location.host.includes('localhost')) {
        let temp = location.host.split('.').reverse();
        root_domain = 'localhost';
      } else if (location.href.includes('192.168.8.161')) {
        root_domain = '192.168.8.161';
      } else {
        let temp = location.host.split('.').reverse();
        root_domain = '.' + temp[1] + '.' + temp[0];
      }

      console.log('cookiedomain', `${root_domain}`, awsmobile);
      awsmobile.Auth.cookieStorage.domain = `${root_domain}`;
      this.cookiedomain = `${root_domain}`;
      this.Amplify.configure({ ...awsmobile
      });
    }

    async initSubscriber() {
      const websocket = this.websocket = new WebSocket('wss://8klsruotw2.execute-api.eu-central-1.amazonaws.com/prod');

      this.websocket.onclose = ({
        wasClean,
        code,
        reason
      }) => {
        setTimeout(() => {
          this.initSubscriber();
        }, 1000);
      };

      setInterval(() => {
        if (this.websocket.readyState !== WebSocket.OPEN && this.websocket.readyState !== WebSocket.CONNECTING) {
          this.trigger('websocket:reconnected');
          setTimeout(() => {
            // alert('reconnect socket');
            this.initSubscriber();
          }, 1000);
        }
      }, 1000);

      this.websocket.onerror = error => {
        if (this.websocket.readyState !== WebSocket.OPEN && this.websocket.readyState !== WebSocket.CONNECTING) {
          this.trigger('websocket:reconnected');
          setTimeout(() => {
            this.initSubscriber();
          }, 1000);
        }

        throw new Error(this.websocket.url);
      };

      this.websocket.onmessage = ({
        data
      }) => {
        let message = JSON.parse(data);

        if (message.type == 'createChatline') {
          this.trigger('ws:createChatline', {
            node: message.data
          });
        }

        if (message.type == 'updateVote') {
          this.trigger('ws:updateVote', {
            node: message.data
          });
        }

        if (message.type == 'userUpdated') {
          this.trigger('ws:updateUser', {
            node: message.data
          });
        }

        if (message.type == 'userUpdated2') {
          this.trigger('ws:updateUser2', {
            node: message.data
          });
        }

        if (message.type == 'updateAppointment') {
          this.trigger('ws:updateAppointment', {
            node: message.data
          });
        }

        if (message.type == 'createAppointment') {
          this.trigger('ws:createAppointment', {
            node: message.data
          });
        }

        if (message.type == 'deleteAppointment') {
          this.trigger('ws:deleteAppointment', {
            node: message.data
          });
        }

        if (message.type == 'removeUser' && message.data.id === this.get('currentUser.id')) {
          window.location.reload();
        }
      };

      this.websocket.onopen = () => {};
    }

    initMessageHandler() {
      addEventListener('message', event => {
        let data;

        try {
          data = JSON.parse(event.data);

          if (data.target) {
            this.trigger(`unity:${data.target}`, data.arg);
            Ember.run.next(this, () => {
              this.trigger(`unity:${data.target}`, data.arg);
            });
          }
        } catch (e) {//console.error(e);
        }
      }, false);
    }

    async removeFavToProfile(profile) {
      if (!this.currentUser) {
        return false;
      }

      if (!Ember.isArray(this.currentUser.favs)) {
        Ember.set(this.currentUser, 'favs', []);
      }

      console.log(this.currentUser.favs.findBy('id', profile.id));
      this.currentUser.favs.removeObject(this.currentUser.favs.findBy('id', profile.id));
      console.log(this.currentUser.favs.findBy('id', profile.id));
      Ember.set(this.currentUser, 'favs', this.currentUser.favs.uniqBy('id'));
      const variables = {
        where: {
          id: this.currentUser.id
        },
        data: {
          favs: this.currentUser.favs
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateUser,
          variables
        });
      } catch (e) {
        console.error(e);
      }
    }

    async addFavToProfile(profile) {
      if (!this.currentUser) {
        return false;
      }

      if (!Ember.isArray(this.currentUser.favs)) {
        Ember.set(this.currentUser, 'favs', []);
      }

      this.currentUser.favs.pushObject({
        id: profile.id,
        email: profile.email,
        firstname: profile.firstname,
        lastname: profile.lastname
      });
      Ember.set(this.currentUser, 'favs', this.currentUser.favs.uniqBy('id'));
      const variables = {
        where: {
          id: this.currentUser.id
        },
        data: {
          favs: this.currentUser.favs
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateUser,
          variables
        });
      } catch (e) {
        console.error(e);
      }
    }

    async initClient() {
      const variables = {
        where: {
          domain_contains: window.location.hostname
        }
      };
      let client;
      let clients = await this.apollo.watchQuery({
        query,
        variables,
        fetchPolicy: 'network-only'
      }, 'clients');

      if (clients.length > 0) {
        client = clients.firstObject;
        this.set('currentClient', client);
      }

      if (client.locale) {
        console.log(this.get('intl'));
        this.get('intl').setLocale([client.locale]);
        this.get('moment').setLocale(client.locale.split('-')[0]);
      } else {
        this.get('intl').setLocale(['de-de']);
        this.get('moment').setLocale('de');
      }
    }

    async generateAnonymUser(client) {
      console.log('generateAnonymUser');
      console.log(this.get('cookies').read(`session`));

      if (this.get('cookies').read(`session`) == undefined) {
        const variables = {
          data: {
            username: ``,
            firstname: 'Anonym',
            lastname: 'Anonym',
            email: 'anonym@eventinfo.app',
            invitecode: '',
            client: {
              connect: {
                id: client.id
              }
            }
          }
        };
        let prismaUser = await this.apollo.mutate({
          mutation,
          variables
        }, 'createUser');
        localStorage.setItem('user', prismaUser.id);
        this.get('cookies').write('session', prismaUser.id, {
          domain: this.cookiedomain
        });
        this.set('currentUser', prismaUser);
      } else {
        const variables = {
          where: {
            id: this.get('cookies').read(`session`)
          }
        };
        let user = null;
        user = await this.apollo.watchQuery({
          query: query2,
          variables,
          fetchPolicy: 'network-only'
        }, 'user');
        localStorage.setItem('user', user.id);
        this.set('currentUser', user);
      }
    }

    async logout() {
      localStorage.removeItem('user');
      const Auth = this.Auth;

      try {
        await Auth.signOut();

        if (this.isStreamingApp) {
          let domains = this.currentClient.domain.split(',');
          let firstDomain = domains[0];
          let href = 'https://' + firstDomain + '/#/login';
          return window.location.href = href;
        } else {
          await this.get('router').transitionTo('login');
          return window.location.reload();
        }
      } catch (e) {
        console.log(e);
      }
    }

    async initSession() {
      const Auth = this.Auth;
      let userId;
      let client = this.currentClient;

      if (client.loginmode === 'ANONYM') {
        await this.generateAnonymUser(client);
      }

      if (!(await Auth.currentUserInfo())) {
        console.log('no valid user session found');
      } else {
        console.log((await Auth.currentSession()).getIdToken().payload);

        if (client.loginmode === 'ANONYM') {
          console.log(await Auth.currentCredentials());
        } else {
          userId = (await Auth.currentSession()).getIdToken().payload['custom:userid'];
          localStorage.setItem('user', userId);
        }

        const variables = {
          where: {
            id: userId
          }
        };
        let user = null;
        user = await this.apollo.watchQuery({
          query: query2,
          variables,
          fetchPolicy: 'network-only'
        }, 'user');
        console.log(user);
        client.user = user;

        if (client.id !== user.client.id) {
          await this.logout();
          window.location.reload();
        }
      }

      const variables3 = {
        where: {
          client: {
            id: client.id
          }
        }
      };
      let themes = await this.apollo.watchQuery({
        query: query4,
        variables: variables3,
        fetchPolicy: 'network-only'
      }, 'themes');
      await this.setHeadTags(client, themes.firstObject); //this.loadProgram(client.id);

      Ember.set(client, 'theme', themes.firstObject);

      if (client.user) {
        this.set('currentUser', client.user);
      }

      this.set('currentTheme', themes.firstObject);
      await this.setHeadTags(client, themes.firstObject);
    }

    async setHeadTags(model, theme) {
      if (!theme) {
        return;
      }

      console.log(model, theme, theme.data.applogo.key);
      let logopath = await this.Storage.get(theme.data.applogo.key, {
        level: 'public'
      });
      let headTags = [{
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'client',
          content: theme.data.clientName
        }
      }, {
        type: 'link',
        tagId: 'canonical-link',
        attrs: {
          rel: 'apple-touch-icon',
          href: logopath
        }
      }, {
        type: 'title',
        content: theme.data.apptitle
      }, {
        type: 'meta',
        attrs: {
          name: 'apple-mobile-web-app-title',
          content: theme.data.apptitle
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'apple-mobile-web-app-capable',
          content: 'yes'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'default'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'theme-color',
          content: '#004080'
        }
      }];
      this.set('headTags', headTags);
      return headTags;
    }

    async findAllQuestions(client) {
      const variables = {
        where: {
          client: {
            id: client.id
          }
        }
      };

      if (Ember.isEmpty(client.user)) {
        return this.router.transitionTo('landing');
      }

      let questions = await this.apollo.query({
        query: questionsQuery,
        variables,
        fetchPolicy: 'network-only'
      }, 'questions');
      const variables2 = {
        where: {
          client: {
            id: client.id
          },
          user: {
            id: client.user.id
          }
        }
      };
      let answers = await this.apollo.query({
        query: answersQuery,
        variables: variables2,
        fetchPolicy: 'network-only'
      }, 'answers');

      if (!Ember.isEmpty(client.user.group)) {
        questions = questions.filter(item => {
          if (Ember.isEmpty(item.groups)) {
            return true;
          }

          return item.groups.includes(client.user.group);
        });
      }

      return {
        questions,
        answers
      };
    }

    async setAppointmentNotification(user, value) {
      if (!user) {
        user = this.currentUser;
      }

      console.log(user.id + '(' + user.firstname + ' ' + user.lastname + ') hat eine Notification');
      const variables = {
        where: {
          id: user.id
        },
        data: {
          hasUnreadAppointments: value
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateUser,
          variables
        });
      } catch (e) {
        console.error(e);
      }
    }

    async getAppointmentNotification() {
      const variables = {
        where: {
          id: this.currentUser.id
        }
      };

      try {
        let user = await this.apollo.query({
          query: query2,
          variables,
          fetchPolicy: 'network-only'
        }, 'user');
        console.log(user);
      } catch (e) {
        console.error(e);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "moment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCurrentModule", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "onCurrentModule"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onCurrentModuleItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "onCurrentModuleItem"), _class.prototype), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isAuthenticated", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isAuthenticated"), _class.prototype), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cookies", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = AmplifyService;
});