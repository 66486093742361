define("ember-x-tabs/components/x-tabs", ["exports", "ember-x-tabs/templates/components/x-tabs"], function (_exports, _xTabs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _xTabs.default,
    classNames: ['tabs'],
    classNameBindings: ['tab-style', 'customClass'],
    activeName: Ember.computed({
      get() {
        return undefined;
      },

      set(k, v) {
        return v;
      }

    }),
    activeData: undefined,
    name: 'x-tabs',
    selectFirstTab: true,
    actions: {
      select(name, data) {
        this.set('activeName', name);
        this.set('activeData', data);
      },

      register(name, data) {
        let activeName = this.get('activeName'); // Setting the data for the tabSelected, or selecting the firstTab

        if (name === activeName || this.get('selectFirstTab') === true && activeName == null) {
          this.set('selectFirstTab', false);
          Ember.run.scheduleOnce('afterRender', this, function () {
            this.send('select', name, data);
          });
        }
      }

    }
  });

  _exports.default = _default;
});