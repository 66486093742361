define("event-components/graphql/service", ["exports", "ember-apollo-client/services/apollo", "apollo-cache-inmemory", "apollo-cache-persist", "apollo-link-context"], function (_exports, _apollo, _apolloCacheInmemory, _apolloCachePersist, _apolloLinkContext) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const authLink = (0, _apolloLinkContext.setContext)((request, previousContext) => ({
    headers: {
      authorization: window.localStorage.getItem("user")
    }
  }));
  const cache = new _apolloCacheInmemory.InMemoryCache(); // await before instantiating ApolloClient, else queries might run before the cache is persisted

  (0, _apolloCachePersist.persistCache)({
    cache,
    storage: window.localStorage
  });

  class Graphql extends _apollo.default.extend({// anything which *must* be merged to prototype here
  }) {
    clientOptions() {
      return {
        link: this.link(),
        cache: cache
      };
    }

    link() {
      let httpLink = super.link();
      return authLink.concat(httpLink);
    }

  } // DO NOT DELETE: this is how TypeScript knows how to look up your services.


  _exports.default = Graphql;
});