define("ember-x-tabs/templates/components/x-tabs-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r6J26EQA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"a\"],[14,\"role\",\"tab\"],[15,\"aria-controls\",[34,0]],[15,\"onclick\",[30,[36,4],[[32,0],[35,3,[\"selectAction\"]],[35,2],[35,1]],null]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tabName\",\"data\",\"name\",\"api\",\"action\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tabs-tab.hbs"
  });

  _exports.default = _default;
});