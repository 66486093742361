define("@sentry/ember/index", ["exports", "@sentry/browser", "@sentry/utils"], function (_exports, Sentry, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    InitSentryForEmber: true,
    getActiveTransaction: true,
    instrumentRoutePerformance: true,
    init: true
  };
  _exports.InitSentryForEmber = InitSentryForEmber;
  _exports.init = _exports.instrumentRoutePerformance = _exports.getActiveTransaction = void 0;
  Object.keys(Sentry).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === Sentry[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return Sentry[key];
      }
    });
  });

  function _getSentryInitConfig() {
    var _global$__sentryEmber;

    const _global = (0, _utils.getGlobalObject)();

    _global.__sentryEmberConfig = (_global$__sentryEmber = _global.__sentryEmberConfig) !== null && _global$__sentryEmber !== void 0 ? _global$__sentryEmber : {};
    return _global.__sentryEmberConfig;
  }

  function InitSentryForEmber(_runtimeConfig) {
    const environmentConfig = {
      "sentry": {
        "dsn": "https://d1ef00334a0e4d858c2e823b03036045@o99865.ingest.sentry.io/1290469",
        "environment": "production",
        "tracesSampleRate": 0.05
      }
    };
    (false && !(environmentConfig) && Ember.assert('Missing configuration.', environmentConfig));
    (false && !(environmentConfig.sentry || _runtimeConfig) && Ember.assert('Missing configuration for Sentry.', environmentConfig.sentry || _runtimeConfig));

    if (!environmentConfig.sentry) {
      // If environment config is not specified but the above assertion passes, use runtime config.
      environmentConfig.sentry = { ..._runtimeConfig
      };
    } // Merge runtime config into environment config, preferring runtime.


    Object.assign(environmentConfig.sentry, _runtimeConfig || {});
    const initConfig = Object.assign({}, environmentConfig.sentry);
    initConfig._metadata = initConfig._metadata || {};
    initConfig._metadata.sdk = {
      name: 'sentry.javascript.ember',
      packages: [{
        name: 'npm:@sentry/ember',
        version: Sentry.SDK_VERSION
      }],
      version: Sentry.SDK_VERSION
    }; // Persist Sentry init options so they are identical when performance initializers call init again.

    const sentryInitConfig = _getSentryInitConfig();

    Object.assign(sentryInitConfig, initConfig);
    Sentry.init(initConfig);
  }

  const getActiveTransaction = () => {
    var _Sentry$getCurrentHub, _Sentry$getCurrentHub2;

    return (_Sentry$getCurrentHub = Sentry.getCurrentHub()) === null || _Sentry$getCurrentHub === void 0 ? void 0 : (_Sentry$getCurrentHub2 = _Sentry$getCurrentHub.getScope()) === null || _Sentry$getCurrentHub2 === void 0 ? void 0 : _Sentry$getCurrentHub2.getTransaction();
  };

  _exports.getActiveTransaction = getActiveTransaction;

  const instrumentRoutePerformance = BaseRoute => {
    const instrumentFunction = async (op, description, fn, args) => {
      const startTimestamp = (0, _utils.timestampWithMs)();
      const result = await fn(...args);
      const currentTransaction = getActiveTransaction();

      if (!currentTransaction) {
        return result;
      }

      currentTransaction.startChild({
        op,
        description,
        startTimestamp
      }).finish();
      return result;
    };

    return {
      [BaseRoute.name]: class extends BaseRoute {
        beforeModel(...args) {
          return instrumentFunction('ember.route.beforeModel', this.fullRouteName, super.beforeModel.bind(this), args);
        }

        async model(...args) {
          return instrumentFunction('ember.route.model', this.fullRouteName, super.model.bind(this), args);
        }

        async afterModel(...args) {
          return instrumentFunction('ember.route.afterModel', this.fullRouteName, super.afterModel.bind(this), args);
        }

        async setupController(...args) {
          return instrumentFunction('ember.route.setupController', this.fullRouteName, super.setupController.bind(this), args);
        }

      }
    }[BaseRoute.name];
  };

  _exports.instrumentRoutePerformance = instrumentRoutePerformance;
  // init is now the preferred way to call initialization for this addon.
  const init = InitSentryForEmber;
  _exports.init = init;
});