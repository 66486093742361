define("event-components/components/app-fragebogen/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-concurrency-decorators", "graphql-tag", "@ember-decorators/component", "event-components/gql/fragments", "event-components/components/app-fragebogen/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _emberConcurrencyDecorators, _graphqlTag, _component, _fragments, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  const query = (0, _graphqlTag.default)`
  query answers($where: AnswerWhereInput!) {
    answers(where: $where) {
      ${_fragments.ANSWER_FRAGMENT}
    }
  }
`;
  const updateMutation = (0, _graphqlTag.default)`
mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}
`;
  let AppFragebogen = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("notifications"), _dec3 = Ember.inject.service("amplify"), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.computed("questions"), _dec7 = Ember._action, _dec8 = (0, _emberConcurrencyDecorators.task)(), (_class = class AppFragebogen extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "notifications", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "eventbus", _descriptor5, this);
      (0, _defineProperty2.default)(this, "showSaveButton", true);
      (0, _defineProperty2.default)(this, "finished", false);
      (0, _defineProperty2.default)(this, "isDisabled", false);
      (0, _defineProperty2.default)(this, "isProfile", false);
      (0, _initializerDefineProperty2.default)(this, "isInvalid", _descriptor6, this);
      (0, _defineProperty2.default)(this, "aggregation", void 0);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.loadAggrgation().then(aggregation => {
        this.set('aggregation', aggregation);
      }); //this.get('eventbus').on('saveanswer', this, 'onSaveAnswer');
    }

    async loadAggrgation() {
      let result = await fetch('https://vq1r6gh2b7.execute-api.eu-central-1.amazonaws.com/prod/answers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          clientId: this.amplify.currentClient.id
        })
      });
      let aggregation = await result.json();
      return aggregation;
    }

    get groups() {
      let groups = [];
      this.questions.forEach(element => {
        if (!groups.includes(element.group)) {
          groups.pushObject(element.group);
        }
      });
      return groups;
    }

    onParticipationChange(value) {
      if (value === "1") {
        this.set("isDisabled", true);
      } else {
        this.set("isDisabled", false);
      }
    }

    *sendNotificationEmail() {
      try {
        if (this.showSaveButton == false) {
          return;
        }

        const variables = {
          where: {
            client: {
              id: this.get("client.id")
            },
            user: {
              id: this.get("user.id")
            }
          }
        };
        let answers = this.get("answers");
        let user = this.user;
        answers = answers.filter(a => {
          return a.question !== undefined && a.question !== null && a.user.invitecode === this.user.invitecode;
        });
        let questions = answers.map(a => {
          let val = parseInt(a.value);

          if (!val) {
            val = a.value;
          } else {
            val = a.question.values[parseInt(a.value)];
          }

          return {
            sorting: a.question.sorting,
            answer: val
          };
        });
        console.log("not 3");
        console.log(user, answers, questions);
        yield fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: this.user.email,
            emailSender: this.amplify.currentTheme.data.emailsender,
            subject: this.get("amplify.currentTheme.data.emailtempalteConfirmationSubject").replace(/\n/g, ""),
            content: "empty",
            emailTemplate: this.get("amplify.currentTheme.data.emailtempalteConfirmation").replace(/\n/g, "<br />"),
            data: { ...user,
              questions: questions
            }
          })
        }).then(response => response.json());
      } catch (e) {
        console.error(e);
      }
    }

    *pause() {
      try {
        console.log("enter save");
        const variables = {
          where: {
            client: {
              id: this.get("client.id")
            },
            user: {
              id: this.get("user.id")
            }
          }
        };
        let answers = yield this.get("apollo").query({
          query,
          variables,
          fetchPolicy: "network-only"
        }, "answers");
        this.set("answers", answers);
        let teilnehmerFrage = this.answers.findBy("question.participation");

        if (typeof teilnehmerFrage == "object" && teilnehmerFrage.value === "1") {
          console.log("teilnehmerFrage");
          yield this.get("eventbus").trigger("saveanswer");
          yield this.sendNotificationEmail.perform(); //yield this.get("notifications").success(
          //  this.amplify.currentTheme.data.successText
          //);

          this.set("isInvalid", false);
          let variables = {
            data: {
              finished: true
            },
            where: {
              id: this.user.id
            }
          };
          yield this.get("apollo").mutate({
            mutation: updateMutation,
            variables
          }, "updateUser");
          return;
        }

        if (document.getElementById("anmeldeform").checkValidity() === false) {
          console.log("validation failed");
          this.get("notifications").info(this.intl.t("fragebogen.required_fields"));
          this.set("isInvalid", true);
          throw "isInvalid";
          return;
        } else {
          console.log("validation success");
          yield this.get("eventbus").trigger("saveanswer");
          yield this.sendNotificationEmail.perform();
          let variables = {
            data: {
              finished: true
            },
            where: {
              id: this.user.id
            }
          };
          yield this.get("apollo").mutate({
            mutation: updateMutation,
            variables
          }, "updateUser");
        }

        if (this.showSaveButton) {
          this.set("finished", true);
        } //yield this.get("notifications").success(
        //  this.amplify.currentTheme.data.successText
        //);

      } catch (e) {
        console.error(e);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notifications", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "eventbus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isInvalid", [_component.className], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "groups", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "groups"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onParticipationChange", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onParticipationChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sendNotificationEmail", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "sendNotificationEmail"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pause", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "pause"), _class.prototype)), _class));
  _exports.default = AppFragebogen;
});