define("event-components/helpers/special-counter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.specialCounter = specialCounter;
  _exports.default = void 0;

  function specialCounter(params
  /*, hash*/
  ) {
    // const limit = params[0];
    // const value = params[1];
    // const answers = params[2];
    // console.log("SpecialCounter",params);
    // if (answers === undefined) {
    //   return false;
    // }
    // const filteredAnswers = answers.filter((item) => {
    //   return item.value.split(",").includes(value.toString());
    // });
    // return limit - filteredAnswers.length;
    let temp = params[0].findBy('value', params[1].toString());

    if (!temp) {
      return 0;
    }

    return temp.count;
  }

  var _default = Ember.Helper.helper(specialCounter);

  _exports.default = _default;
});