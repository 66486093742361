define("event-components/components/active-users/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/active-users/template", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  const subscription = (0, _graphqlTag.default)`
subscription($where: UserSubscriptionWhereInput) {
  user(where: $where) {
    node {
     ${_fragments.USER_FRAGMENT}
    }
  }
}
`;
  const query = (0, _graphqlTag.default)`
query users($where: UserWhereInput) {
  users(where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}`;
  const query2 = (0, _graphqlTag.default)`
query user($where: UserWhereUniqueInput!) {
  user(where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}`;
  let ActiveUsers = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('amplify'), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ActiveUsers extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    willDestroy() {
      try {
        this.amplify.off('nav:goback', this, 'onHistoryBack');
        this.amplify.off('ws:updateUser2', this, 'handleUser');
        Ember.removeListener(this._subscriber, 'event', this, this.handleUser);
      } catch (e) {}
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "_subscriber", void 0);
      (0, _defineProperty2.default)(this, "durationInMinutes", 10);
      (0, _defineProperty2.default)(this, "users", []);
      this.set('apollo', this.get('apolloService').createQueryManager());
      this.amplify.on('nav:goback', this, 'onHistoryBack');
      this.amplify.on('ws:updateUser2', this, 'handleUser');
      this.initSubscriber();
      this.loadUsers();
    }

    onHistoryBack() {
      this.set('currentChatroom', null);
      this.set('target', null);
    }

    addFavToProfil(user) {
      this.amplify.addFavToProfile(user);
    }

    async loadUsers() {
      var startdate = new Date();
      var durationInMinutes = this.durationInMinutes;
      startdate.setMinutes(startdate.getMinutes() - durationInMinutes);
      const variables = {
        where: {
          client: {
            id: this.amplify.currentClient.id
          },
          lastseen_gt: startdate.toISOString()
        }
      };
      let users = await this.apollo.query({
        query,
        variables
      }, 'users');
      this.set('users', users);
    }

    async handleUser(event) {
      if (!event.node.client) {
        const variables = {
          where: {
            id: event.node.id
          }
        };
        let user = await this.apollo.query({
          query: query2,
          variables
        }, 'user');
        event.node.client = user.client;
      }

      if (event.node.client.id !== this.amplify.currentClient.id) {
        return false;
      } else {}

      this.users.pushObject(event.node);
      this.set('users', this.users.uniqBy('id'));
    }

    inviteUserToVideoChat(userId) {
      this.amplify.trigger('videochat:invite', userId);
    }

    videocall(profile) {
      const iframe = document.getElementById('videochat');
      console.log(iframe);

      if (iframe != null) {
        this.amplify.set('videoChatOpen', true);
        iframe.contentWindow.postMessage({
          type: 'invite',
          data: profile.id
        }, '*');
      }
    }

    async initSubscriber() {
      try {
        const variables = {
          where: {
            mutation_in: ['CREATED', 'UPDATED'],
            node: {
              client: {
                id: this.amplify.currentClient.id
              }
            }
          }
        };
        this._subscriber = await this.apollo.subscribe({
          query: subscription,
          variables
        }, 'user');
        Ember.addListener(this._subscriber, 'event', this, this.handleUser);
      } catch (e) {
        console.error(e);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "addFavToProfil", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "addFavToProfil"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "inviteUserToVideoChat", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "inviteUserToVideoChat"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "videocall", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "videocall"), _class.prototype)), _class));
  _exports.default = ActiveUsers;
});