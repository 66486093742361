define("ember-apollo-client/-private/mixins/base-query-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    apolloService: Ember.inject.service('apollo'),
    apollo: Ember.computed('apolloService', function () {
      return this.apolloService.createQueryManager();
    }),

    init() {
      this._super(...arguments);

      (false && !(false) && Ember.deprecate(`
Mixins in ember-apollo-client are deprecated, use queryManager macro instead.

import { queryManager } from 'ember-apollo-client';

export default Route.extend({
  apollo: queryManager(),

  // ...
});`, false, {
        id: 'ember-apollo-client.deprecate-mixins',
        until: '3.0.0'
      }));
    }

  });

  _exports.default = _default;
});