define("event-components/components/app-appointments/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-appointments/template", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  const query = (0, _graphqlTag.default)`
  query appointments($where: AppointmentWhereInput) {
    appointments(where: $where) {
      ${_fragments.APPOINTMENT_FRAGMENT}
    }
  }
`;
  let AppAppointments = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("amplify"), _dec3 = Ember._action, (_class = class AppAppointments extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    didReceiveAttrs() {
      this.loadModel();
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "isLoading", false);
      (0, _defineProperty2.default)(this, "target", void 0);
      (0, _defineProperty2.default)(this, "appointmentModal", false);
      (0, _defineProperty2.default)(this, "date", "");
      (0, _defineProperty2.default)(this, "startAt", "");
      (0, _defineProperty2.default)(this, "endAt", "");
      (0, _defineProperty2.default)(this, "location", "");
      (0, _defineProperty2.default)(this, "description", "");
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.amplify.on('ws:updateAppointment', this, 'onUpdateAppointment');
      this.amplify.on('ws:createAppointment', this, 'onCreateAppointment');
      this.amplify.on('ws:deleteAppointment', this, 'onDeleteAppointment');
    }

    willDestroy() {
      this.amplify.off('ws:updateAppointment', this, 'onUpdateAppointment');
      this.amplify.off('ws:createAppointment', this, 'onCreateAppointment');
      this.amplify.off('ws:deleteAppointment', this, 'onDeleteAppointment');
    }

    async onUpdateAppointment(appointmentNode) {
      let openAppointments = await this.get('openAppointments');
      let pendingAppointments = await this.get('pendingAppointments');
      openAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
      pendingAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
    }

    async onCreateAppointment(appointmentNode) {
      this.set("user", this.amplify.currentUser);
      this.set("client", this.amplify.currentClient);

      if (appointmentNode.node.client.id == this.client.id) {
        if (appointmentNode.node.user.id == this.target.id || appointmentNode.node.requestUser.id == this.user.id) {
          this.loadModel();
        }
      }
    }

    async onDeleteAppointment(appointmentNode) {
      let openAppointments = await this.get('openAppointments');
      let pendingAppointments = await this.get('pendingAppointments');
      openAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
      pendingAppointments.forEach(a => {
        if (a.id == appointmentNode.node.id) {
          this.loadModel();
        }
      });
    }

    sortAppointment(a) {
      a.sort((a, b) => {
        let asort = parseInt(a.date.replace(/-/g, '') + a.startAt.replace(/:/g, ''));
        let bsort = parseInt(b.date.replace(/-/g, '') + b.startAt.replace(/:/g, ''));
        console.log(asort, bsort);
        return asort - bsort;
      });
      return a;
    }

    async loadModel() {
      this.set("user", this.amplify.currentUser);
      const variables = {
        where: {
          AND: {
            user: {
              id: this.target.id
            },
            requestUser: null
          }
        }
      };
      const variablesPending = {
        where: {
          AND: {
            user: {
              id: this.target.id
            },
            requestUser: {
              id: this.user.id
            }
          }
        }
      };
      let openAppointments = await this.apollo.query({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "appointments");
      let pendingAppointments = await this.apollo.query({
        query,
        variables: variablesPending,
        fetchPolicy: "network-only"
      }, "appointments");
      openAppointments = this.sortAppointment(openAppointments);
      pendingAppointments = this.sortAppointment(pendingAppointments);
      this.set("openAppointments", openAppointments);
      this.set("pendingAppointments", pendingAppointments);
    }

    compareDate(a, b) {
      if (a > b) {
        return -1;
      }

      if (a <= b) {
        return 1;
      }

      return 0;
    }

    async createAppointment() {
      this.set("user", this.amplify.currentUser);
      this.set("client", this.amplify.currentClient);
      this.amplify.setAppointmentNotification(this.target, true);
      fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: this.target.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: this.get("amplify.currentTheme.data.ETAReqSubject").replace(/\n/g, ""),
          content: "empty",
          emailTemplate: this.get("amplify.currentTheme.data.ETAReq").replace(/\n/g, "<br />"),
          data: {
            owner: this.target,
            appointment: {
              user: this.target,
              requestUser: this.user,
              date: this.date,
              startAt: this.startAt,
              endAt: this.endAt,
              location: this.location,
              description: this.description,
              type: 'Terminanfrage',
              approved: false
            }
          }
        })
      }).then(response => response.json());
      const variables = {
        data: {
          client: {
            connect: {
              id: this.client.id
            }
          },
          user: {
            connect: {
              id: this.target.id
            }
          },
          date: this.date,
          startAt: this.startAt,
          endAt: this.endAt,
          location: this.location,
          description: this.description,
          requestUser: {
            connect: {
              id: this.user.id
            }
          },
          type: this.intl.t('appointment.terminanfrage'),
          approved: false
        }
      };

      if (parseInt(this.startAt.replace(':', '')) >= parseInt(this.endAt.replace(':', ''))) {
        this.set('errorMsg', this.intl.t('appointment.zeit_validierung'));
      } else {
        this.set('isLoading', true);
        const mutation = (0, _graphqlTag.default)`
        mutation($data: AppointmentCreateInput!) {
          createAppointment(data: $data) {
            ${_fragments.APPOINTMENT_FRAGMENT}
          }
        }
      `;

        try {
          await this.apollo.mutate({
            mutation,
            variables
          }, "appointment");
        } catch (e) {
          console.error(e);
        } finally {
          this.set('errorMsg', null);
          this.set('isLoading', false);
          this.set('appointmentModal', false);
          this.loadModel();
        }
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createAppointment", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "createAppointment"), _class.prototype)), _class));
  _exports.default = AppAppointments;
});