define("event-components/components/app-chat/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "@ember-decorators/component", "event-components/gql/fragments", "ember-concurrency-decorators", "event-components/components/app-chat/template", "@ember-decorators/object"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _component, _fragments, _emberConcurrencyDecorators, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  const CREATE_CHATLINE = (0, _graphqlTag.default)`
  mutation($data: ChatlineCreateInput!) {
    createChatline(
      data: $data
    ) {
      ${_fragments.CHATLINE_FRAGMENT}
    }
  }
`;
  const query = (0, _graphqlTag.default)`
  query ($where: ChatlineWhereInput!, $last: Int, $after: String) {
    chatlines(where: $where, last: $last, after: $after) {
      id
      message
      target
      system
      videochatMessage
      user {
        id
        username
        firstname
        lastname
        avatarthumb {
          id
          key
        }
      }
      createdAt
      updatedAt
    }
  }
`;
  const query2 = (0, _graphqlTag.default)`
  query($where: ChatroomWhereInput!) {
    chatrooms(
      where: $where
    ) {
      ${_fragments.CHATROOM_FRAGMENT}
    }
  }
`;
  const mutation = (0, _graphqlTag.default)`
mutation createChatroom($data: ChatroomCreateInput!) {
  createChatroom(data: $data) {
    ${_fragments.CHATROOM_FRAGMENT}
  }
}
`;
  let AppChat = (_dec = (0, _component.classNames)("app-chat"), _dec2 = Ember.inject.service("graphql"), _dec3 = Ember.inject.service("chat"), _dec4 = Ember.inject.service("amplify"), _dec5 = Ember.inject.service("router"), _dec6 = Ember._action, _dec7 = (0, _object.observes)("currentChatroom"), _dec8 = Ember._action, _dec9 = (0, _emberConcurrencyDecorators.task)(), _dec10 = (0, _emberConcurrencyDecorators.task)(), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec(_class = (_class2 = class AppChat extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    // imageHeight: any = this.get("amplify.currentTheme.data.chatImageHeight") + "px";
    // imageStyle: string = "height: " + this.imageHeight + ";";
    unsetCurrentRoom() {
      this.set("currentChatroom", null);
    }

    onCurrentChatroom() {
      if (this.currentChatroom) {
        if (this.currentChatroom.privateuser !== null) {
          if (this.currentChatroom.privateuser.firstObject.id == this.amplify.currentUser.id) {
            this.amplify.set("currentModuleItem", `Privater Chat: ${this.currentChatroom.privateuser.lastObject.firstname} ${this.currentChatroom.privateuser.lastObject.lastname}`);
          } else {
            this.amplify.set("currentModuleItem", `Privater Chat: ${this.currentChatroom.privateuser.firstObject.firstname} ${this.currentChatroom.privateuser.firstObject.lastname}`);
          }
        } else {
          this.amplify.set("currentModuleItem", this.currentChatroom.name);
        }
      }
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "chatService", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor4, this);
      (0, _defineProperty2.default)(this, "chatmessage", "");
      (0, _defineProperty2.default)(this, "chatMessages", []);
      (0, _defineProperty2.default)(this, "chatrooms", []);
      (0, _defineProperty2.default)(this, "chatIsPrivate", false);
      (0, _defineProperty2.default)(this, "isInitialized", false);
      (0, _defineProperty2.default)(this, "currentChatroom", null);
      (0, _defineProperty2.default)(this, "autoScroll", true);
      (0, _defineProperty2.default)(this, "target", null);
      this.set("imageStyle", "height: " + this.imageHeight + "; width: auto;");

      try {
        this.set("apollo", this.get("apolloService").createQueryManager());
        this.get("chatService").on("chatline:created", this, "chatlineCreated");
        this.get("amplify").on("moduleitem:unset", this, "unset");
        this.get("amplify").on("nav:gotoprivate", user => {
          this.target = user;
          this.loadChatrooms.perform();
        });
      } catch (e) {
        console.error(e);
      }
    }

    removeUser() {
      this.set("currentChatroom", null);
    }

    unset() {
      this.set("currentChatroom", null);
    }

    willDestroyElement() {
      this.get("amplify").off("moduleitem:unset", this, "unset");
    }

    didReceiveAttrs() {}

    didInsertElement() {
      this.loadChatrooms.perform();

      if (this.currentChatroom) {
        this.joinChatroom.perform(this.currentChatroom);
      }

      $(".app-chat").on("wheel", () => {
        const appChat = document.getElementsByClassName("app-chat")[0];

        if (appChat.scrollTop + appChat.clientHeight == appChat.scrollHeight) {
          this.set("autoScroll", true);
        } else {
          this.set("autoScroll", false);
        }
      });
    }

    videocall(id) {
      const iframe = document.getElementById('videochat');

      if (iframe != null) {
        this.amplify.set('videoChatOpen', true);
        iframe.contentWindow.postMessage({
          type: 'invite',
          data: id
        }, '*');
      }
    }

    *loadChatlines() {
      console.log("LOAD CHATLINES");

      if (!this.currentChatroom) {
        return;
      }

      try {
        let variables = {
          where: {
            chatroom: {
              id: this.currentChatroom.id
            }
          },
          last: 100
        };
        let cachedLInes = this.get("amplify.Cache").getItem(`chatline_${this.currentChatroom.id}`);

        if (cachedLInes !== null && cachedLInes.lastObject) {
          variables.after = cachedLInes.lastObject.id;
        } else {
          cachedLInes = [];
        }

        let chatlines = yield this.apollo.query({
          query,
          variables,
          fetchPolicy: "network-only"
        }, "chatlines");
        chatlines = cachedLInes.concat(chatlines);
        this.get("amplify.Cache").setItem(`chatline_${this.currentChatroom.id}`, chatlines);
        console.log(`lines: ${chatlines.length}`);
        this.set("chatMessages", chatlines.filterBy("target", null));
        chatlines.forEach(element => {
          let chatLine = this.get("chatService.unreadMessages").findBy("id", element.id);
          let chatLineIndex = this.get("chatService.unreadMessages").indexOf(chatLine);

          if (chatLineIndex != -1) {
            this.get("chatService.unreadMessages").removeAt(chatLineIndex);
          }
        });
        Ember.run.next(this, () => {
          this.set("isInitialized", true);
          this.scrollToBottom();
        });
      } catch (e) {
        console.error(e);
      }
    }

    *loadChatrooms() {
      try {
        const variables = {
          where: {
            client: {
              id: this.get("amplify.currentClient.id")
            }
          }
        };
        let chatrooms = yield this.apollo.query({
          query: query2,
          variables,
          fetchPolicy: "network-only"
        }, "chatrooms");
        this.chatService.set("chatrooms", chatrooms);
        chatrooms = chatrooms.filter(c => {
          var _c$privateusersmap;

          if (this.get("amplify.currentUser.groups").includes("Stoerer") && c.groupname === "Stoerer") {
            return true;
          } else if (this.get("amplify.currentUser.groups").includes(c.groupname) && !this.get("amplify.currentUser.groups").includes("Stoerer")) {
            return true;
          } else if (c.autoEnter && !this.get("amplify.currentUser.groups").includes("Stoerer")) {
            return true;
          } else if ((_c$privateusersmap = c.privateusersmap) !== null && _c$privateusersmap !== void 0 && _c$privateusersmap.includes(this.get("amplify.currentUser.id"))) {
            return false;
          } else {
            return false;
          }
        });
        this.set("chatrooms", chatrooms);
        if (this.target !== null) this.openPrivateChat(this.target);

        if (!Ember.isEmpty(this.chat)) {
          let chat = this.chatrooms.findBy("id", this.chat);
          this.set("currentChatroom", chat);
        }
      } catch (e) {
        console.error(e);
      }
    }

    async openPrivateChat(target) {
      console.log("ROOMS", this.chatrooms, this.get("amplify.currentUser.groups"));
      let rooms = this.chatrooms.filter(c => {
        if (c.privateusersmap == null) {
          return false;
        }

        return c.privateusersmap.includes(this.amplify.currentUser.id) && c.privateusersmap.includes(target.id);
      });
      console.log("ROOMS", rooms);
      Ember.run.next(this, async function () {
        if (rooms.length > 0) {
          this.set("currentChatroom", rooms.firstObject);
          this.joinChatroom.perform(this.currentChatroom);
        } else {
          let newChatroom = await this.createPrivateChatroom(target);
          this.set("currentChatroom", newChatroom);
          this.joinChatroom.perform(this.currentChatroom);
        }
      });
    }

    async createPrivateChatroom(target) {
      const variables = {
        data: {
          name: `Privater Chat: ${target.firstname} ${target.lastname}`,
          privateuser: [this.amplify.currentUser, target],
          privateusersmap: `${this.amplify.currentUser.id}-${target.id}`,
          text: "",
          client: {
            connect: {
              id: this.amplify.currentClient.id
            }
          }
        }
      };
      let chatroom = await this.apollo.mutate({
        mutation: mutation,
        variables
      }, "createChatroom");
      return chatroom;
    }

    enableAutoScroll() {
      this.set("autoScroll", true);
      window.scrollTo(0, document.body.scrollHeight);
      document.getElementsByClassName("app-chat")[0].scrollTop = document.getElementsByClassName("app-chat")[0].scrollHeight;
    }

    keyDown(event) {
      if (event.keyCode === 13) {
        this.send("newChatline");
        return false;
      }
    }

    back(event) {
      if (this.currentChatroom) {
        this.set("currentChatroom", null);
        this.set("target", null);
      } else {
        this.set("target", null);
        this.get("router").transitionTo("index");
      }
    }

    scrollToBottom() {
      console.log(this.isInitialized, this.autoScroll);

      if (this.isInitialized && this.autoScroll) {
        window.scrollTo(0, document.body.scrollHeight);
        document.getElementsByClassName("app-chat")[0].scrollTop = document.getElementsByClassName("app-chat")[0].scrollHeight;
      }
    }

    *joinChatroom(chatroom) {
      this.set("currentChatroom", chatroom);
      yield this.loadChatlines.perform();
    }

    chatlineCreated(chatline) {
      var _chatline$chatroom, _this$currentChatroom;

      console.log("Yay new chats", chatline);

      if (((_chatline$chatroom = chatline.chatroom) === null || _chatline$chatroom === void 0 ? void 0 : _chatline$chatroom.id) !== ((_this$currentChatroom = this.currentChatroom) === null || _this$currentChatroom === void 0 ? void 0 : _this$currentChatroom.id)) {
        return;
      }

      try {
        this.scrollToBottom();
      } catch (e) {
        console.error(e);
      }

      this.chatMessages.pushObject(chatline);
    }

    async newChatline(event) {
      if (this.chatmessage === "") {
        return;
      }

      const variables = {
        data: {
          chatroom: {
            connect: {
              id: this.get("currentChatroom.id")
            }
          },
          client: {
            connect: {
              id: this.get("amplify.currentClient.id")
            }
          },
          message: this.chatmessage,
          user: {
            connect: {
              id: this.get("amplify.currentUser.id")
            }
          }
        }
      };
      this.set("chatmessage", "");
      let chatline = await this.apollo.mutate({
        mutation: CREATE_CHATLINE,
        variables
      }, "createChatline"); //this.set("chatmessage", "");
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apolloService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "chatService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "amplify", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "unsetCurrentRoom", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "unsetCurrentRoom"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onCurrentChatroom", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "onCurrentChatroom"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "videocall", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "videocall"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadChatlines", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "loadChatlines"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "loadChatrooms", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "loadChatrooms"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "enableAutoScroll", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "enableAutoScroll"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "keyDown", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "keyDown"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "back", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "back"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "scrollToBottom", [_dec14], Object.getOwnPropertyDescriptor(_class2.prototype, "scrollToBottom"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "joinChatroom", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "joinChatroom"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "newChatline", [_dec15], Object.getOwnPropertyDescriptor(_class2.prototype, "newChatline"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppChat;
});