define("event-components/components/infinity-loader/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "ember-concurrency-decorators", "event-components/components/infinity-loader/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _emberConcurrencyDecorators, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _class2, _descriptor;

  let InfinityLoader = (_dec = (0, _component.tagName)("div"), _dec(_class = (_class2 = class InfinityLoader extends Ember.Component.extend({}) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "id", _descriptor, this);
      (0, _defineProperty2.default)(this, "isFirstRun", true);
    }

    *interSectionObserver(entry) {
      console.log(entry);

      if (entry.isIntersecting && !this.isFirstRun) {
        console.log("load more");
        this.onInit();
      } else {
        this.set("isFirstRun", false);
      }
    }

    didInsertElement() {
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.0
      };
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          this.interSectionObserver.perform(entry);
        });
      }, options);
      observer.observe(this.element);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "id", [_component.attribute], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "infinity-loader";
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "interSectionObserver", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "interSectionObserver"), _class2.prototype)), _class2)) || _class);
  _exports.default = InfinityLoader;
});