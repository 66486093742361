define("@sentry/ember/instance-initializers/sentry-performance", ["exports", "@sentry/browser", "@sentry/ember", "@sentry/utils"], function (_exports, Sentry, _ember, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports._instrumentEmberRouter = _instrumentEmberRouter;
  _exports.instrumentForPerformance = instrumentForPerformance;
  _exports.default = void 0;

  function getSentryConfig() {
    var _global$__sentryEmber;

    const _global = (0, _utils.getGlobalObject)();

    _global.__sentryEmberConfig = (_global$__sentryEmber = _global.__sentryEmberConfig) !== null && _global$__sentryEmber !== void 0 ? _global$__sentryEmber : {};
    const environmentConfig = {
      "sentry": {
        "dsn": "https://d1ef00334a0e4d858c2e823b03036045@o99865.ingest.sentry.io/1290469",
        "environment": "production",
        "tracesSampleRate": 0.05
      }
    };
    Object.assign(environmentConfig.sentry, _global.__sentryEmberConfig);
    return environmentConfig;
  }

  function initialize(appInstance) {
    const config = getSentryConfig();

    if (config['disablePerformance']) {
      return;
    }

    const performancePromise = instrumentForPerformance(appInstance);
  }

  function getBackburner() {
    if (Ember.run.backburner) {
      return Ember.run.backburner;
    }

    return Ember.run.backburner;
  }

  function getTransitionInformation(transition, router) {
    var _transition$from;

    const fromRoute = transition === null || transition === void 0 ? void 0 : (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name;
    const toRoute = transition && transition.to ? transition.to.name : router.currentRouteName;
    return {
      fromRoute,
      toRoute
    };
  }

  function getLocationURL(location) {
    if (!location || !location.getURL || !location.formatURL) {
      return '';
    }

    const url = location.formatURL(location.getURL());

    if (location.implementation === 'hash') {
      return `${location.rootURL}${url}`;
    }

    return url;
  }

  function _instrumentEmberRouter(routerService, routerMain, config, startTransaction, startTransactionOnPageLoad) {
    const {
      disableRunloopPerformance
    } = config;
    const location = routerMain.location;
    let activeTransaction;
    let transitionSpan;
    const url = getLocationURL(location);

    if (startTransactionOnPageLoad && url) {
      const routeInfo = routerService.recognize(url);
      activeTransaction = startTransaction({
        name: `route:${routeInfo.name}`,
        op: 'pageload',
        tags: {
          url,
          toRoute: routeInfo.name,
          'routing.instrumentation': '@sentry/ember'
        }
      });
    }

    const finishActiveTransaction = function (_, nextInstance) {
      if (nextInstance) {
        return;
      }

      activeTransaction.finish();
      getBackburner().off('end', finishActiveTransaction);
    };

    routerService.on('routeWillChange', transition => {
      var _activeTransaction;

      const {
        fromRoute,
        toRoute
      } = getTransitionInformation(transition, routerService);
      (_activeTransaction = activeTransaction) === null || _activeTransaction === void 0 ? void 0 : _activeTransaction.finish();
      activeTransaction = startTransaction({
        name: `route:${toRoute}`,
        op: 'navigation',
        tags: {
          fromRoute,
          toRoute,
          'routing.instrumentation': '@sentry/ember'
        }
      });
      transitionSpan = activeTransaction.startChild({
        op: 'ember.transition',
        description: `route:${fromRoute} -> route:${toRoute}`
      });
    });
    routerService.on('routeDidChange', () => {
      if (!transitionSpan || !activeTransaction) {
        return;
      }

      transitionSpan.finish();

      if (disableRunloopPerformance) {
        activeTransaction.finish();
        return;
      }

      getBackburner().on('end', finishActiveTransaction);
    });
    return {
      startTransaction
    };
  }

  function _instrumentEmberRunloop(config) {
    const {
      disableRunloopPerformance,
      minimumRunloopQueueDuration
    } = config;

    if (disableRunloopPerformance) {
      return;
    }

    let currentQueueStart;
    let currentQueueSpan;
    const instrumentedEmberQueues = ['actions', 'routerTransitions', 'render', 'afterRender', 'destroy'];
    getBackburner().on('begin', (_, previousInstance) => {
      if (previousInstance) {
        return;
      }

      const activeTransaction = (0, _ember.getActiveTransaction)();

      if (!activeTransaction) {
        return;
      }

      if (currentQueueSpan) {
        currentQueueSpan.finish();
      }

      currentQueueStart = (0, _utils.timestampWithMs)();
      instrumentedEmberQueues.forEach(queue => {
        Ember.run.scheduleOnce(queue, null, () => {
          Ember.run.scheduleOnce(queue, null, () => {
            // Process this queue using the end of the previous queue.
            if (currentQueueStart) {
              const now = (0, _utils.timestampWithMs)();
              const minQueueDuration = minimumRunloopQueueDuration !== null && minimumRunloopQueueDuration !== void 0 ? minimumRunloopQueueDuration : 5;

              if ((now - currentQueueStart) * 1000 >= minQueueDuration) {
                activeTransaction === null || activeTransaction === void 0 ? void 0 : activeTransaction.startChild({
                  op: `ember.runloop.${queue}`,
                  startTimestamp: currentQueueStart,
                  endTimestamp: now
                }).finish();
              }

              currentQueueStart = undefined;
            } // Setup for next queue


            const stillActiveTransaction = (0, _ember.getActiveTransaction)();

            if (!stillActiveTransaction) {
              return;
            }

            currentQueueStart = (0, _utils.timestampWithMs)();
          });
        });
      });
    });
    getBackburner().on('end', (_, nextInstance) => {
      if (nextInstance) {
        return;
      }

      if (currentQueueSpan) {
        currentQueueSpan.finish();
        currentQueueSpan = undefined;
      }
    });
  }

  function processComponentRenderBefore(payload, beforeEntries) {
    const info = {
      payload,
      now: (0, _utils.timestampWithMs)()
    };
    beforeEntries[payload.object] = info;
  }

  function processComponentRenderAfter(payload, beforeEntries, op, minComponentDuration) {
    const begin = beforeEntries[payload.object];

    if (!begin) {
      return;
    }

    const now = (0, _utils.timestampWithMs)();
    const componentRenderDuration = now - begin.now;

    if (componentRenderDuration * 1000 >= minComponentDuration) {
      const activeTransaction = (0, _ember.getActiveTransaction)();
      activeTransaction === null || activeTransaction === void 0 ? void 0 : activeTransaction.startChild({
        op,
        description: payload.containerKey || payload.object,
        startTimestamp: begin.now,
        endTimestamp: now
      });
    }
  }

  function _instrumentComponents(config) {
    const {
      disableInstrumentComponents,
      minimumComponentRenderDuration,
      enableComponentDefinitions
    } = config;

    if (disableInstrumentComponents) {
      return;
    }

    const minComponentDuration = minimumComponentRenderDuration !== null && minimumComponentRenderDuration !== void 0 ? minimumComponentRenderDuration : 2;
    const beforeEntries = {};
    const beforeComponentDefinitionEntries = {};
    const subscribe = Ember.subscribe;

    function _subscribeToRenderEvents() {
      subscribe('render.component', {
        before(_name, _timestamp, payload) {
          processComponentRenderBefore(payload, beforeEntries);
        },

        after(_name, _timestamp, payload, _beganIndex) {
          processComponentRenderAfter(payload, beforeEntries, 'ember.component.render', minComponentDuration);
        }

      });

      if (enableComponentDefinitions) {
        subscribe('render.getComponentDefinition', {
          before(_name, _timestamp, payload) {
            processComponentRenderBefore(payload, beforeComponentDefinitionEntries);
          },

          after(_name, _timestamp, payload, _beganIndex) {
            processComponentRenderAfter(payload, beforeComponentDefinitionEntries, 'ember.component.definition', 0);
          }

        });
      }
    }

    _subscribeToRenderEvents();
  }

  function _instrumentInitialLoad(config) {
    const startName = '@sentry/ember:initial-load-start';
    const endName = '@sentry/ember:initial-load-end';
    const {
      performance
    } = window;
    const HAS_PERFORMANCE = performance && performance.clearMarks && performance.clearMeasures;

    if (!HAS_PERFORMANCE) {
      return;
    }

    if (config.disableInitialLoadInstrumentation) {
      performance.clearMarks(startName);
      performance.clearMarks(endName);
      return;
    } // Split performance check in two so clearMarks still happens even if timeOrigin isn't available.


    const HAS_PERFORMANCE_TIMING = performance.measure && performance.getEntriesByName && _utils.browserPerformanceTimeOrigin !== undefined;

    if (!HAS_PERFORMANCE_TIMING) {
      return;
    }

    const measureName = '@sentry/ember:initial-load';
    const startMarkExists = performance.getEntriesByName(startName).length > 0;
    const endMarkExists = performance.getEntriesByName(endName).length > 0;

    if (!startMarkExists || !endMarkExists) {
      return;
    }

    performance.measure(measureName, startName, endName);
    const measures = performance.getEntriesByName(measureName);
    const measure = measures[0];
    const startTimestamp = (measure.startTime + _utils.browserPerformanceTimeOrigin) / 1000;
    const endTimestamp = startTimestamp + measure.duration / 1000;
    const transaction = (0, _ember.getActiveTransaction)();
    const span = transaction === null || transaction === void 0 ? void 0 : transaction.startChild({
      op: 'ember.initial-load',
      startTimestamp
    });
    span === null || span === void 0 ? void 0 : span.finish(endTimestamp);
    performance.clearMarks(startName);
    performance.clearMarks(endName);
    performance.clearMeasures(measureName);
  }

  async function instrumentForPerformance(appInstance) {
    var _Sentry$getCurrentHub;

    const config = getSentryConfig();
    const sentryConfig = config.sentry; // Maintaining backwards compatibility with config.browserTracingOptions, but passing it with Sentry options is preferred.

    const browserTracingOptions = config.browserTracingOptions || config.sentry.browserTracingOptions || {};
    const tracing = await emberAutoImportDynamic("@sentry/tracing");
    const idleTimeout = config.transitionTimeout || 5000;
    const existingIntegrations = sentryConfig['integrations'] || [];
    sentryConfig['integrations'] = [...existingIntegrations, new tracing.Integrations.BrowserTracing({
      routingInstrumentation: (customStartTransaction, startTransactionOnPageLoad) => {
        const routerMain = appInstance.lookup('router:main');
        const routerService = appInstance.lookup('service:router');

        _instrumentEmberRouter(routerService, routerMain, config, customStartTransaction, startTransactionOnPageLoad);
      },
      idleTimeout,
      ...browserTracingOptions
    })];

    if (false && (_Sentry$getCurrentHub = Sentry.getCurrentHub()) !== null && _Sentry$getCurrentHub !== void 0 && _Sentry$getCurrentHub.getIntegration(tracing.Integrations.BrowserTracing)) {
      // Initializers are called more than once in tests, causing the integrations to not be setup correctly.
      return;
    }

    Sentry.init(sentryConfig); // Call init again to rebind client with new integration list in addition to the defaults

    _instrumentEmberRunloop(config);

    _instrumentComponents(config);

    _instrumentInitialLoad(config);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});