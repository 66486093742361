define("ember-apollo-client/-private/mixins/component-query-manager", ["exports", "ember-apollo-client/-private/mixins/base-query-manager"], function (_exports, _baseQueryManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_baseQueryManager.default, {
    willDestroyElement() {
      this._super(...arguments);

      this.apollo.unsubscribeAll(false);
    }

  });

  _exports.default = _default;
});