define("event-components/components/app-meeting/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "event-components/components/app-meeting/template", "ember-concurrency-decorators"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _template, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  let AppMeeting = (_class = class AppMeeting extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
    }

    *joinMeeting(userId) {
      window.open(`${this.amplify.currentTheme.data.meetingUrl}?userid=${userId}`, "_blank");
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "joinMeeting", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "joinMeeting"), _class.prototype)), _class);
  _exports.default = AppMeeting;
});