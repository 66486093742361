define("ember-x-tabs/templates/components/x-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EWLPTEgL",
    "block": "{\"symbols\":[\"api\",\"&default\"],\"statements\":[[6,[37,6],[[30,[36,2],null,[[\"selectAction\",\"registerAction\",\"activeName\",\"name\",\"activeData\"],[[30,[36,5],[[32,0],\"select\"],null],[30,[36,5],[[32,0],\"register\"],null],[35,4],[35,1],[35,3]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,2,[[30,[36,2],null,[[\"tabs\",\"panes\",\"dataPane\",\"api\"],[[30,[36,0],[\"x-tabs-tabs\"],[[\"api\",\"ownerName\"],[[32,1],[35,1]]]],[30,[36,0],[\"x-tabs-panes\"],[[\"api\"],[[32,1]]]],[30,[36,0],[\"x-tabs-pane-data\"],[[\"api\"],[[32,1]]]],[32,1]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"name\",\"hash\",\"activeData\",\"activeName\",\"action\",\"let\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tabs.hbs"
  });

  _exports.default = _default;
});