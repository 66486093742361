define("event-components/components/app-programm-module/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-programm-module/template", "event-components/gql/fragments", "graphql-tag", "moment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _fragments, _graphqlTag, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  const query = (0, _graphqlTag.default)`
  query program($where: ProgramItemWhereInput!) {
    programItems(orderBy: sorting_ASC, where: $where) {
      ${_fragments.PROGRAM_FRAGMENT}
    }
  }
`;
  const query2 = (0, _graphqlTag.default)`
  query agenda($where: AgendaItemWhereInput!) {
    agendaItems(where: $where) {
      ${_fragments.AGENDA_FRAGMENT}
    }
  }
`;
  let AppProgrammModule = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('amplify'), (_class = class AppProgrammModule extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      this.set('apollo', this.get('apolloService').createQueryManager());
      this.loadModel();
    }

    async loadModel() {
      let client = await this.amplify.get('currentClient');
      let variables = {
        where: {
          client: {
            id: client.id
          }
        },
        orderBy: 'sorting_ASC'
      };
      let cache = this.amplify.currentTheme.data.cachebehavior ? this.amplify.currentTheme.data.cachebehavior : 'network-only';
      let programItems = await this.apollo.query({
        query,
        variables,
        fetchPolicy: cache
      }, 'programItems');
      let variables2 = {
        where: {
          user: {
            id: this.amplify.currentUser.id
          }
        },
        orderBy: 'sorting_ASC'
      };
      let agendaItems = this.apollo.query({
        query: query2,
        variables: variables2,
        fetchPolicy: cache
      }, 'agendaItems');
      let tabs = [];

      if (this.amplify.currentUser !== undefined) {
        if (Array.isArray(this.amplify.currentUser.groups)) {
          programItems = programItems.filter(p => {
            if (Array.isArray(p.whitelist) && p.whitelist.length > 0) {
              console.log(this.amplify.currentUser.groups);

              if (this.amplify.currentUser.groups.length == 0) {
                return false;
              }

              return this.amplify.currentUser.groups.some(group => {
                return p.whitelist.includes(group);
              });
            }

            return true;
          });
        }
      } else {
        programItems = programItems.filter(p => {
          if (Array.isArray(p.whitelist) && p.whitelist.length > 0) {
            return false;
          } else {
            return true;
          }
        });
      }

      programItems = programItems.map(p => {
        p.sorting = parseInt(p.sorting);
        return p;
      });
      programItems.sort((a, b) => {
        return a.sorting - b.sorting;
      });
      let items = programItems;
      items = items.sortBy('sorting:asc');
      programItems.forEach(element => {
        tabs.pushObject((0, _moment.default)(element.date, _moment.default.ISO_8601).format('dd DD.MM.YYYY'));
      });
      tabs = tabs.uniq();
      const model = Ember.RSVP.hash({
        programItems: items,
        agendaItems: agendaItems,
        tabs: tabs,
        client: client
      });
      this.set('model', model);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppProgrammModule;
});