define("ember-x-tabs/templates/components/x-tab/nav-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "raR92msF",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"a\"],[16,6,[31,[\"#\",[34,0]]]],[24,\"role\",\"tab\"],[16,\"aria-controls\",[34,0]],[4,[38,2],[[32,0],[30,[36,2],[[32,0],[35,1],[35,0]],null]],null],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"paneId\",\"selectAction\",\"action\"]}",
    "moduleName": "ember-x-tabs/templates/components/x-tab/nav-item.hbs"
  });

  _exports.default = _default;
});