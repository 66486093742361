define("event-components/components/app-avatar-photo-detail/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-avatar-photo-detail/template", "ember-concurrency-decorators", "graphql-tag", "event-components/gql/fragments"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _emberConcurrencyDecorators, _graphqlTag, _fragments) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  const mutation = (0, _graphqlTag.default)`
mutation updateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
  updateUser(data: $data, where: $where) {
    ${_fragments.USER_FRAGMENT}
  }
}
`;
  let AppAvatarPhotoDetail = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service(), _dec3 = Ember._action, (_class = class AppAvatarPhotoDetail extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    *createAvatarFromBlob(blob) {
      try {
        const filekey = `${this.amplify.currentClient.id}/${this.amplify.currentUser.id}/avatar/${Date.now()}-thumb.jpg`;
        let result = yield this.get("amplify").Storage.put(filekey, blob, {
          contentType: 'image/jpg',
          level: "public"
        });
        let fileUrl = yield this.get("amplify").Storage.get(result.key, {
          expires: 60,
          level: "public"
        });
        let fileModel = {
          key: result.key
        };
        let user = yield this.submitThumbForm.perform({
          file: fileModel
        });
        console.log(user.avatarthumb.id);
        this.set("amplify.currentUser.avatarthumb", user.avatarthumb);
        this.onClose();
        return user;
      } catch (e) {
        this.get("amplify").Logger.error("[File Upload] ", e);
      }
    }

    *submitThumbForm(data) {
      let {
        file
      } = data;
      const variables = {
        data: {
          avatarthumb: {
            create: { ...file,
              client: {
                connect: {
                  id: this.amplify.currentClient.id
                }
              }
            }
          }
        },
        where: {
          id: this.get("amplify.currentUser.id")
        }
      };
      let user = yield this.get("apollo").mutate({
        mutation,
        variables
      }, "updateUser");
      return user;
    }

    *submitForm(data) {
      let {
        file
      } = data;
      const variables = {
        data: {
          avatar: {
            create: { ...file,
              client: {
                connect: {
                  id: this.amplify.currentClient.id
                }
              }
            }
          }
        },
        where: {
          id: this.get("amplify.currentUser.id")
        }
      };
      let user = yield this.get("apollo").mutate({
        mutation,
        variables
      }, "updateUser");
      return user;
    }

    *removeImage() {
      const variables = {
        data: {
          avatar: {
            delete: true
          }
        },
        where: {
          id: this.get("amplify.currentUser.id")
        }
      };
      let user = yield this.get("apollo").mutate({
        mutation,
        variables
      }, "updateUser");
      this.set("amplify.currentUser.avatar", user.avatar);
      return user;
    }

    uploadImage() {
      var _document$getElementB;

      (_document$getElementB = document.getElementById("avataer-upload")) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.click();
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "createAvatarFromBlob", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "createAvatarFromBlob"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "submitThumbForm", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "submitThumbForm"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "submitForm", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "submitForm"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeImage", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeImage"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "uploadImage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "uploadImage"), _class.prototype)), _class));
  _exports.default = AppAvatarPhotoDetail;
});