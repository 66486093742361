define("event-components/components/app-map-beacondetail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6cls+0LM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"style\"],[12],[2,\"\\n    .beacon-border-\"],[1,[35,0,[\"id\"]]],[2,\"::before {\\n        border-top: 10px solid \"],[1,[35,0,[\"color\"]]],[2,\";\\n    }\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"border-top border-color1\"],[12],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"beacon\"]}",
    "moduleName": "event-components/components/app-map-beacondetail/template.hbs"
  });

  _exports.default = _default;
});