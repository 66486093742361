define("event-components/helpers/faved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.faved = faved;
  _exports.default = void 0;

  function faved(params
  /*, hash*/
  ) {
    if (params[0] === null) {
      return false;
    }

    console.log(params[0].findBy('id', params[1].id) !== undefined);
    return params[0].findBy('id', params[1].id) !== undefined;
  }

  var _default = Ember.Helper.helper(faved);

  _exports.default = _default;
});