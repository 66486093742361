define("event-components/components/app-agenda-appointment/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "event-components/components/app-agenda-appointment/template", "event-components/gql/fragments", "graphql-tag"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _template, _fragments, _graphqlTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2;

  const deleteMutation = (0, _graphqlTag.default)`
  mutation deleteAppointment($where: AppointmentWhereUniqueInput!) {
    deleteAppointment(where: $where) {
      id
    }
  }
`;
  const updateMutation = (0, _graphqlTag.default)`
mutation updateAppointment($data: AppointmentUpdateInput!, $where: AppointmentWhereUniqueInput!) {
  updateAppointment(where: $where, data: $data) {
    ${_fragments.APPOINTMENT_FRAGMENT}
  }
}
`;
  let AppAgendaAppointment = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("amplify"), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class AppAgendaAppointment extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "editor", false);
      (0, _defineProperty2.default)(this, "expanded", false);
      (0, _defineProperty2.default)(this, "errorMsg", null);
      (0, _defineProperty2.default)(this, "isLoading", false);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    async deleteAppointment(id) {
      const variables = {
        where: {
          id: id
        }
      };
      let res = await this.apollo.mutate({
        mutation: deleteMutation,
        variables,
        refetchQueries: ["posts"]
      });
      console.log(res);
    }

    async updateAppointment(id) {
      const variables = {
        where: {
          id: id
        },
        data: {
          date: this.model.date,
          startAt: this.model.startAt,
          endAt: this.model.endAt,
          location: this.model.location,
          description: this.model.description
        }
      };

      if (parseInt(this.model.startAt.replace(':', '')) >= parseInt(this.model.endAt.replace(':', ''))) {
        this.set('errorMsg', this.intl.t('appointment.zeit_validierung'));
      } else {
        this.set('isLoading', true);

        try {
          await this.apollo.mutate({
            mutation: updateMutation,
            variables
          }, "appointment");
        } catch (e) {
          console.error(e);
        } finally {
          this.set('errorMsg', null);
          this.set('isLoading', false);
          this.set('editor', false);
        }
      }
    }

    async requestAppointment(appointment) {
      this.set('isLoading', true);
      this.set("user", this.amplify.currentUser);
      this.amplify.setAppointmentNotification(appointment.user, true);
      fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: appointment.user.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: this.get("amplify.currentTheme.data.ETAReqSubject").replace(/\n/g, ""),
          content: "empty",
          emailTemplate: this.get("amplify.currentTheme.data.ETAReq").replace(/\n/g, "<br />"),
          data: {
            owner: appointment.user,
            appointment: { ...appointment,
              requestUser: this.user,
              requestion: this.model.requestion,
              locationSuggestion: this.model.locationSuggestion
            }
          }
        })
      }).then(response => response.json());
      const variables = {
        where: {
          id: appointment.id
        },
        data: {
          locationSuggestion: this.model.locationSuggestion,
          requestion: this.model.requestion,
          requestUser: {
            connect: {
              id: this.user.id
            }
          }
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateMutation,
          variables
        }, "appointment");
      } catch (e) {
        console.error(e);
      } finally {
        this.set('isLoading', false);
        this.set('editor', false);
      }
    }

    async denyAppointment(appointment) {
      this.set('isLoading', true);
      this.set("user", this.amplify.currentUser);
      this.amplify.setAppointmentNotification(appointment.requestUser, true);
      fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: appointment.requestUser.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: this.get("amplify.currentTheme.data.ETADenSubject").replace(/\n/g, ""),
          content: "empty",
          emailTemplate: this.get("amplify.currentTheme.data.ETADen").replace(/\n/g, "<br />"),
          data: {
            owner: appointment.user,
            appointment: { ...appointment,
              approved: false
            }
          }
        })
      }).then(response => response.json());

      if (appointment.type === this.intl.t('appointment.terminanfrage')) {
        return this.deleteAppointment(appointment.id);
      }

      const variables = {
        where: {
          id: appointment.id
        },
        data: {
          locationSuggestion: null,
          requestion: null,
          requestUser: {
            disconnect: true
          }
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateMutation,
          variables
        }, "appointment");
      } catch (e) {
        console.error(e);
      } finally {
        this.set('isLoading', false);
        this.set('editor', false);
      }
    }

    async approveAppointment(appointment) {
      this.set('isLoading', true);
      this.set("user", this.amplify.currentUser);
      this.amplify.setAppointmentNotification(appointment.requestUser, true);
      fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: appointment.requestUser.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: this.get("amplify.currentTheme.data.ETAAproSubject").replace(/\n/g, ""),
          content: "empty",
          emailTemplate: this.get("amplify.currentTheme.data.ETAApro").replace(/\n/g, "<br />"),
          data: {
            owner: appointment.user,
            appointment: { ...appointment,
              approved: true
            }
          }
        })
      }).then(response => response.json());
      const variables = {
        where: {
          id: appointment.id
        },
        data: {
          approved: true
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateMutation,
          variables
        }, "appointment");
      } catch (e) {
        console.error(e);
      } finally {
        this.set('isLoading', false);
        this.set('editor', false);
      }
    }

    async cancelAppointment(appointment) {
      this.set('isLoading', true);
      this.set("user", this.amplify.currentUser);
      this.amplify.setAppointmentNotification(appointment.user, true); // mail to owner

      fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: appointment.user.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: this.get("amplify.currentTheme.data.ETADelSubject").replace(/\n/g, ""),
          content: "empty",
          emailTemplate: this.get("amplify.currentTheme.data.ETADel").replace(/\n/g, "<br />"),
          data: {
            owner: appointment.user,
            appointment: { ...appointment,
              type: this.intl.t('appointment.feier_termin')
            }
          }
        })
      }).then(response => response.json());
      this.amplify.setAppointmentNotification(appointment.requestUser, true); // mail to requestor

      fetch("https://u6laok016g.execute-api.eu-west-1.amazonaws.com/prod/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: appointment.requestUser.email,
          emailSender: this.amplify.currentTheme.data.emailsender,
          subject: this.get("amplify.currentTheme.data.ETADelSubject").replace(/\n/g, ""),
          content: "empty",
          emailTemplate: this.get("amplify.currentTheme.data.ETADel").replace(/\n/g, "<br />"),
          data: {
            owner: appointment.user,
            appointment: { ...appointment,
              type: this.intl.t('appointment.stornierter_termin')
            }
          }
        })
      }).then(response => response.json());
      const variables = {
        where: {
          id: appointment.id
        },
        data: {
          type: this.intl.t('appointment.feier_termin')
        }
      };

      try {
        await this.apollo.mutate({
          mutation: updateMutation,
          variables
        }, "appointment");
      } catch (e) {
        console.error(e);
      } finally {
        this.set('isLoading', false);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deleteAppointment", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAppointment"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "updateAppointment", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateAppointment"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "requestAppointment", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "requestAppointment"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "denyAppointment", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "denyAppointment"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "approveAppointment", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "approveAppointment"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cancelAppointment", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAppointment"), _class.prototype)), _class));
  _exports.default = AppAgendaAppointment;
});