define("event-components/vote/service", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "event-components/gql/fragments", "ember-concurrency"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _fragments, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  const subscription = (0, _graphqlTag.default)`
subscription($where: VoteSubscriptionWhereInput) {
  vote(where: $where) {
    node {
     ${_fragments.VOTE_FRAGMENT}
    }
    previousValues {
      id
    }
    mutation
  }
}
`;
  const query = (0, _graphqlTag.default)`
  query votes($where: VoteWhereInput!) {
    votes(where: $where) {
      ${_fragments.VOTE_FRAGMENT}
    }
  }
`;
  let Chat = (_dec = Ember.inject.service("graphql"), _dec2 = Ember.inject.service("amplify"), (_class = class Chat extends Ember.Service.extend(Ember.Evented) {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor2, this);
      (0, _defineProperty2.default)(this, "suggested", '');
      (0, _defineProperty2.default)(this, "votes", []);
      (0, _defineProperty2.default)(this, "_subscriber", void 0);
      this.set("apollo", this.get("apolloService").createQueryManager());
      this.amplify.on("ws:updateVote", this, "handleVoteChange");
      this.initService();
    }

    async initService() {
      await (0, _emberConcurrency.waitForProperty)(this, "amplify.currentClient.id"); //this.initSubscriber();
      //this.initVotesSubscription();

      this.preloadVotes();
    }

    async initVotesSubscription() {
      console.log(this.get("amplify"));
    }

    async preloadVotes() {
      let votes;
      let variables = {
        where: {
          client: {
            id: this.get("amplify.currentClient.id")
          },
          show: true
        }
      };
      votes = await this.apollo.watchQuery({
        query,
        variables,
        fetchPolicy: "network-only"
      }, "votes");
      this.get("votes").pushObjects(votes);
    }

    willDestroy() {
      this._subscriber.unsubscribe();
    }

    handleVoteChange(event) {
      console.log("new vote", ...arguments);

      if (!event.node.active) {
        let oldVote = this.votes.findBy("id", event.node.id);
        console.log("note is null", oldVote, this.votes.length);
        this.votes.removeObject(oldVote);
        console.log(this.votes.length);
      } else if (this.votes.findBy('id', event.node.id) !== undefined) {
        let oldVote = this.votes.findBy("id", event.node.id);
        let idx = this.votes.indexOf(oldVote);
        console.log("note is updated", idx, this.votes.length);
        this.votes.replace(idx, 1, [{ ...oldVote,
          ...event.node
        }]);
        console.log(this.votes.length);
      } else {
        if (this.amplify.currentClient.id !== event.node.client.id) {
          return;
        }

        this.votes = [...this.votes, event.node];
      }

      this.trigger("vote:changed", event.node);
    }

    async initSubscriber() {}

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class)); // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = Chat;
});