define("ember-gestures/services/-gestures", ["exports", "ember-gestures/utils/string/dasherized-to-camel", "ember-gestures/utils/string/capitalize-word"], function (_exports, _dasherizedToCamel, _capitalizeWord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Promise,
    // jshint ignore:line
    defer // jshint ignore:line

  } = Ember.RSVP;

  var _default = Ember.Service.extend({
    _recognizers: null,
    _fastboot: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),

    retrieve(names) {
      let promises = names.map(name => {
        return this.lookupRecognizer(name);
      });
      return Ember.RSVP.all(promises);
    },

    createRecognizer(name, details) {
      const eventName = (0, _dasherizedToCamel.default)(details.eventName || name).toLowerCase();
      const gesture = (0, _capitalizeWord.default)(details.recognizer);
      const options = details.options || {};
      options.event = eventName;
      options.name = name;
      const Recognizer = new Hammer[gesture](options);
      Recognizer.initialize = defer();
      this.set(`_recognizers.${name}`, Recognizer);
      return Recognizer;
    },

    setupRecognizer(name, details) {
      if (this.get('_fastboot.isFastBoot')) {
        return;
      }

      return Promise.resolve(this.createRecognizer(name, details)) // includes
      .then(Recognizer => {
        if (details.include) {
          const included = details.include.map(name => {
            return this.__speedyLookupRecognizer(name);
          });
          return Ember.RSVP.all(included).then(recognizers => {
            Recognizer.recognizeWith(recognizers);
            return Recognizer;
          });
        }

        return Recognizer;
      }) // excludes
      .then(Recognizer => {
        if (details.exclude) {
          const excluded = details.exclude.map(name => {
            return this.__speedyLookupRecognizer(name);
          });
          return Ember.RSVP.all(excluded).then(recognizers => {
            Recognizer.requireFailure(recognizers);
            Recognizer.exclude = recognizers;
            Recognizer.initialize.resolve(Recognizer);
            return Recognizer;
          });
        } else {
          Recognizer.exclude = [];
          Recognizer.initialize.resolve(Recognizer);
          return Recognizer;
        }
      });
    },

    __speedyLookupRecognizer(name) {
      let recognizer = this.get(`_recognizers.${name}`);

      if (recognizer) {
        return recognizer;
      }

      const path = `ember-gesture:recognizers/${name}`;
      const details = Ember.getOwner(this).factoryFor(path);

      if (details) {
        return this.setupRecognizer(name, details.class);
      }

      return Promise.reject(new Error(`ember-gestures/recognizers/${name} was not found. You can scaffold this recognizer with 'ember g recognizer ${name}'`));
    },

    lookupRecognizer(name) {
      let recognizer = this.get(`_recognizers.${name}`);

      if (recognizer) {
        return recognizer.initialize.promise.then(function (recognizer) {
          return recognizer;
        });
      }

      const path = `ember-gesture:recognizers/${name}`;
      const details = Ember.getOwner(this).factoryFor(path);

      if (details) {
        return this.setupRecognizer(name, details.class);
      }

      return Promise.reject(new Error(`ember-gestures/recognizers/${name} was not found. You can scaffold this recognizer with 'ember g recognizer ${name}'`));
    },

    init() {
      this._super();

      this._recognizers = {};
    }

  });

  _exports.default = _default;
});