define("event-components/components/twitter-module/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3TgJ3XpM",
    "block": "{\"symbols\":[\"post\",\"index\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"filteredPosts\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"twitter-post\",[[24,0,\"\"]],[[\"@post\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[18,3,null]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\"]}",
    "moduleName": "event-components/components/twitter-module/template.hbs"
  });

  _exports.default = _default;
});