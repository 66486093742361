define("event-components/components/app-agenda-item/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember-decorators/component", "graphql-tag", "event-components/components/app-agenda-item/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _graphqlTag, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2, _descriptor;

  const mutation = (0, _graphqlTag.default)`
  mutation deleteAgendaItem($where: AgendaItemWhereUniqueInput!) {
    deleteAgendaItem(where: $where) {
      id
    }
  }
`;
  let AppAgendaItem = (_dec = (0, _component.classNames)("agenda-item"), _dec2 = Ember.inject.service("graphql"), _dec3 = Ember.computed("model.fullpage"), _dec4 = Ember.computed("model.floatright"), _dec5 = Ember.computed("model.date", "model.programmItem.date"), _dec6 = Ember._action, _dec(_class = (_class2 = class AppAgendaItem extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    get fullpage() {
      return this.get("model.fullpage") ? "fullpage" : "no-fullpage";
    }

    get floatright() {
      return this.get("model.floatright") ? "floatright" : "no-floatright";
    }

    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      this.set("apollo", this.get("apolloService").createQueryManager());
    }

    get date() {
      let temp;

      if (Ember.isEmpty(this.get("model.programmItem.date"))) {
        temp = this.get("model.date");
      } else {
        temp = this.get("model.programmItem.date");
      }

      console.log(temp);
      return temp;
    }

    async removeItemToAgenda(item) {
      const variables = {
        where: {
          id: item.id
        }
      };
      let resp = this.apollo.mutate({
        mutation,
        variables,
        refetchQueries: ["agenda"]
      });

      if (await resp) {
        console.log(resp);
        location.reload();
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "apolloService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "fullpage", [_component.className, _dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "fullpage"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "floatright", [_component.className, _dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "floatright"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "date", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "date"), _class2.prototype), (0, _applyDecoratedDescriptor2.default)(_class2.prototype, "removeItemToAgenda", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "removeItemToAgenda"), _class2.prototype)), _class2)) || _class);
  _exports.default = AppAgendaItem;
});