define("event-components/components/app-umfrage-vote/component", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "graphql-tag", "ember-concurrency-decorators", "event-components/gql/fragments", "event-components/components/app-umfrage-vote/template"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _graphqlTag, _emberConcurrencyDecorators, _fragments, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  const updateMutation = (0, _graphqlTag.default)`
mutation updateVote($data: VoteUpdateInput!, $where: VoteWhereUniqueInput!) {
  updateVote(data: $data, where: $where) {
    ${_fragments.VOTE_FRAGMENT}
  }
}
`;
  let AppUmfrage = (_dec = Ember.inject.service('graphql'), _dec2 = Ember.inject.service('cookies'), _dec3 = Ember.inject.service('amplify'), _dec4 = Ember.inject.service("vote"), _dec5 = Ember.inject.service(), _dec6 = Ember._action, _dec7 = Ember.computed('vote.moderated'), _dec8 = Ember.computed('vote.votes.[]'), _dec9 = Ember.computed('vote.votes.[]'), _dec10 = Ember.computed('vote.votes.[]'), (_class = class AppUmfrage extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "layout", _template.default);
      (0, _initializerDefineProperty2.default)(this, "apolloService", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "cookies", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "amplify", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "voteService", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "intl", _descriptor5, this);
      (0, _defineProperty2.default)(this, "voteid", null);
      (0, _defineProperty2.default)(this, "showChart", false);
      (0, _defineProperty2.default)(this, "newAnswer", '');
      (0, _defineProperty2.default)(this, "newBackendText", "Hier können Sie abstimmen und somit die Reihenfolge der Fragen beeinflussen.");
      (0, _defineProperty2.default)(this, "isSaved", true);
      this.set('apollo', this.get('apolloService').createQueryManager());
    }

    didReceiveAttrs() {
      this.set('voteid', this.get('cookies').read(`vote-${this.votescope}`));
    }

    showChart() {
      this.set('amplify.currentModuleItem3rd', true);
    }

    get votevalues() {
      if (this.vote.moderated) {
        return this.vote.moderatedvalues;
      } else {
        return this.vote.values;
      }
    }

    get currentAnswers() {
      if (this.vote.votes == null) {
        return false;
      }

      return this.vote.votes.filterBy('user', this.amplify.currentUser.id);
    }

    get sortedValues() {
      return this.vote.values.sort().reverse().sort((a, b) => {
        let ergeb = this.vote.votes.filterBy('vote', a).length - this.vote.votes.filterBy('vote', b).length;
        ;
        console.log(ergeb);
        return ergeb;
      }).reverse();
    }

    get userHasReachedVotingLimit() {
      if (this.vote.votes == null) {
        return false;
      }

      let votes = this.vote.votes.filterBy('user', this.amplify.currentUser.id);

      if (votes.length >= this.vote.limit) {
        return true;
      }

      return false;
    }

    *saveVote(saveVote) {
      try {
        let resp = yield fetch('https://vq1r6gh2b7.execute-api.eu-central-1.amazonaws.com/prod/vote', {
          method: 'POST',
          body: JSON.stringify({
            voteid: saveVote.id,
            votes: this.vote.votes.filterBy('user', this.amplify.currentUser.id)
          })
        });
        let updatedVote = yield resp.json();
        this.set('vote', updatedVote);
        this.set('isSaved', true);
      } catch (e) {
        console.error(e);
      }
    }

    willDestroy() {
      this.set('');
    }

    *silentSaveVote(saveVote) {
      try {
        let resp = yield fetch('https://vq1r6gh2b7.execute-api.eu-central-1.amazonaws.com/prod/vote', {
          method: 'POST',
          body: JSON.stringify({
            voteid: saveVote.id,
            votes: this.vote.votes.filterBy('user', this.amplify.currentUser.id)
          })
        });
        let updatedVote = yield resp.json();
        this.set('vote', updatedVote);
        this.set('isSaved', true);
      } catch (e) {
        console.error(e);
      }
    }

    didInsertElement() {
      console.log("didInsertElement");
    }

    *goBackToVote() {
      this.set('voteService.suggested', '');
    }

    *changeVote(index, save = false) {
      let antwort = this.vote.values.objectAt(index);

      if (antwort !== null) {
        this.set('isSaved', false);

        if (!this.vote.votes) {
          Ember.set(this.vote, 'votes', []);
        }

        let newVote = {
          user: this.amplify.currentUser.id,
          vote: antwort
        };
        let oldVote2 = this.vote.votes.find(v => {
          return v.user === this.amplify.currentUser.id && v.vote === antwort;
        });

        if (this.vote.votes.indexOf(oldVote2) !== -1) {
          this.get('vote.votes').removeObject(oldVote2); //return this.silentSaveVote.perform(this.vote);
        } else if (this.get('userHasReachedVotingLimit')) {
          event.target.checked = false; //this.silentSaveVote.perform(this.vote);

          return;
        } else {
          this.vote.votes.pushObject(newVote);
        }

        let oldVote = this.vote.votes.findBy('user', this.amplify.currentUser.id);

        if (save) {
          this.silentSaveVote.perform(this.vote);
        }
      }
    }

    *saveNewAnswer(saveVote) {
      try {
        let id = this.get('voteid');

        if (Ember.isEmpty(this.newAnswer)) {
          return;
        }

        if (Ember.isEmpty(id)) {
          const uniqueId = this.vote.id;
          this.get('cookies').write(`vote-${this.votescope}`, uniqueId);
          id = uniqueId;
        }

        let variables = {
          data: {
            changetrack: Date().toString(),
            values: {
              set: [...this.vote.values, this.newAnswer]
            }
          },
          where: {
            id: this.vote.id
          }
        };
        delete variables.data.client;
        delete variables.data.image;
        delete variables.data.__typename;
        delete variables.data.id;
        this.set('newAnswer', '');
        this.set('voteService.suggested', this.intl.t('vote.neuer_vorschlag'));
        let vote = yield this.apollo.mutate({
          mutation: updateMutation,
          variables
        }, 'updateVote');
        this.set('vote', vote);
      } catch (e) {
        console.error(e);
      }
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "apolloService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "cookies", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "amplify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "voteService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showChart", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showChart"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "votevalues", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "votevalues"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "currentAnswers", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "currentAnswers"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "sortedValues", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "sortedValues"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "userHasReachedVotingLimit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "userHasReachedVotingLimit"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveVote", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveVote"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "silentSaveVote", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "silentSaveVote"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "goBackToVote", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "goBackToVote"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "changeVote", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "changeVote"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "saveNewAnswer", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveNewAnswer"), _class.prototype)), _class));
  _exports.default = AppUmfrage;
});