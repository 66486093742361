define("ember-apollo-client/-private/mixins/route-query-manager", ["exports", "ember-apollo-client/-private/mixins/base-query-manager"], function (_exports, _baseQueryManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_baseQueryManager.default, {
    beforeModel() {
      this.apollo.markSubscriptionsStale();
      return this._super(...arguments);
    },

    resetController(_controller, isExiting) {
      this._super(...arguments);

      this.apollo.unsubscribeAll(!isExiting);
    },

    willDestroy() {
      let apollo = this.apollo;

      if (apollo.unsubscribeAll) {
        this.apollo.unsubscribeAll(false);
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});