define("event-components/eventbus/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Eventbus extends Ember.Service.extend(Ember.Evented) {// normal class body definition here
  } // DO NOT DELETE: this is how TypeScript knows how to look up your services.


  _exports.default = Eventbus;
});