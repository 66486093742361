define("event-components/components/twitter-filter/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@ember-decorators/component", "event-components/components/twitter-filter/template"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _class2;

  let TwitterFilter = (_dec = (0, _component.tagName)("div"), _dec2 = (0, _component.classNames)("twitter-filter"), _dec3 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class TwitterFilter extends Ember.Component.extend({// anything which *must* be merged to prototype here
  }) {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "layout", _template.default);
    }

    onFilter(filter) {
      this.onToggleFilter(filter);
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class2.prototype, "onFilter", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "onFilter"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = TwitterFilter;
});